import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as MediaLibrary from "expo-media-library";
import * as Permissions from "expo-permissions";
import React, { Component, PureComponent } from "react";
import {
  Animated,
  Dimensions,
  FlatList,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import Fade from "../../Components/Fade";
import { Types as CourseTypes } from "../../Models/Reducers/CourseReducer";
import { Types as ReportTypes } from "../../Models/Reducers/ReportReducer";
import { Types as TimeTypes } from "../../Models/Reducers/TimeframeReducer";
import { Types as WeekdayTypes } from "../../Models/Reducers/WeekdayReducer";
import { colors, fontSize, Layout, spaceSize } from "../../Themes";
import { ss } from "./ScheduleStyle";

class Item extends PureComponent {
  render() {
    const { item, index, selected } = this.props;
    return (
      <View key={index}>
        <View
          style={[
            ss.lineContainer,
            selected && {
              backgroundColor: colors.bgNavItem
            }
          ]}
        >
          <TouchableOpacity
            style={{ flex: 1 }}
            onPress={() => this.props.onSelect(item)}
          >
            <Text
              style={[
                ss.textInput,
                selected && {
                  color: colors.textNavSelected
                }
              ]}
            >{`${index + 1}/ ${item.name}`}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default class ScheduleScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      keyCourse: "",
      keyTeacher: "",
      currentTeacher: null,
      scrollX: new Animated.Value(0),
      //
      teachers: [...this.props.teacherState.teachers].map(teacher => {
        let busyTimes = [];
        this.props.courseState.courses
          .filter(
            f =>
              f.teacher != null &&
              f.teacher === teacher.uuid &&
              f.teacher !== teacher.course
          )
          .forEach(course => {
            if (course.schedules)
              course.schedules.forEach(time => {
                busyTimes.push({
                  weekday: time.weekday,
                  timeframe: time.timeframe,
                  subjectName: course.name
                });
              });
          });
        return {
          ...teacher,
          busyTimes: busyTimes,
          value: teacher.name
        };
      }),
      grid: this.props.weekdayState.weekdays
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(weekday => {
          return {
            title: weekday.name,
            titleCollapse: weekday.code,
            cells: this.props.timeframeState.timeframes
              .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
              .map(timeframe => {
                return {
                  weekday: weekday.uuid,
                  timeframe: timeframe.uuid,
                  red: false,
                  yellow: false,
                  gray: false
                };
              })
          };
        })
    };
  }
  async componentDidMount() {
    this.props.getTeachers();
    this.props.getWeekdays();
    this.props.getTimeframes();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.courseState.type !== "") {
      switch (nextProps.courseState.type) {
        case CourseTypes.UPDATE_COURSE_FAIL:
          alert("Có lỗi xảy ra. Hãy thử lại sau!");

          // alert(nextProps.courseState.memessage);
          break;
        case CourseTypes.UPDATE_COURSE_SUCCESS:
          // this.onReset();
          alert("Xếp lịch thành công!");
          this.props.getCourses();
          this.props.getTeachers();
          this.props.getWeekdays();
          this.props.getTimeframes();

          break;
      }
      this.props.resetTypeCourse();
    }

    if (nextProps.reportState.type !== "") {
      switch (nextProps.reportState.type) {
        case ReportTypes.DOWNLOAD_TEACHER_SCHEDULE_SUCCESS:
          // const uri = "https://conn-backend.herokuapp.com/report/teacher_schedule.xlsx"
          // let fileUri = FileSystem.documentDirectory + 'schedule.xlsx';
          // FileSystem.downloadAsync(uri, fileUri)
          //   .then(({ uri }) => {
          //     this.saveFile(uri)
          //   }).then(() => {
          //     this.refs.toast.show('Đã lưu!');
          //     this.setState({
          //       disableButton: !this.state.disableButton
          //     })
          //   })
          //   .catch(error => {
          //     console.error(error);
          //   })
          break;
        case ReportTypes.DOWNLOAD_TEACHER_SCHEDULE_FAIL:
          this.refs.toast.show(nextProps.reportState.message);
          this.setState({
            disableButton: !this.state.disableButton
          });
          break;
      }
    }

    if (this.state.teachers.length === 0) {
      this.setState({
        teachers: [...nextProps.teacherState.teachers].map(teacher => {
          let busyTimes = [];
          nextProps.courseState.courses
            .filter(
              f =>
                f.teacher != null &&
                f.teacher === teacher.uuid &&
                f.teacher !== teacher.course
            )
            .forEach(course => {
              if (course.schedules)
                course.schedules.forEach(time => {
                  busyTimes.push({
                    weekday: time.weekday,
                    timeframe: time.timeframe,
                    subjectName: course.name
                  });
                });
            });
          return {
            ...teacher,
            busyTimes: busyTimes,
            value: teacher.name
          };
        })
      });
    }
    if (
      nextProps.weekdayState.type === WeekdayTypes.GET_WEEKDAYS_SUCCESS ||
      nextProps.timeframeState.type === TimeTypes.GET_TIMEFRAMES_SUCCESS ||
      nextProps.courseState.type === CourseTypes.GET_COURSES_SUCCESS
    ) {
      this.setState({
        teachers: [...nextProps.teacherState.teachers].map(teacher => {
          let busyTimes = [];
          nextProps.courseState.courses
            .filter(
              f =>
                f.teacher != null &&
                f.teacher === teacher.uuid &&
                f.teacher !== teacher.course
            )
            .forEach(course => {
              if (course.schedules)
                course.schedules.forEach(time => {
                  busyTimes.push({
                    weekday: time.weekday,
                    timeframe: time.timeframe,
                    subjectName: course.name
                  });
                });
            });
          return {
            ...teacher,
            busyTimes: busyTimes,
            value: teacher.name
          };
        })
        // grid: nextProps.weekdayState.weekdays
        //   .sort((a, b) => a.sortIndex - b.sortIndex)
        //   .map(weekday => {
        //     return {
        //       title: weekday.name,
        //       titleCollapse: weekday.code,
        //       cells: nextProps.timeframeState.timeframes
        //         .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
        //         .map(timeframe => {
        //           return {
        //             weekday: weekday.uuid,
        //             timeframe: timeframe.uuid,
        //             red: false,
        //             yellow: false,
        //             gray: false
        //           };
        //         })
        //     };
        //   })
      });
    }
  }
  routeTo = (screen, params) => {
    this.props.navigation.navigate({
      routeName: screen,
      params
    });
  };

  saveFile = async uri => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status === "granted") {
      const asset = await MediaLibrary.createAssetAsync(uri);
      await MediaLibrary.createAlbumAsync("Download", asset, false);
    }
  };

  dowload = () => {
    this.setState({
      disableButton: !this.state.disableButton
    });
    this.props.makeXlxs();
  };

  onSelectTeacher = teacher => {
    // console.log(teacher)
    this.setState({
      selectedTeacher: teacher,
      keyTeacher: (!!teacher && teacher.name) || "",
      grid: this.state.grid.map(column => {
        return {
          ...column,
          cells: column.cells.map(cell => {
            // let freeTime = teacher && teacher.teachingTimes
            //   ? teacher.teachingTimes.find(time => time.weekday === cell.weekday && time.timeframe === cell.timeframe)
            //   : null;
            let busyTime =
              teacher && teacher.busyTimes
                ? teacher.busyTimes.find(
                    time =>
                      time.weekday === cell.weekday &&
                      time.timeframe === cell.timeframe
                  )
                : null;
            return {
              ...cell,
              // yellow: freeTime ? true : false,
              yellow: teacher ? true : false,
              gray: busyTime ? true : false,
              subjectName: busyTime ? busyTime.subjectName : ""
            };
          })
        };
      })
    });
  };
  onSelectCourse = course => {
    this.setState({
      selectedCourse: course,
      keyCourse: (!!course && course.name) || "",
      currentTeacher: (!!course && course.teacher) || "",
      grid: this.state.grid.map(column => {
        return {
          ...column,
          cells: column.cells.map(cell => {
            return {
              ...cell,
              red: course
                ? course.schedules.find(
                    f =>
                      f.weekday === cell.weekday &&
                      f.timeframe === cell.timeframe
                  )
                  ? true
                  : false
                : false
            };
          })
        };
      })
    });
    if (!!course) {
      let currentTeacher = this.state.teachers.find(
        teacher => teacher.uuid === course.teacher
      );
      if (!!currentTeacher)
        setTimeout(() => {
          this.onSelectTeacher(currentTeacher);
        }, 10);
      else
        setTimeout(() => {
          this.onSelectTeacher();
        }, 10);
    }
  };
  onReset = () => {
    this.setState({
      selectedCourse: undefined,
      selectedTeacher: undefined,
      keyTeacher: "",
      keyCourse: "",
      grid: this.state.grid.map(column => {
        return {
          ...column,
          cells: column.cells.map(cell => {
            return {
              ...cell,
              yellow: false,
              red: false,
              gray: false
            };
          })
        };
      })
    });
  };
  onNew = () => {
    this.onReset();
  };
  onSave = () => {
    let isValid = true;
    this.state.grid.forEach(column => {
      column.cells.forEach(cell => {
        if (cell.gray === true && cell.red === true) isValid = false;
      });
    });
    // if (this.state.selectedTeacher === undefined) {
    //   alert("Vui lòng chọn giảng viên");
    //   return;
    // }
    if (this.state.selectedCourse === undefined) {
      alert("Vui lòng chọn lớp");
      return;
    }
    if (isValid)
      this.props.setScheduleCourse({
        ...this.state.selectedCourse,
        teacher: !!this.state.selectedTeacher
          ? this.state.selectedTeacher.uuid
          : null
      });
    else alert("Lịch học chưa trùng khớp");
  };

  getColumnTimeWidth = () => {
    const { width, height } = Dimensions.get("window");
    let ratio = width / height;
    return ratio < 1
      ? Layout.minerScale(90)
      : ratio < 2
      ? Layout.minerScale(100)
      : Layout.minerScale(120);
  };
  render() {
    const {
      selectedTeacher,
      keyTeacher,
      selectedCourse,
      keyCourse,
      scrollX
    } = this.state;

    const width = this.getColumnTimeWidth();
    const ratio =
      Dimensions.get("window").width / Dimensions.get("window").height;

    return (
      <Fade>
        <View style={[ss.container]}>
          <View style={ss.dataSelect}>
            <View style={ss.course}>
              <View style={ss.title}>
                <FontAwesome
                  name={"graduation-cap"}
                  color={colors.textBody}
                  size={fontSize.largest}
                />
                <Text style={ss.titleText}>
                  {ratio < 1.2 ? "" : "Chọn Lớp:"}
                </Text>
                <TextInput
                  style={[
                    ss.selectedText,
                    {
                      ...Platform.select({
                        web: { outlineColor: "transparent" }
                      })
                    }
                  ]}
                  value={keyCourse}
                  onChangeText={text => this.setState({ keyCourse: text })}
                />
                <TouchableOpacity
                  style={{ marginHorizontal: spaceSize.margin.small }}
                  onPress={() => this.onSelectCourse()}
                  disabled={!(!!selectedCourse || !!keyCourse)}
                >
                  <FontAwesome
                    name={!!selectedCourse || !!keyCourse ? "close" : "search"}
                    color={
                      !!selectedCourse || !!keyCourse
                        ? colors.iconSpecial
                        : colors.textNav
                    }
                    size={fontSize.largest}
                  />
                </TouchableOpacity>
              </View>
              <View style={ss.listData}>
                <FlatList
                  style={{ flex: 1 }}
                  data={this.props.listCoure.filter(course => {
                    if (selectedCourse) return true;
                    else
                      return course.name
                        .toLowerCase()
                        .includes(keyCourse.toLowerCase());
                  })}
                  keyExtractor={this._keyExtractor}
                  extraData={this.state || this.props}
                  scrollEventThrottle={16}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <Item
                      item={item}
                      index={index}
                      onSelect={course => this.onSelectCourse(course)}
                      selected={
                        (!!selectedCourse &&
                          item.id.uuid === selectedCourse.id.uuid) ||
                        false
                      }
                    />
                  )}
                />
              </View>
            </View>

            <View style={ss.teacher}>
              <View style={ss.title}>
                <MaterialIcons
                  name={"people"}
                  color={colors.textBody}
                  size={fontSize.largest}
                ></MaterialIcons>
                <Text style={ss.titleText}>
                  {ratio < 1.2 ? "" : "Chọn Giảng Viên:"}
                </Text>
                <TextInput
                  style={[
                    ss.selectedText,
                    {
                      ...Platform.select({
                        web: { outlineColor: "transparent" }
                      })
                    }
                  ]}
                  value={keyTeacher}
                  onChangeText={text => this.setState({ keyTeacher: text })}
                />
                <TouchableOpacity
                  style={{ marginHorizontal: spaceSize.margin.small }}
                  onPress={() => this.onSelectTeacher()}
                  disabled={!(!!selectedTeacher || !!keyTeacher)}
                >
                  <FontAwesome
                    name={
                      !!selectedTeacher || !!keyTeacher ? "close" : "search"
                    }
                    color={
                      !!selectedTeacher || !!keyTeacher
                        ? colors.iconSpecial
                        : colors.textNav
                    }
                    size={fontSize.largest}
                  />
                </TouchableOpacity>
              </View>
              <View style={ss.listData}>
                <FlatList
                  style={{ flex: 1 }}
                  data={this.state.teachers.filter(teacher => {
                    if (selectedTeacher) return true;
                    else
                      return teacher.name
                        .toLowerCase()
                        .includes(keyTeacher.toLowerCase());
                  })}
                  keyExtractor={this._keyExtractor}
                  extraData={this.state || this.props}
                  scrollEventThrottle={16}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <Item
                      item={item}
                      index={index}
                      onSelect={teacher => this.onSelectTeacher(teacher)}
                      selected={
                        (!!selectedTeacher &&
                          item.uuid === selectedTeacher.uuid) ||
                        false
                      }
                    />
                  )}
                />
              </View>
            </View>
          </View>
          <View style={[ss.timeTable]}>
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "flex-start"
                },
                ratio < 1.2 && { width: "100%" }
              ]}
            >
              <View style={ss.timeframeTitle}>
                <Text style={[ss.timeframeTitleText, { opacity: 0 }]}>
                  {this.props.timeframeState.timeframes[0].beginTime +
                    " - " +
                    this.props.timeframeState.timeframes[0].endTime}
                </Text>
              </View>

              <View style={{ overflow: "hidden" }}>
                <Animated.View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    right: scrollX
                  }}
                >
                  {this.state.grid.map((column, idx1) => (
                    <View key={idx1} style={{ width: width }}>
                      <View
                        style={{
                          borderWidth: Layout.minerScale(1),
                          borderColor: colors.line,
                          height: Layout.minerScale(32),
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Text
                          style={{
                            fontSize: fontSize.large,
                            color: colors.textNav
                          }}
                        >
                          {column.title}
                        </Text>
                      </View>
                    </View>
                  ))}
                </Animated.View>
              </View>
            </View>

            <ScrollView
              style={{ height: "100%", width: "100%" }}
              showsVerticalScrollIndicator={false}
              scrollEventThrottle={16}
              contentContainerStyle={{
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start"
              }}
            >
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "flex-start"
                  },
                  ratio < 1.2 && { width: "100%" }
                ]}
              >
                <View>
                  {/* <Text
                    style={{
                      borderWidth: Layout.minerScale(1),
                      borderColor: colors.line,
                      height: Layout.minerScale(32)
                    }}
                  ></Text> */}
                  {this.props.timeframeState.timeframes
                    .sort((a, b) => a.beginTime - b.beginTime)
                    .map((timeframe, index) => (
                      <View key={index} style={ss.timeframeTitle}>
                        <Text style={ss.timeframeTitleText}>
                          {timeframe.beginTime + " - " + timeframe.endTime}
                        </Text>
                      </View>
                    ))}
                </View>
                <ScrollView
                  style={{
                    flexDirection: "row",
                    flex: 1
                  }}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  horizontal={true}
                  scrollEventThrottle={16}
                  onScroll={Animated.event([
                    {
                      nativeEvent: {
                        contentOffset: { x: this.state.scrollX }
                      }
                    }
                  ])}
                >
                  {this.state.grid.map((column, idx1) => (
                    <View key={idx1} style={{ width: width }}>
                      {/* <View
                        style={{
                          borderWidth: Layout.minerScale(1),
                          borderColor: colors.line,
                          height: Layout.minerScale(32),
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Text
                          style={{
                            fontSize: fontSize.large,
                            color: colors.textNav
                          }}
                        >
                          {column.title}
                        </Text>
                      </View> */}
                      {column.cells.map((cell, idx2) => {
                        let color = cell.red // ô nầy là ô lịch của lớp?
                          ? cell.gray // ô nầy là ô lịch của lớp. Ô nầy là ô bận của giáo viên?
                            ? !!this.state.selectedTeacher &&
                              this.state.selectedTeacher.uuid ===
                                this.state.currentTeacher
                              ? "green"
                              : "red" // ô nầy giáo viên bận
                            : cell.yellow
                            ? "green" // có giáo viên
                            : "red" // không có giáo viên
                          : cell.gray // ô nầy không phải ô lịch của lớp. Ô nầy là ô bận của giáo viên?
                          ? "gray" // ô nầy giáo viên bận
                          : "transparent"; // ô nầy giáo viên rảnh
                        return (
                          <View
                            key={idx2}
                            style={{
                              borderWidth: Layout.minerScale(1),
                              borderColor: colors.line,
                              height: Layout.minerScale(32),
                              backgroundColor: color,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            // onPress={() => {
                            //   this.onTouchCell(cell.weekday, cell.timeframe);
                            // }}
                          >
                            <Text
                              style={{
                                color: "white",
                                fontSize: fontSize.medium
                              }}
                            >
                              {cell.gray
                                ? !!this.state.selectedCourse &&
                                  this.state.selectedCourse.name ===
                                    cell.subjectName
                                  ? ""
                                  : cell.subjectName // ô nầy giáo viên bận
                                : ""}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  ))}
                </ScrollView>
              </View>
            </ScrollView>
            <View style={ss.buttonContainer}>
              <TouchableOpacity style={ss.button} onPress={this.onNew}>
                <Text style={ss.buttonText}>Lịch mới</Text>
              </TouchableOpacity>
              <TouchableOpacity style={ss.button} onPress={this.onSave}>
                <Text style={ss.buttonText}>Lưu</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Fade>
    );
  }
}

ScheduleScreen.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed"
};
