import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/TimeframeReducer";

function* getTimeframes(action) {
  try {
    let result = yield call(() =>
      API.get("/app/timeframe/views").then(response => response.data)
    );
    if (result.status === 1) {
      let timeframes = result.response.map((timeframe, idx) => {
        return {
          uuid: timeframe.uuid,
          name: timeframe.name,
          beginTime: timeframe.beginTime,
          endTime: timeframe.endTime
        };
      });
      yield put({
        type: Types.GET_TIMEFRAMES_SUCCESS,
        timeframes: timeframes.sort((a, b) => {
          
          return !a.name.localeCompare(b.name);
        }),
        message: "Success"
      });
    } else {
      yield put({
        type: Types.GET_TIMEFRAMES_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_TIMEFRAMES_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* insertTimeframe(action) {
  try {
    const result = yield call(() =>
      API.put("/app/timeframe", action.timeframe).then(
        response => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.INSERT_TIMEFRAME_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.INSERT_TIMEFRAME_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.INSERT_TIMEFRAME_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* deleteTimeframe(action) {
  try {
    const result = yield call(() =>
      API.delete("/app/timeframe", { uuid: action.uuid }).then(
        response => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.DELETE_TIMEFRAME_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.DELETE_TIMEFRAME_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.DELETE_TIMEFRAME_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* updateTimeframe(action) {
  try {
    const result = yield call(() =>
      API.put("/app/timeframe", action.timeframe).then(
        response => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_TIMEFRAME_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_TIMEFRAME_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_TIMEFRAME_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* TimeframeSaga() {
  yield takeLatest(Types.GET_TIMEFRAMES, getTimeframes);
  yield takeLatest(Types.INSERT_TIMEFRAME, insertTimeframe);
  yield takeLatest(Types.UPDATE_TIMEFRAME, updateTimeframe);
  yield takeLatest(Types.DELETE_TIMEFRAME, deleteTimeframe);
}

export default TimeframeSaga;
