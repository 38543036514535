export const Messages = {
  clientError: `Client Error.`,
  serverError: `Server Error.`,
  networkError: `No internet connection.`,
  timeoutError: `Server didn't respond in time.`,
  connectionError: `Server not available, bad dns.`,
  cancelError: `Request has been cancelled.`,
  mapDataError: `Error when map data with server!`
};

export const AlertMessages = {
  inputLogin: "Please input email/phone and password",
  nameEmpty: "Your name is empty",
  //
  phoneInvalid: `Phone number is invalid`,
  phoneEmpty: `Your phone is empty`,
  //
  emailInvalid: `Email is invalid`,
  //
  chooseItem: `Please choose a service`,
  chooseTime: `You must choose time`,
  chooseCustomer: `Please choose a customer`,
  chooseEmployee: `Please choose employee`
};

export const messageFromResponse = response => {
  const problem = response.problem;
  let errMessage = "";
  switch (problem) {
    case "NETWORK_ERROR":
      errMessage = Messages.networkError;
      break;
    case "CLIENT_ERROR":
      errMessage = Messages.clientError;
      break;
    case "SERVER_ERROR":
      errMessage = Messages.serverError;
      break;
    case "TIMEOUT_ERROR":
      errMessage = Messages.timeoutError;
      break;
    case "CONNECTION_ERROR":
      errMessage = Messages.connectionError;
    case "CANCEL_ERROR":
      errMessage = Messages.cancelError;
      break;
  }
  return errMessage;
};
