import React, { Component } from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Linking } from "react-native-web";
import { IconTextMate, IconTextMateCom } from "../../Components/CustomButton";
import Fade from "../../Components/Fade";
import SearchInput from "../../MicroComponents/Input/SearchInput/SearchInput";
import { WebPicker } from "../../MicroComponents/Picker";
import { Types as ReportTypes } from "../../Models/Reducers/ReportReducer";
import { BASE_URL } from "../../Models/URL";
import { colors, fontSize, Layout, spaceSize } from "../../Themes";
import { enumAccount } from "../../Utilities/Enum";
import { removeVietNamMark } from "../../Utilities/Format";
import ReviewGradeSchedule from "./ReviewGradeSchedule";

export default class GradeSchedule extends Component {
  state = {
    url: `${BASE_URL}/report/grade_schedules.xlsx`,
    searchVaue: "",
    listGradesSelected: [],
    branchSelected:
      this.props.branchInfo &&
      this.props.branchInfo.branchs.length > 0 &&
      this.props.branchInfo.branchs[0].uuid,
    reviewGradeSchedule: false
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.reportState.type !== "") {
      switch (nextProps.reportState.type) {
        case ReportTypes.REVIEW_GRADE_SCHEDULE_SUCCESS:
          this.setState({
            reviewGradeSchedule: true
          });
          this.props.resetType();
          break;
        case ReportTypes.REVIEW_GRADE_SCHEDULE_FAIL:
          alert(nextProps.reportState.message);
          this.setState({
            disableButton: !this.state.disableButton
          });
          this.props.resetType();
          break;
        case ReportTypes.DOWNLOAD_GRADE_SCHEDULE_SUCCESS:
          if (this.state.disableButton) {
            this.setState({
              disableButton: !this.state.disableButton,
              allowDownload: true
            });
            //** gán lại url dowload nếu có */
            if (nextProps.reportState.urlDownload)
              this.setState({
                url: `${BASE_URL}${nextProps.reportState.urlDownload}`
              });
            setTimeout(() => {
              this.refs.downloadSchedule.props.onPress();
            }, 500);
          }
          this.props.resetType();
          break;
        case ReportTypes.DOWNLOAD_GRADE_SCHEDULE_FAIL:
          alert(nextProps.reportState.message);
          this.setState({
            disableButton: !this.state.disableButton
          });
          this.props.resetType();
          break;
      }
    }
  }

  dowload = type => {
    this.setState({
      disableButton: !this.state.disableButton
    });
    const listGradeDownload = this.state.listGradesSelected.map(
      item => item.uuid
    );
    //
    let branchSelected = this.state.branchSelected;
    if (
      this.props.loginState.userInfo.userTypes.includes(
        enumAccount.ruleOption.employee
      )
    ) {
      branchSelected = this.props.loginState.userInfo.subsidiaryUuid;
    }
    //
    if (type === "DOWNLOAD")
      this.props.downloadGradeSchedule(branchSelected, listGradeDownload);
    else this.props.reviewGradeSchedule(branchSelected, listGradeDownload);
  };

  onSelectGrade = grade => {
    let listGradesSelected = Object.assign([], this.state.listGradesSelected);
    //
    const gradeIndex = listGradesSelected.findIndex(
      item => item.uuid === grade.uuid
    );
    if (gradeIndex >= 0) listGradesSelected.splice(gradeIndex, 1);
    else listGradesSelected.push(grade);
    //
    this.setState({ listGradesSelected });
  };

  render() {
    let gradeSchedule = Object.assign([], this.props.gradeState.grades);
    if (!!this.state.searchVaue)
      gradeSchedule = gradeSchedule.filter(item =>
        removeVietNamMark(item.name.toLowerCase()).includes(
          removeVietNamMark(this.state.searchVaue.toLowerCase())
        )
      );
    const optionBranch = this.props.branchInfo.branchs.map(item => {
      return {
        value: item.uuid,
        label: item.name
      };
    });
    return (
      <Fade>
        <View style={ss.container}>
          {!this.props.loginState.userInfo.userTypes.includes(
            enumAccount.ruleOption.employee
          ) && (
            <View
              style={[
                ss.header,
                {
                  flexDirection: "row",
                  backgroundColor: colors.bgBody,
                  height: Layout.minerScale(50),
                  borderRadius: spaceSize.borderRadius.small,
                  marginBottom: spaceSize.margin.largest
                }
              ]}
            >
              <Text
                style={[ss.textStyle, { textAlign: "left" }]}
              >{`Vui lòng chọn chi nhánh:`}</Text>
              <WebPicker
                currentValue={this.state.branchSelected}
                options={optionBranch}
                style={{ width: Layout.minerScale(200) }}
                onChange={value => {
                  this.setState({ branchSelected: value });
                }}
              />
            </View>
          )}
          <View style={ss.header}>
            <SearchInput
              value={this.state.searchVaue}
              //
              onChangeText={searchVaue => this.setState({ searchVaue })}
              clearSearch={() => this.setState({ searchVaue: "" })}
              //
              maxLength={20}
              //
              styleWrapper={{
                height: "100%",
                // width: "100%",
                width: Layout.minerScale(350),
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",

                backgroundColor: colors.bgBody,
                borderRadius: spaceSize.borderRadius.large * 2
              }}
              styleText={{
                width: "80%",
                fontSize: fontSize.medium,
                color: colors.textBody
              }}
              styleIcon={{ fontSize: fontSize.largest }}
            />
            {/* REVIEW */}
            <IconTextMateCom
              nameIcon={
                !!this.state.reviewGradeSchedule ? "eye-off" : "eye-check"
              }
              onPress={() => {
                this.setState(pre => ({
                  reviewGradeSchedule: !pre.reviewGradeSchedule
                }));
                if (!this.state.reviewGradeSchedule) this.dowload("REVIEW");
              }}
              disabled={this.state.listGradesSelected.length === 0}
              //
              styleButton={{
                width: Layout.minerScale(30),
                marginLeft: spaceSize.margin.small
              }}
              styleIcon={[
                {
                  fontSize: Layout.minerScale(30),
                  color: colors.iconDownloadAll
                },
                this.state.listGradesSelected.length === 0 && {
                  color: colors.iconDisable
                }
              ]}
            />
            {/* DOWNLOAD */}
            <IconTextMate
              nameIcon={"file-download"}
              onPress={() => this.dowload("DOWNLOAD")}
              disabled={this.state.listGradesSelected.length === 0}
              //
              styleButton={{
                width: Layout.minerScale(30),
                marginLeft: spaceSize.margin.small
              }}
              styleIcon={[
                {
                  fontSize: Layout.minerScale(30),
                  color: colors.iconDownloadAll
                },
                this.state.listGradesSelected.length === 0 && {
                  color: colors.iconDisable
                }
              ]}
            />
            <View style={{ width: Layout.minerScale(50) }}>
              {this.state.listGradesSelected.length > 0 && (
                <Text style={ss.textStyleCount}>
                  {this.state.listGradesSelected.length}
                </Text>
              )}
            </View>
          </View>
          {!!!this.state.reviewGradeSchedule ? (
            <FlatList
              columnWrapperStyle={ss.flatlistStyle}
              numColumns={3}
              data={gradeSchedule}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => {
                const selected = this.state.listGradesSelected.findIndex(
                  grade => grade.uuid === item.uuid
                );
                return (
                  <TouchableOpacity onPress={() => this.onSelectGrade(item)}>
                    <View
                      style={[
                        ss.itemGrade,
                        selected >= 0 && { backgroundColor: colors.bgSelected }
                      ]}
                    >
                      <Text style={ss.textStyle}>{item.name}</Text>
                    </View>
                  </TouchableOpacity>
                );
              }}
            />
          ) : (
            <ReviewGradeSchedule
              reviewGradeSchedule={this.props.reportState.reviewGradeSchedule}
            />
          )}
        </View>
        {/* HIDE to download */}
        <Text
          ref="downloadSchedule"
          style={{ opacity: 0 }}
          onPress={() => {
            this.setState({ allowDownload: false });
            //

            Linking.canOpenURL(this.state.url).then(supported => {
              if (supported) {
                Linking.openURL(this.state.url);
                this.setState({ listGradesSelected: [] });
              } else {
                alert("Can't download file teacher_schedule.xlsx");
              }
            });
          }}
        />
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: spaceSize.margin.medium
  },
  header: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(500),
    //
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    //
    paddingHorizontal: spaceSize.padding.medium,
    marginBottom: spaceSize.margin.medium
  },
  flatlistStyle: {
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "center"
  },
  itemGrade: {
    width: Layout.minerScale(250),
    height: Layout.minerScale(40),
    backgroundColor: colors.bgBody,
    //
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //
    marginRight: spaceSize.margin.medium,
    marginBottom: spaceSize.margin.medium,
    paddingHorizontal: spaceSize.padding.small,
    borderRadius: spaceSize.borderRadius.small
  },
  textStyle: {
    flex: 1,
    fontSize: fontSize.largest,
    color: colors.textBody,
    textAlign: "center"
  },
  textStyleCount: {
    fontSize: fontSize.largest,
    color: colors.textActive,
    fontWeight: "bold"
  }
});
