import { Dimensions, Platform } from "react-native";

const setting = {
  window: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height
  },
  standarWidth: Platform.OS === "web" ? 716 : 414,
  standardHeight: Platform.OS === "web" ? 414 : 716
};

export class Layout {
  static get = () => {
    return setting;
  };
  static set = (width, height) => {
    setting = {
      ...setting,
      window: {
        ...setting.window,
        height: height,
        width: width
      }
    };
  };
  static widthScale = size => {
    const ratio = this.get().window.width / this.get().standarWidth;
    let resultSize = 0;
    if (size.toString().includes("%")) {
      const standarSize = (this.get().standarWidth * parseInt(size)) / 100;
      resultSize = ratio * standarSize;
    } else resultSize = ratio * size;
    return resultSize;
  };

  static heightScale = size => {
    const ratio = this.get().window.height / this.get().standardHeight;
    let resultSize = 0;
    if (size.toString().includes("%")) {
      const standarSize = (this.get().standardHeight * parseInt(size)) / 100;
      resultSize = ratio * standarSize;
    } else resultSize = ratio * size;
    return resultSize;
  };

  static moderateScale = (size, factor = 0.5) =>
    size + (this.width(size) - size) * factor;

  static minerScale = size => {
    const minerSize =
      this.get().window.width < this.get().window.height
        ? this.get().window.width
        : this.get().window.height;
    const minerStandarSize = this.get().standarWidth;
    const ratio = minerSize / minerStandarSize;

    let resultSize = 0;
    if (size.toString().includes("%")) {
      const standarSize = (minerStandarSize * parseInt(size)) / 100;
      resultSize = ratio * standarSize;
    } else resultSize = ratio * size;

    return resultSize;
  };
}
