import React, { Component } from "react";
import { Text, StyleSheet, View, Animated } from "react-native";
import { spaceSize, colors, Layout } from "../../Themes";
import BranchContainer from "../../Screens/Setting/Branch/BranchContainer";
import BranchDetail from "../../Screens/Setting/Branch/BranchDetailScreenContainer";
import Fade from "../Fade";

export default class Branch extends Component {
  state = {
    branch: {}
  };
  selectBranch = branch => {
    this.setState({ branch });
  };
  render() {
    return (
      <Fade>
        <View style={[ss.container]}>
          <View style={ss.vieList}>
            <BranchContainer
              selectBranch={this.selectBranch}
              branch={this.state.branch}
            />
          </View>
          <View style={ss.vieDetail}>
            <BranchDetail branch={this.state.branch || {}}></BranchDetail>
          </View>
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  vieList: {
    flex: 2,
    height: "auto",
    maxHeight: "100%",
    marginRight: spaceSize.margin.largest,
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
