import React, { Component } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import BackHeader from "../../Components/BackHeader";
import IconTitleHeader from "../../Components/IconTitleHeader";
import { Layout } from "../../Themes";
import { FontAwesome } from "@expo/vector-icons";

export default class SettingScreen extends Component {
  constructor(props) {
    super(props);
  }

  routeTo = screen => {
    this.props.navigation.navigate({ routeName: screen });
  };

  componentDidMount() {
    this.props.getSubjects();
    this.props.getGrades();
    this.props.getTimeframes();
    this.props.getBranchs();
    this.props.getAccounts();
  }

  render() {
    return (
      <View style={[styles.container]}>
        <IconTitleHeader
          icon={<FontAwesome name="cog" style={styles.iconHeader} />}
          title={"Cài Đặt"}
        />
        <View style={{ flex: 1, alignItems: "center" }}>
          <TouchableOpacity
            style={[styles.touchableOpacityStyle]}
            onPress={() => this.routeTo("BranchScreen")}
          >
            <Text style={[styles.textStyle]}>{"Chi Nhánh"}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.touchableOpacityStyle]}
            onPress={() => this.routeTo("TimeframeScreen")}
          >
            <Text style={[styles.textStyle]}>{"Thời gian"}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.touchableOpacityStyle]}
            onPress={() => this.routeTo("GradeScreen")}
          >
            <Text style={[styles.textStyle]}>{"Khối"}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.touchableOpacityStyle]}
            onPress={() => this.routeTo("SubjectScreen")}
          >
            <Text style={[styles.textStyle]}>{"Môn học"}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.touchableOpacityStyle]}
            onPress={() => this.routeTo("CourseScreen")}
          >
            <Text style={[styles.textStyle]}>{"Lớp"}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.touchableOpacityStyle]}
            onPress={() => this.routeTo("TeacherScreen")}
          >
            <Text style={[styles.textStyle]}>{"Giảng Viên"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

SettingScreen.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  rightActions: {
    width: Layout.minerScale(100),
    flexDirection: "row",
    marginHorizontal: Layout.minerScale(12)
  },
  textStyle: {
    fontSize: Layout.minerScale(20)
  },
  touchableOpacityStyle: {
    width: "100%",
    height: Layout.minerScale(42),
    paddingHorizontal: Layout.minerScale(12),
    justifyContent: "center"
  },
  verticalLine: {
    height: 1,
    width: "100%",
    backgroundColor: "rgba(128,128,128,0.5)"
  },
  iconHeader: {
    fontSize: Layout.minerScale(24),
    paddingHorizontal: Layout.minerScale(12),
    paddingVertical: Layout.minerScale(12)
  }
});
