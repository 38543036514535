import { MaterialIcons } from '@expo/vector-icons';
import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Layout } from '../Themes/Layout';

export default class BackAndSaveHeader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <View style={styles.headerContainer}>
                <MaterialIcons name="arrow-back" onPress={() => this.props.goBack()} style={styles.iconBack} />
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Text style={styles.title}>{this.props.title}</Text>
                </View>
                <MaterialIcons name="check" onPress={() => this.props.onSave()} style={styles.iconSave} />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        height: Layout.minerScale(50),
        backgroundColor: "#fff",
        zIndex: 1
    },
    iconBack: {
        fontSize: Layout.minerScale(24),
        paddingHorizontal: Layout.minerScale(12),
        paddingVertical: Layout.minerScale(12)
    },
    title: {
        fontSize: Layout.minerScale(24),
        textAlign: 'center'
    },
    iconSave: {
        fontSize: Layout.minerScale(24),
        paddingHorizontal: Layout.minerScale(12),
        paddingVertical: Layout.minerScale(12),
    }
});
