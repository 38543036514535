import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ReportCreators } from "../../Models/Reducers/ReportReducer";
import GradeSchedule from "./GradeSchedule";
const mapStateToProps = (state, props) => {
  return {
    gradeState: state.gradeState,
    reportState: state.reportState,
    branchInfo: state.branchInfo,
    loginState: state.loginState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportCreators
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GradeSchedule);
