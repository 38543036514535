/*
 * check object is has value
 * input: vObject: object, vProp: string key in vObject
 * output: true is object has value else false
 */
export const isHaveValue = (vObject, vProp) => {
  //   console.info(
  //     vObject,
  //     typeof vObject,
  //     Object.prototype.toString.call(vObject)
  //   );
  if (!vProp)
    switch (Object.prototype.toString.call(vObject)) {
      case "[object Undefined]":
        return false;
      case "[object Null]":
        return false;
      case "[object String]":
        if (vObject === "" || vObject === null) return false;
      case "[object Object]":
        for (var key in vObject)
          if (vObject.hasOwnProperty(key)) {
            return true;
          }
        return false;
      case "[object Array]":
        if (vObject.length === 0) return false;
      default:
        return true;
    }
  else {
    //// vObject must to Object & have value
    if (
      Object.prototype.toString.call(vObject) === "[object Object]" &&
      isHaveValue(vObject)
    ) {
      for (var key in vObject) {
        // if (isHaveValue(vObject[vProp])) return true;
        if (key.toString() === vProp && isHaveValue(vObject[key])) return true;

        if (vObject.hasOwnProperty(key)) {
          const returnLoop = isHaveValue(vObject[key], vProp);
          if (returnLoop) return true;
          else if (returnLoop === undefined) return false;
        }
      }
      return false;
    } else return false;
  }
};

export const onlyNumber = (text, charsAccept) => {
  let newText = "";
  let numbers = "0123456789";
  //
  if (charsAccept) numbers = numbers + charsAccept;
  //
  for (var i = 0; i < text.length; i++) {
    if (numbers.indexOf(text[i]) > -1) {
      newText = newText + text[i];
    }
  }
  //
  return newText;
};

export const isEqual = function(value, other) {
  // console.log('isEqual', value, other)
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function(item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const capitalize = s => {
  if (typeof s !== "string") return "";
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};
