import React, { PureComponent } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import Icon from "../../Icon";
import { isHaveValue, onlyNumber } from "../../../Utilities/ValueHandling"; //review
import { string, func } from "prop-types";

export default class Phone extends PureComponent {
  static propTypes = {
    icon: string,
    value: string,
    placeholder: string,
    onReturnPhone: func,
    inputRef: func
  };
  static defaultProps = {
    icon: "",
    value: "",
    placeholder: "",
    onReturnPhone: () => {},
    inputRef: () => {}
  };
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      formated: this.props.value
        ? this.formatPhoneNumber(this.props.value)
        : "",
      valid: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      // && nextProps.phone
      this.onChangePhone(nextProps.value);
    }
  }
  formatPhoneNumber = phone => {
    if (isHaveValue(phone)) {
      let formated = [
        "(",
        "",
        "",
        "",
        ")",
        " ",
        "",
        "",
        "",
        "-",
        "",
        "",
        "",
        ""
      ];
      let index = [1, 2, 3, 6, 7, 8, 10, 11, 12, 13];
      phone !== null &&
        phone.split("").forEach((e, i) => {
          formated[index[i]] = e;
        });
      formated = formated.toString().replace(/,/g, "");
      formated = formated.length < 11 ? formated.replace("-", "") : formated;
      formated = formated.length === 3 ? "" : formated;
      return formated;
    } else return "";
  };

  validatePhone = phone => {
    var re = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    return re.test(phone);
  };
  onChangePhone = textInput => {
    let text;
    if (isHaveValue(textInput)) {
      const textAfterReplace = textInput
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");
      text = onlyNumber(textAfterReplace);
      if (text !== textAfterReplace) {
        return;
      }
    }

    if (!isHaveValue(text)) {
      this.setState({
        value: text,
        formated: "",
        valid: null
      });
      this.props.onReturnPhone(null, false);
    } else {
      this.setState({ value: text, formated: this.formatPhoneNumber(text) });
      this.props.onReturnPhone(text, false); // return phone to parent component
      if (text.length === 10) {
        this.setState({ valid: true });
        this.props.onReturnPhone(text, true);
        const { fetchCustomerByPhone, token } = this.props;
        if (isHaveValue(fetchCustomerByPhone) && isHaveValue(token))
          fetchCustomerByPhone(phone, token); // map customer structure from reducer to component  => fetch finish will return customer data
      }
    }
  };

  render() {
    const { formated } = this.state;
    return (
      <View style={[ss.container, this.props.style]}>
        {this.props.icon && (
          <Icon
            name={this.props.icon}
            size={this.props.styleIcon && this.props.styleIcon.fontSize}
            color={
              !!this.props.validIconColor
                ? !!this.props.value
                  ? this.props.validIconColor
                  : this.props.styleIcon.color
                : this.props.styleIcon.color
            }
          />
        )}
        <TextInput
          ref={input => this.props.inputRef(input)}
          onChangeText={text => this.onChangePhone(text)}
          style={[ss.textInput, this.props.styleText]}
          keyboardType="numeric"
          value={formated}
          maxLength={14}
          placeholder={
            !!this.props.placeholder ? this.props.placeholder : "(xxx) xxx-xxxx"
          }
          onSubmitEditing={this.props.onSubmitEditing}
        />

        {!!this.props.value && (
          <Icon
            name={this.state.valid ? "check" : "close"}
            size={this.props.styleIcon && this.props.styleIcon.fontSize * 0.8}
            color={this.state.valid ? "green" : "red"}
          />
        )}
      </View>
    );
  }
}
const ss = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textInput: {
    marginLeft: 10,
    flex: 1,
    textAlign: "left"
  }
});
