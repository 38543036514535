import React, { Component } from "react";
import {
  Text,
  View,
  Platform,
  StyleSheet,
  TouchableOpacity
} from "react-native";
import { TextField } from "react-native-material-textfield";
import { Layout, colors, spaceSize, fontSize } from "../../../Themes";
import BackHeader from "../../../Components/BackHeader";
import { PrimaryButton } from "../../../Components/CustomButton/CustomButton";

export default class BranchDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      selectedBranch: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (Platform.OS === "web") {
      this.setState({ selectedBranch: nextProps.branch });
    }
  }
  onChangeBranchInfo = (key, value) => {
    let newState = { ...this.state.selectedBranch };
    newState[key] = value;
    this.setState({
      selectedBranch: newState
    });
  };
  onSave = () => {
    this.props.updateBranch(this.state.selectedBranch);
  };
  render() {
    const { selectedBranch } = this.state;

    return (
      <View style={ss.container}>
        {Platform.OS !== "web" && (
          <BackHeader
            goBack={() => this.props.navigation.goBack()}
            title={"Chi nhánh"}
          />
        )}
        <View style={ss.detailBranch}>
          <TextField
            ref={ref => (this.branchName = ref)}
            fontSize={fontSize.large}
            containerStyle={{
              paddingHorizontal: Layout.minerScale(16)
            }}
            labelFontSize={fontSize.medium}
            label={"Tên chi nhánh"}
            value={selectedBranch.name || ""}
            onChangeText={text => this.onChangeBranchInfo("name", text)}
            style={{
              ...Platform.select({
                web: { outlineColor: "transparent" }
              })
            }}
            textColor={colors.textBody}
            tintColor={colors.textPlaceHolder}
            baseColor={colors.textPlaceHolder}
          />
          <TextField
            ref={ref => (this.branchAddress = ref)}
            containerStyle={{
              paddingHorizontal: Layout.minerScale(16)
            }}
            label={"Địa chỉ"}
            value={selectedBranch.address || ""}
            onChangeText={text => this.onChangeBranchInfo("address", text)}
            style={{
              ...Platform.select({
                web: { outlineColor: "transparent" }
              })
            }}
            fontSize={fontSize.large}
            labelFontSize={fontSize.medium}
            textColor={colors.textBody}
            tintColor={colors.textPlaceHolder}
            baseColor={colors.textPlaceHolder}
          />
          <TextField
            ref={ref => (this.email = ref)}
            fontSize={fontSize.large}
            containerStyle={{
              paddingHorizontal: Layout.minerScale(16)
            }}
            labelFontSize={fontSize.medium}
            label={"Email"}
            value={selectedBranch.email || ""}
            onChangeText={text => this.onChangeBranchInfo("email", text)}
            style={{
              ...Platform.select({
                web: { outlineColor: "transparent" }
              })
            }}
            textColor={colors.textBody}
            tintColor={colors.textPlaceHolder}
            baseColor={colors.textPlaceHolder}
          />
          <TextField
            ref={ref => (this.phone = ref)}
            fontSize={fontSize.large}
            containerStyle={{
              paddingHorizontal: Layout.minerScale(16)
            }}
            labelFontSize={fontSize.medium}
            label={"SĐT"}
            value={selectedBranch.phone || ""}
            onChangeText={text => this.onChangeBranchInfo("phone", text)}
            style={{
              ...Platform.select({
                web: { outlineColor: "transparent" }
              })
            }}
            textColor={colors.textBody}
            tintColor={colors.textPlaceHolder}
            baseColor={colors.textPlaceHolder}
          />
          <TextField
            ref={ref => (this.website = ref)}
            fontSize={fontSize.large}
            containerStyle={{
              paddingHorizontal: Layout.minerScale(16)
            }}
            labelFontSize={fontSize.medium}
            label={"Website"}
            value={selectedBranch.website || ""}
            onChangeText={text => this.onChangeBranchInfo("website", text)}
            style={{
              ...Platform.select({
                web: { outlineColor: "transparent" }
              })
            }}
            textColor={colors.textBody}
            tintColor={colors.textPlaceHolder}
            baseColor={colors.textPlaceHolder}
          />
        </View>
        <View
          style={{
            alignItems: "center",
            marginTop: spaceSize.margin.largest
          }}
        >
          <TouchableOpacity style={ss.button} onPress={this.onSave}>
            <Text style={ss.buttonText}>Lưu</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

BranchDetailScreen.navigationOptions = {
  header: null
};

const ss = StyleSheet.create({
  container: {
    flex: 1
  },
  listBranch: {
    height: Layout.heightScale(60),
    width: "100%",
    padding: Layout.heightScale(10)
  },
  selectedBranchText: {
    fontSize: fontSize.large
  },

  detailBranch: {
    flex: 1
  },
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest
  }
});
