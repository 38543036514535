import { StyleSheet, Dimensions } from "react-native";
import { colors, fontSize, Layout, spaceSize } from "../../Themes";

export const color = {
  teacher: "gray",
  course: "green"
};

export const ss = StyleSheet.create({
  container: {
    flex: 1
  },
  dataSelect: {
    height: Layout.heightScale(120),
    marginVertical: spaceSize.margin.largest,
    marginRight: spaceSize.margin.largest,
    flexDirection: "row"
  },
  teacher: {
    flex: 1,
    backgroundColor: colors.bgNav075,
    borderRadius: Layout.minerScale(20),
    overflow: "hidden",
    marginLeft: spaceSize.margin.medium
  },
  title: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: spaceSize.padding.small,
    paddingLeft: spaceSize.padding.large,
    borderBottomColor: colors.line,
    borderBottomWidth: 1
  },
  titleText: {
    marginLeft: spaceSize.margin.medium,
    color: colors.textBody,
    fontSize: fontSize.largest
  },
  selectedText: {
    flex: 1,
    minWidth: 0,
    marginLeft: spaceSize.margin.medium,
    color: colors.textBody,
    fontSize: fontSize.huge
  },
  listData: {
    flex: 1
  },
  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    padding: spaceSize.padding.small
  },
  textInput: {
    fontSize: fontSize.largest,
    marginLeft: spaceSize.margin.medium,
    color: colors.textBody
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.largest
  },
  course: {
    flex: 1,
    backgroundColor: colors.bgNav075,
    borderRadius: Layout.minerScale(20),
    overflow: "hidden",
    marginRight: spaceSize.margin.medium
  },
  timeTable: {
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: colors.bgNav075,
    borderRadius: Layout.minerScale(20),
    overflow: "hidden",
    paddingTop: spaceSize.padding.medium,
    paddingHorizontal: spaceSize.padding.medium,
    marginBottom: spaceSize.margin.largest,
    marginRight: spaceSize.margin.largest
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12)
  },
  timeframeTitleText: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody
  },
  buttonContainer: {
    flexDirection: "row",
    height: Layout.minerScale(70),
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest
  }
});
