import { MaterialIcons } from '@expo/vector-icons';
import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Layout } from '../Themes';


export default class BackHeader extends Component {
    constructor(props) {
        super(props)
    }


    render() {
        return (
            <View style={styles.headerContainer}>
                <MaterialIcons name="arrow-back" onPress={() => this.props.goBack()} style={styles.iconBack} />
                <Text style={styles.title}>{this.props.title}</Text>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: "row",
        alignItems: 'center',
        height: Layout.minerScale(50),
        backgroundColor: "#fff",
        zIndex: 1
    },
    iconBack: {
        fontSize: Layout.minerScale(24),
        paddingHorizontal: Layout.minerScale(12),
        paddingVertical: Layout.minerScale(12)
    },
    title: {
        fontSize: Layout.minerScale(24),
        textAlign: 'center'
    }
});
