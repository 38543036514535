import PropTypes, { func, object, string } from "prop-types";
import React, { PureComponent } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import Icon from "../Icon";

export default class ButtonIcon extends PureComponent {
  static propTypes = {
    icon: PropTypes.string,
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };

  static defaultProps = {
    icon: "",
    style: {},
    styleIcon: {},
    onPress: () => {},
    disabled: false
  };
  render() {
    return (
      <TouchableOpacity
        style={[ss.container, this.props.style]}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
      >
        <Icon
          name={this.props.icon}
          size={this.props.styleIcon && this.props.styleIcon.fontSize}
          color={this.props.styleIcon && this.props.styleIcon.color}
        />
      </TouchableOpacity>
    );
  }
}
const ss = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10
  }
});
