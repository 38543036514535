import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import PropTypes from "prop-types";

//{label: "", value: ""}

export default class PickerScreen extends Component {
  static propTypes = {
    data: PropTypes.array,
    onValueChange: PropTypes.func
  };

  _renderItem = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        style={[ss.item, this.props.styleItem]}
        onPress={() => this.props.onValueChange(item.value)}
      >
        <View style={{ height: 0.5, width: "100%" }} />
        <Text style={[ss.text, this.props.styleItemText]}>{item.label}</Text>
        <View
          style={{
            height: 0.5,
            width: "100%",
            backgroundColor: this.props.colorLine
          }}
        />
      </TouchableOpacity>
    );
  };
  _keyExtractor = (item, index) => index.toString();
  render() {
    const { data } = this.props;
    return (
      <View style={[ss.container, this.props.style]}>
        {data.map((item, index) => this._renderItem(item, index))}
      </View>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    justifyContent: "space-between"
  },
  item: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center"
  },
  text: {
    fontSize: 16
  }
});
