import React, { Component } from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Linking } from "react-native-web";
import { IconTextMate } from "../../Components/CustomButton";
import Fade from "../../Components/Fade";
import SearchInput from "../../MicroComponents/Input/SearchInput/SearchInput";
import { WebPicker } from "../../MicroComponents/Picker";
import { Types as ReportTypes } from "../../Models/Reducers/ReportReducer";
import { BASE_URL } from "../../Models/URL";
import { colors, fontSize, Layout, spaceSize } from "../../Themes";
import { removeVietNamMark } from "../../Utilities/Format";
import { TeacherCard } from "../../Components/TeacherCard";

export default class TeacherInfo extends Component {
  state = {
    url: `${BASE_URL}/report/grade_schedules.xlsx`,
    searchVaue: ""
    // listGradesSelected: [],
    // branchSelected:
    //   this.props.branchInfo &&
    //   this.props.branchInfo.branchs.length > 0 &&
    //   this.props.branchInfo.branchs[0].uuid
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.reportState.type !== "") {
      switch (nextProps.reportState.type) {
        case ReportTypes.DOWNLOAD_TEACHER_INFO_SUCCESS:
          if (this.state.disableButton) {
            this.setState({
              disableButton: !this.state.disableButton,
              allowDownload: true
            });
            //** gán lại url dowload nếu có */
            if (nextProps.reportState.urlDownload)
              this.setState({
                url: `${BASE_URL}${nextProps.reportState.urlDownload}`
              });
            setTimeout(() => {
              this.refs.downloadSchedule.props.onPress();
            }, 500);
          }
          this.props.resetType();
          break;
        case ReportTypes.DOWNLOAD_TEACHER_INFO_FAIL:
          alert(nextProps.reportState.message);
          this.setState({
            disableButton: !this.state.disableButton
          });
          this.props.resetType();
          break;
      }
    }
  }

  dowload = () => {
    this.setState({
      disableButton: !this.state.disableButton
    });
    this.props.downloadTeacherInfo();
  };

  //   onSelectGrade = grade => {
  //     let listGradesSelected = Object.assign([], this.state.listGradesSelected);
  //     //
  //     const gradeIndex = listGradesSelected.findIndex(
  //       item => item.uuid === grade.uuid
  //     );
  //     if (gradeIndex >= 0) listGradesSelected.splice(gradeIndex, 1);
  //     else listGradesSelected.push(grade);
  //     //
  //     this.setState({ listGradesSelected });
  //   };

  render() {
    let teacherInfo = Object.assign([], this.props.teacherState.teachers);
    if (!!this.state.searchVaue)
      teacherInfo = teacherInfo.filter(item =>
        removeVietNamMark(item.name.toLowerCase()).includes(
          removeVietNamMark(this.state.searchVaue.toLowerCase())
        )
      );
    return (
      <Fade>
        <View style={ss.container}>
          <View style={ss.header}>
            <SearchInput
              value={this.state.searchVaue}
              //
              onChangeText={searchVaue => this.setState({ searchVaue })}
              clearSearch={() => this.setState({ searchVaue: "" })}
              //
              maxLength={20}
              //
              styleWrapper={{
                height: "100%",
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",

                backgroundColor: colors.bgBody,
                borderRadius: spaceSize.borderRadius.large * 2
              }}
              styleText={{
                width: "80%",
                fontSize: fontSize.medium,
                color: colors.textBody
              }}
              styleIcon={{ fontSize: fontSize.largest }}
            />
            {/*  */}
            <IconTextMate
              nameIcon={"file-download"}
              onPress={this.dowload}
              disabled={this.props.teacherState.teachers.length === 0}
              //
              styleButton={{
                width: Layout.minerScale(30),
                marginLeft: spaceSize.margin.small,
                marginRight: spaceSize.margin.small
              }}
              styleIcon={[
                {
                  fontSize: Layout.minerScale(30),
                  color: colors.iconDownloadAll
                },
                this.props.teacherState.teachers.length === 0 && {
                  color: colors.iconDisable
                }
              ]}
            />
            <View style={{ width: Layout.minerScale(50) }}>
              {this.props.teacherState.teachers.length > 0 && (
                <Text style={ss.textStyleCount}>
                  {this.props.teacherState.teachers.length}
                </Text>
              )}
            </View>
          </View>

          <FlatList
            columnWrapperStyle={ss.flatlistStyle}
            numColumns={3}
            data={teacherInfo}
            keyExtractor={(item, index) => index.toString()}
            initialNumToRender={1}
            renderItem={({ item, index }) => {
              return (
                <TeacherCard
                  item={item}
                  children={
                    <View style={{}}>
                      <Text style={ss.textStyle}>{`Đơn vị: ${
                        !!item.workAddress ? item.workAddress : ""
                      }`}</Text>

                      <Text style={ss.textStyle}>{`Email: ${
                        !!item.email ? item.email : ""
                      }`}</Text>

                      <Text style={ss.textStyle}>{`Điện thoại: ${
                        !!item.phone ? item.phone : ""
                      }`}</Text>
                    </View>
                  }
                  imageStyle={ss.imageStyle}
                  containerStyle={ss.teacherCardContainer}
                  nameStyle={ss.nameStyle}
                  socialStyle={ss.socialStyle}
                  iconStyle={ss.iconStyle}
                  actionStyle={ss.actionStyle}
                />
              );
            }}
          />
        </View>
        {/* HIDE to download */}
        <Text
          ref="downloadSchedule"
          style={{ opacity: 0 }}
          onPress={() => {
            this.setState({ allowDownload: false });
            //

            Linking.canOpenURL(this.state.url).then(supported => {
              if (supported) {
                Linking.openURL(this.state.url);
                this.setState({ listGradesSelected: [] });
              } else {
                alert("Can't download file daily_schedule.xlsx");
              }
            });
          }}
        />
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: spaceSize.margin.medium
  },
  header: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(500),
    //
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    //
    paddingHorizontal: spaceSize.padding.medium,
    marginBottom: spaceSize.margin.medium
  },
  flatlistStyle: {
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "center"
  },
  nameStyle: {
    flex: 1,
    fontSize: fontSize.largest,
    color: colors.textBody
  },
  textStyle: {
    flex: 1,
    fontSize: fontSize.medium,
    color: colors.textPlaceHolder
  },
  textStyleCount: {
    fontSize: fontSize.largest,
    color: colors.textActive,
    fontWeight: "bold"
  },
  teacherCardContainer: {
    height: Layout.minerScale(130),
    width: Layout.minerScale(400),
    backgroundColor: colors.bgBody,
    marginTop: spaceSize.margin.largest,
    marginRight: spaceSize.margin.largest,
    paddingHorizontal: spaceSize.padding.small,
    borderRadius: spaceSize.borderRadius.small
  },
  imageStyle: {
    height: Layout.minerScale(60),
    width: Layout.minerScale(60),
    borderRadius: Layout.minerScale(60) / 2
  },
  socialStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: Layout.minerScale(30)
  },
  iconStyle: { color: colors.textPlaceHolder, fontSize: Layout.minerScale(20) },
  actionStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    //
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    //
    height: Layout.minerScale(30),
    width: Layout.minerScale(60)
    //
  }
});
