import React, { Component } from "react";
import {
  Animated,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Toast from "react-native-easy-toast";
import { Dropdown } from "react-native-material-dropdown";
import { TextField } from "react-native-material-textfield";
import BackAndSaveHeader from "../../../Components/BackAndSaveHeader";
import { NumberInput } from "../../../MicroComponents/Input";
import { WebPicker } from "../../../MicroComponents/Picker";
import { colors, fontSize, Layout, spaceSize } from "../../../Themes";

export default class CourseDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.refTextInputName = {};
    let course = this.props.navigation
      ? this.props.navigation.getParam("course", {
          id: {
            uuid: null,
          },
          name: "",
          grade:
            this.props.gradeState &&
            this.props.gradeState.grades.length > 0 &&
            this.props.gradeState.grades[0].uuid,
          subject:
            this.props.subjectState &&
            this.props.subjectState.subjects.length > 0 &&
            this.props.subjectState.subjects[0].uuid,
          teacher: null,
          schedules: [],
        })
      : this.props.course;
    let title = this.props.navigation
      ? this.props.navigation.getParam("title", "Tạo mới khóa học")
      : this.props.title;

    this.state = {
      title,
      course,
      subjects: [...this.props.subjectState.subjects].map((subject) => {
        return { ...subject, value: subject.name };
      }),
      grades: [...this.props.gradeState.grades].map((grade) => {
        return { ...grade, value: grade.name };
      }),
      teachers: [...this.props.teacherState.teachers].map((teacher) => {
        let busyTimes = [];
        this.props.courseState.courses
          .filter(
            (f) =>
              f.teacher != null &&
              f.teacher === teacher.uuid &&
              f.teacher !== course.teacher
          )
          .forEach((course) => {
            if (course.schedules)
              course.schedules.forEach((time) => {
                busyTimes.push({
                  weekday: time.weekday,
                  timeframe: time.timeframe,
                  subjectName: course.name,
                });
              });
          });
        return {
          ...teacher,
          busyTimes: busyTimes,
          value: teacher.name,
        };
      }),
      grid: this.props.weekdayState.weekdays
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map((weekday) => {
          return {
            title: weekday.name,
            titleColsapse: weekday.code,
            cells: this.props.timeframeState.timeframes
              .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
              .map((timeframe) => {
                return {
                  weekday: weekday.uuid,
                  timeframe: timeframe.uuid,
                  red: false,
                  yellow: false,
                  gray: false,
                };
              }),
          };
        }),

      branchSelected: null,
      // this.props.branchInfo &&
      // this.props.branchInfo.branchs.length > 0 &&
      // this.props.branchInfo.branchs[0].uuid,
      // price: 0
      // errMaLop: ""
      scrollX: new Animated.Value(0),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (Platform.OS === "web") {
      console.log("componentWillReceiveProps_next", nextProps.course);
      if (nextProps.course.uuid === null)
        this.setState({
          course: {
            id: {
              uuid: null,
            },
            name: "",
            grade:
              this.props.gradeState &&
              this.props.gradeState.grades.length > 0 &&
              this.props.gradeState.grades[0].uuid,
            subject:
              this.props.subjectState &&
              this.props.subjectState.subjects.length > 0 &&
              this.props.subjectState.subjects[0].uuid,
            teacher: null,
            schedules: [],
            fee: 0,
            duration: "",
          },
          branchSelected:
            this.props.branchInfo.branchs.length > 0
              ? this.props.branchInfo.branchs[0].uuid
              : null,
          // price: 0,
          // duration: ""
        });
      else
        this.setState({
          course: nextProps.course,
          //
          branchSelected:
            nextProps.course &&
            nextProps.course.id &&
            nextProps.course.id.subsidiaryUuid
              ? nextProps.course.id.subsidiaryUuid
              : this.props.branchInfo &&
                this.props.branchInfo.branchs.length > 0 &&
                this.props.branchInfo.branchs[0].uuid,
          // price: nextProps.course.fee ? nextProps.course.fee : 0
        });
      //

      this.setState({
        grid: this.state.grid.map((column) => {
          return {
            ...column,
            cells: column.cells.map((cell) => {
              return {
                ...cell,
                red: nextProps.course.schedules.find(
                  (f) =>
                    f.weekday === cell.weekday && f.timeframe === cell.timeframe
                )
                  ? true
                  : false,
              };
            }),
          };
        }),
      });
    }
  }

  onSave = () => {
    if (!!!this.state.course.name) {
      alert("Vui lòng nhập Mã lớp!");
      // this.setState({ errMaLop: "Vui lòng nhập Mã lớp!" });
      return;
    }
    //
    // this.setState({ errMaLop: "" });
    if (this.state.course.id && this.state.course.id.uuid) {
      this.props.updateCourse({
        ...this.state.course,
        id: {
          ...this.state.course.id,
          subsidiaryUuid: this.state.branchSelected,
        },
        // fee: this.state.price
      });
      if (Platform.OS !== "web") this.routeTo("CourseScreen");
    } else {
      this.props.insertCourse({
        ...this.state.course,
        id: { subsidiaryUuid: this.state.branchSelected },
        // fee: this.state.price
      });
      if (Platform.OS !== "web") this.routeTo("CourseScreen");
    }
  };

  routeTo = (screen) => {
    this.props.navigation.navigate({ routeName: screen });
  };

  onTouchCell = (weekday, timeframe) => {
    this.state.course.schedules.find(
      (f) => f.weekday === weekday && f.timeframe === timeframe
    )
      ? this.setState({
          course: {
            ...this.state.course,
            schedules: [
              ...this.state.course.schedules.filter(
                (f) => !(f.weekday === weekday && f.timeframe === timeframe)
              ),
            ],
          },
        })
      : this.setState({
          course: {
            ...this.state.course,
            schedules: [...this.state.course.schedules, { weekday, timeframe }],
          },
        });
    this.setState({
      grid: this.state.grid.map((column) => {
        return {
          ...column,
          cells: column.cells.map((cell) => {
            return {
              ...cell,
              red:
                cell.weekday === weekday && cell.timeframe === timeframe
                  ? !cell.red
                  : cell.red,
            };
          }),
        };
      }),
    });
  };
  onSelectedGrade = (text, index, data) => {
    this.setState({
      course: {
        ...this.state.course,
        grade: text === "" ? null : data[index].uuid,
      },
    });
  };
  onSelectedSubject = (text, index, data) => {
    this.setState({
      course: {
        ...this.state.course,
        subject: text === "" ? null : data[index].uuid,
      },
    });
  };
  onSelectedTime = (text, index, data) => {
    this.setState({
      course: {
        ...this.state.course,
        timeframes: text === "" ? null : data[index].uuid,
      },
    });
  };
  onSelectedTeacher = (text, index, data) => {
    let teacher = data[index];
    this.setState({
      course: {
        ...this.state.course,
        teacher: text === "" ? null : teacher.uuid,
      },
      grid: this.state.grid.map((column) => {
        return {
          ...column,
          cells: column.cells.map((cell) => {
            let freeTime =
              teacher && teacher.teachingTimes
                ? teacher.teachingTimes.find(
                    (teacherTime) =>
                      teacherTime.weekday === cell.weekday &&
                      teacherTime.timeframe === cell.timeframe
                  )
                : null;
            let busyTime =
              teacher && teacher.busyTimes
                ? teacher.busyTimes.find(
                    (busyTime) =>
                      busyTime.weekday === cell.weekday &&
                      busyTime.timeframe === cell.timeframe
                  )
                : null;
            return {
              ...cell,
              yellow: freeTime ? true : false,
              gray: busyTime ? true : false,
              subjectName: busyTime ? busyTime.subjectName : "",
            };
          }),
        };
      }),
    });
  };
  onChangeName = (text) => {
    this.setState({
      course: {
        ...this.state.course,
        name: text,
      },
    });
  };

  onChangePrice = (text) => {
    this.setState({
      course: {
        ...this.state.course,
        fee: text,
      },
    });
  };

  onChangeDuration = (text) => {
    this.setState({
      course: {
        ...this.state.course,
        duration: text,
      },
    });
  };
  getColumnTimeWidth = () => {
    if (Platform.OS === "web") {
      const { width, height } = Dimensions.get("window");
      let ratio = width / height;
      return ratio < 1.3
        ? Layout.minerScale(60)
        : ratio < 1.8
        ? Layout.minerScale(70)
        : Layout.minerScale(80);
    } else {
      return Layout.minerScale(80);
    }
  };
  render() {
    const width = this.getColumnTimeWidth();
    const ratio =
      Dimensions.get("window").width / Dimensions.get("window").height;

    // list subjects
    let subjects = this.state.subjects
      .filter((f) => f.gradeUuid === this.state.course.grade)
      .map((item) => {
        return {
          value: item.uuid,
          label: item.name,
        };
      });
    subjects.unshift({ value: "", label: "Không" });

    // list branchs
    let branchs = this.props.branchInfo.branchs.map((item) => {
      return {
        value: item.uuid,
        label: item.name,
      };
    });
    branchs.unshift({ value: "", label: "Không" });

    // list grades
    let grades = this.state.grades.map((item) => {
      return {
        value: item.uuid,
        label: item.name,
      };
    });
    grades.unshift({ value: "", label: "Không" });

    return (
      <View style={styles.container}>
        {Platform.OS !== "web" && (
          <BackAndSaveHeader
            title={this.state.title}
            goBack={() => this.routeTo("CourseScreen")}
            onSave={() => this.onSave()}
          />
        )}
        <KeyboardAvoidingView
          enableOnAndroid={true}
          style={{ flex: 1, marginBottom: Layout.minerScale(12) }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <View style={{ flex: 1 }}>
            <TextField
              ref={(ref) => (this.refTextInputName = ref)}
              label={"Mã Lớp"}
              value={this.state.course.name}
              onChangeText={(text) => this.onChangeName(text)}
              // error={this.state.errMaLop}
              // errorColor={colors.focus}
              //
              fontSize={Layout.minerScale(20)}
              labelFontSize={Layout.minerScale(16)}
              style={{
                ...Platform.select({ web: { outlineColor: "transparent" } }),
              }}
              textColor={colors.textBody}
              tintColor={colors.textPlaceHolder}
              baseColor={colors.textPlaceHolder}
            />
            {/* Chi nhánh */}
            <View>
              {
                Platform.OS === "web" ? (
                  <View style={styles.viePicker}>
                    <Text style={styles.txtLabel}>{"Chi nhánh"}</Text>
                    <WebPicker
                      currentValue={this.state.branchSelected}
                      options={branchs}
                      style={{ width: Layout.minerScale(200) }}
                      onChange={(value) => {
                        this.setState({ branchSelected: value });
                      }}
                    />
                  </View>
                ) : null
                // <Dropdown
                //   label="Chi nhánh"
                //   fontSize={Layout.minerScale(20)}
                //   labelFontSize={Layout.minerScale(16)}
                //   itemCount={7}
                //   dropdownPosition={0}
                //   dropdownOffset={{ top: Layout.minerScale(16), left: 0 }}
                //   data={this.state.grades}
                //   onChangeText={(text, index, data) =>
                //     this.onSelectedGrade(text, index, data)
                //   }
                //   value={
                //     this.state.grades.find(
                //       grade => grade.uuid === this.state.course.grade
                //     )
                //       ? this.state.grades.find(
                //           grade => grade.uuid === this.state.course.grade
                //         ).value
                //       : ""
                //   }
                // />
              }
            </View>
            {/* Khối lớp */}
            <View>
              {Platform.OS === "web" ? (
                <View style={styles.viePicker}>
                  <Text style={styles.txtLabel}>{"Khối"}</Text>
                  <WebPicker
                    currentValue={
                      this.state.grades.find(
                        (grade) => grade.uuid === this.state.course.grade
                      )
                        ? this.state.grades.find(
                            (grade) => grade.uuid === this.state.course.grade
                          ).uuid
                        : ""
                    }
                    options={grades}
                    style={{ width: Layout.minerScale(200) }}
                    onChange={(value) => {
                      console.log("=====value", value);

                      const gradesIndex = this.state.grades.findIndex(
                        (f) => f.uuid === value
                      );
                      console.log("=====", gradesIndex);

                      this.onSelectedGrade(
                        gradesIndex >= 0
                          ? this.state.grades[gradesIndex].name
                          : "",
                        gradesIndex,
                        this.state.grades
                      );
                    }}
                  />
                </View>
              ) : (
                <Dropdown
                  label="Khối lớp"
                  fontSize={Layout.minerScale(20)}
                  labelFontSize={Layout.minerScale(16)}
                  itemCount={7}
                  dropdownPosition={0}
                  dropdownOffset={{ top: Layout.minerScale(16), left: 0 }}
                  data={this.state.grades}
                  onChangeText={(text, index, data) =>
                    this.onSelectedGrade(text, index, data)
                  }
                  value={
                    this.state.grades.find(
                      (grade) => grade.uuid === this.state.course.grade
                    )
                      ? this.state.grades.find(
                          (grade) => grade.uuid === this.state.course.grade
                        ).value
                      : ""
                  }
                />
              )}
            </View>
            {/* Môn học */}
            <View>
              {Platform.OS === "web" ? (
                <View style={styles.viePicker}>
                  <Text style={styles.txtLabel}>{"Môn"}</Text>

                  <WebPicker
                    currentValue={
                      this.state.subjects.find(
                        (subject) => subject.uuid === this.state.course.subject
                      )
                        ? this.state.subjects.find(
                            (subject) =>
                              subject.uuid === this.state.course.subject
                          ).uuid
                        : ""
                    }
                    options={subjects}
                    style={{ width: Layout.minerScale(200) }}
                    onChange={(value) => {
                      const subjectsIndex = this.state.subjects.findIndex(
                        (f) => f.uuid === value
                      );
                      this.onSelectedSubject(
                        subjectsIndex >= 0
                          ? this.state.subjects[subjectsIndex].name
                          : "",
                        subjectsIndex,
                        this.state.subjects
                      );
                    }}
                  />
                </View>
              ) : (
                <Dropdown
                  label="Môn học"
                  fontSize={Layout.minerScale(20)}
                  labelFontSize={Layout.minerScale(16)}
                  itemCount={7}
                  dropdownPosition={0}
                  dropdownOffset={{ top: Layout.minerScale(16), left: 0 }}
                  data={this.state.subjects}
                  onChangeText={(text, index, data) =>
                    this.onSelectedSubject(text, index, data)
                  }
                  value={
                    this.state.subjects.find(
                      (subject) => subject.uuid === this.state.course.subject
                    )
                      ? this.state.subjects.find(
                          (subject) =>
                            subject.uuid === this.state.course.subject
                        ).value
                      : ""
                  }
                />
              )}
            </View>
            {/* Học phí */}
            <View>
              {
                Platform.OS === "web" ? (
                  <View style={[styles.viePicker]}>
                    <Text style={styles.txtLabel}>{"Học phí"}</Text>
                    {/*  */}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: Layout.minerScale(300),
                        height: Layout.minerScale(35),
                      }}
                    >
                      <View
                        style={{
                          width: Layout.minerScale(150),
                          height: Layout.minerScale(35),
                          borderWidth: spaceSize.boderWidth.small,
                          borderRadius: spaceSize.borderRadius.small,
                          borderColor: colors.textBody,
                        }}
                      >
                        <NumberInput
                          input={[styles.inputCurrency]}
                          percentStyle={styles.iconCurrency}
                          textStyle={styles.textCurrency}
                          isCurrency={true}
                          value={this.state.course.fee}
                          onReturnNumber={(text) => this.onChangePrice(text)}
                          maxLength={20}
                          // isFractions={false}
                        />
                      </View>
                      <TextInput
                        value={this.state.course.duration}
                        onChangeText={(text) => this.onChangeDuration(text)}
                        style={{
                          width: Layout.minerScale(100),
                          height: Layout.minerScale(35),
                          color: colors.textBody,
                          fontSize: Layout.minerScale(16),
                          borderWidth: spaceSize.boderWidth.small,
                          borderRadius: spaceSize.borderRadius.small,
                          borderColor: colors.textBody,
                          marginLeft: spaceSize.margin.small,
                          paddingHorizontal: spaceSize.padding.small,
                        }}
                        placeholder={`Thời lượng`}
                      />
                    </View>
                  </View>
                ) : null
                // <Dropdown
                //   label="Môn học"
                //   fontSize={Layout.minerScale(20)}
                //   labelFontSize={Layout.minerScale(16)}
                //   itemCount={7}
                //   dropdownPosition={0}
                //   dropdownOffset={{ top: Layout.minerScale(16), left: 0 }}
                //   data={this.state.subjects}
                //   onChangeText={(text, index, data) =>
                //     this.onSelectedSubject(text, index, data)
                //   }
                //   value={
                //     this.state.subjects.find(
                //       subject => subject.uuid === this.state.course.subject
                //     )
                //       ? this.state.subjects.find(
                //           subject => subject.uuid === this.state.course.subject
                //         ).value
                //       : ""
                //   }
                // />
              }
            </View>

            <View style={[styles.timeTable]}>
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  },
                  ratio < 1.2 && { width: "100%" },
                ]}
              >
                <View style={styles.timeframeTitle}>
                  <Text style={[styles.timeframeTitleText, { opacity: 0 }]}>
                    {this.props.timeframeState.timeframes[0].beginTime +
                      " - " +
                      this.props.timeframeState.timeframes[0].endTime}
                  </Text>
                </View>

                <View style={{ overflow: "hidden" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                    }}
                  >
                    {this.state.grid.map((column, idx1) => (
                      <View key={idx1} style={{ width: width }}>
                        <View
                          style={{
                            borderWidth: Layout.minerScale(1),
                            borderColor: colors.line,
                            height: Layout.minerScale(32),
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: fontSize.large,
                              color: colors.textNav,
                            }}
                          >
                            {column.title}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <ScrollView
                showsVerticalScrollIndicator={false}
                style={[
                  {
                    flex: 1,
                  },
                  // Platform.OS === "web" && { alignItems: "center" }
                ]}
                contentContainerStyle={
                  Platform.OS === "web" && { alignItems: "center" }
                }
              >
                <View
                  style={[
                    {
                      flexDirection: "row",
                    },
                    ratio < 1.2 && { width: "100%" },
                  ]}
                >
                  <View>
                    {this.props.timeframeState.timeframes
                      .sort((a, b) => a.beginTime - b.beginTime)
                      .map((timeframe, index) => (
                        <View key={index} style={styles.timeframeTitle}>
                          <Text style={styles.timeframeTitleText}>
                            {timeframe.beginTime + " - " + timeframe.endTime}
                          </Text>
                        </View>
                      ))}
                  </View>
                  <ScrollView
                    style={{ flexDirection: "row", flex: 1 }}
                    contentContainerStyle={{
                      justifyContent: "center",
                      width: "100%",
                    }}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    horizontal={true}
                  >
                    <View style={{ flexDirection: "row", flex: 1 }}>
                      {this.state.grid.map((column, idx1) => (
                        <View
                          key={idx1}
                          style={{
                            width: width,
                          }}
                        >
                          {/* <View
                            style={{
                              borderWidth: Layout.minerScale(1),
                              borderColor: colors.textBody,
                              height: Layout.minerScale(32),
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Text
                              style={{
                                fontSize: fontSize.large,
                                color: colors.textBody
                              }}
                            >
                              {width <= Layout.minerScale(70)
                                ? column.titleColsapse
                                : column.title}
                            </Text>
                          </View> */}
                          {column.cells.map((cell, idx2) => {
                            let color = cell.red
                              ? cell.gray
                                ? "black"
                                : cell.yellow
                                ? "green"
                                : "red"
                              : cell.gray
                              ? "gray"
                              : cell.yellow
                              ? "yellow"
                              : "transparent";
                            return (
                              <TouchableOpacity
                                key={idx2}
                                style={{
                                  borderWidth: Layout.minerScale(1),
                                  borderColor: colors.textBody,
                                  height: Layout.minerScale(32),
                                  backgroundColor: color,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onPress={() => {
                                  this.onTouchCell(
                                    cell.weekday,
                                    cell.timeframe
                                  );
                                }}
                              >
                                <Text style={{ color: "white" }}>
                                  {color === colors.textBody ||
                                  color === "black"
                                    ? cell.subjectName
                                    : ""}
                                </Text>
                              </TouchableOpacity>
                            );
                          })}
                        </View>
                      ))}
                    </View>
                  </ScrollView>
                </View>
              </ScrollView>
            </View>
            <Toast
              ref="toast"
              fadeInDuration={500}
              fadeOutDuration={750}
              opacity={0.8}
            />
          </View>
          <View
            style={{
              alignItems: "center",
              marginTop: spaceSize.margin.largest,
            }}
          >
            <TouchableOpacity
              style={styles.button}
              onPress={() => this.onSave()}
            >
              <Text style={styles.buttonText}>Lưu</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </View>
    );
  }
}

CourseDetailScreen.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed",
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  timeTable: {
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: Layout.minerScale(20),
    overflow: "hidden",
    paddingTop: spaceSize.padding.medium,
    paddingHorizontal: spaceSize.padding.medium,
    marginRight: spaceSize.margin.largest,
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12),
  },
  timeframeTitleText: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody,
  },
  subjectSwitchContainer: {
    flexDirection: "row",
    height: Layout.minerScale(48),
    alignItems: "center",
  },
  nameSubjectSwitch: {
    fontSize: Layout.minerScale(22),
    color: colors.textBody,
    paddingHorizontal: Layout.minerScale(12),
  },
  titleSubjectSwitchContainer: {
    fontSize: Layout.minerScale(22),
    color: colors.textBody,
  },
  titleTimeframeContainer: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody,
    paddingBottom: Layout.minerScale(8),
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12),
  },
  timeframeTitleText: {
    fontSize: fontSize.large,
    color: colors.textBody,
  },
  viePicker: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: spaceSize.margin.small,
  },
  txtLabel: {
    color: colors.textBody,
    fontSize: Layout.minerScale(20),
    textAlign: "left",
  },
  inputCurrency: {
    flex: 1,
    height: "100%",
    paddingHorizontal: spaceSize.padding.small,
  },
  textCurrency: {
    height: "100%",
    fontSize: fontSize.medium,
    color: colors.textBody,
    textAlign: "left",
  },
  iconCurrency: {
    color: colors.textBody,
    fontSize: 12,
    marginLeft: spaceSize.margin.small,
  },
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large,
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest,
  },
});
