import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getTeachers: [],
  getTeachersSuccess: null,
  getTeachersFail: null,
  //
  insertTeacher: ["teacher"],
  insertTeacherSuccess: null,
  insertTeacherFail: null,
  //
  deleteTeacher: ["uuid"],
  deleteTeacherSuccess: null,
  deleteTeacherFail: null,
  //
  updateTeacher: ["teacher"],
  updateTeacherSuccess: null,
  updateTeacherFail: null
});

export const INITIAL_STATE = {
  type: "",
  teachers: [],
  message: ""
};

const getTeachers = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getTeachersSuccess = (state, action) => {
  return {
    type: action.type,
    teachers: action.teachers,
    message: action.message
  };
};

const getTeachersFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertTeacher = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertTeacherSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertTeacherFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const deleteTeacher = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteTeacherSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteTeacherFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const updateTeacher = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateTeacherSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateTeacherFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const HANDLERS = {
  [Types.GET_TEACHERS]: getTeachers,
  [Types.GET_TEACHERS_SUCCESS]: getTeachersSuccess,
  [Types.GET_TEACHERS_FAIL]: getTeachersFail,
  //
  [Types.INSERT_TEACHER]: insertTeacher,
  [Types.INSERT_TEACHER_SUCCESS]: insertTeacherSuccess,
  [Types.INSERT_TEACHER_FAIL]: insertTeacherFail,
  //
  [Types.DELETE_TEACHER]: deleteTeacher,
  [Types.DELETE_TEACHER_SUCCESS]: deleteTeacherSuccess,
  [Types.DELETE_TEACHER_FAIL]: deleteTeacherFail,
  //
  [Types.UPDATE_TEACHER]: updateTeacher,
  [Types.UPDATE_TEACHER_SUCCESS]: updateTeacherSuccess,
  [Types.UPDATE_TEACHER_FAIL]: updateTeacherFail
};

export const teacherState = createReducer(INITIAL_STATE, HANDLERS);
