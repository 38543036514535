import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/ReportReducer";

function* downloadTeacherSchedule(action) {
  try {
    let result = yield call(() =>
      API.post("/app/report/teacher/schedules", action.sendData).then(
        response => response.data
      )
    );
    yield put({
      type: Types.DOWNLOAD_TEACHER_SCHEDULE_SUCCESS,
      response: result.response
    });
  } catch (e) {
    yield put({
      type: Types.DOWNLOAD_TEACHER_SCHEDULE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* reviewGradeSchedule(action) {
  try {
    let result = yield call(() =>
      API.post("/app/report/grade/schedules/view", {
        subsidiary: action.branch,
        gradeUuids: action.listGrade
      }).then(response => response.data)
    );
    yield put({
      type: Types.REVIEW_GRADE_SCHEDULE_SUCCESS,
      response: result.response
    });
  } catch (e) {
    yield put({
      type: Types.REVIEW_GRADE_SCHEDULE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* downloadGradeSchedule(action) {
  try {
    let result = yield call(() =>
      API.post("/app/report/grade/schedules", {
        subsidiary: action.branch,
        gradeUuids: action.listGrade
      }).then(response => response.data)
    );
    if (result.status === 1)
      yield put({
        type: Types.DOWNLOAD_GRADE_SCHEDULE_SUCCESS,
        response: result.response
      });
    else
      yield put({
        type: Types.DOWNLOAD_GRADE_SCHEDULE_FAIL,
        message: "Lỗi"
      });
  } catch (e) {
    yield put({
      type: Types.DOWNLOAD_GRADE_SCHEDULE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* downloadTeacherInfo(action) {
  try {
    let result = yield call(() =>
      API.post("/app/report/teachers").then(response => response.data)
    );
    yield put({
      type: Types.DOWNLOAD_TEACHER_INFO_SUCCESS,
      response: result.response
    });
  } catch (e) {
    yield put({
      type: Types.DOWNLOAD_TEACHER_INFO_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* sendEmail(action) {
  try {
    let result = yield call(() =>
      API.post(
        "/app/report/teacher/schedules/send/to/email",
        action.sendData
      ).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.SEND_EMAIL_SUCCESS
      });
    } else {
      yield put({
        type: Types.SEND_EMAIL_FAIL,
        message: "Lỗi!"
      });
    }
  } catch (e) {
    yield put({
      type: Types.SEND_EMAIL_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* views(action) {
  try {
    let result = yield call(() =>
      API.get("/app/report/teacher/schedules").then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.VIEWS_SUCCESS,
        schedules: result.response,
        message: "Success"
      });
    }
  } catch (e) {}
}

function* getDailySchedule(action) {
  try {
    let result = yield call(() =>
      API.post("/app/report/daily/schedules/", {
        subsidiaryUuid: action.sendData
      }).then(response => response.data)
    );

    if (result.status === 1) {
      yield put({
        type: Types.GET_DAILY_SCHEDULE_SUCCESS,
        dailySchedule: result.response
      });
    } else {
      yield put({
        type: Types.GET_DAILY_SCHEDULE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_DAILY_SCHEDULE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* ReportSaga() {
  yield takeLatest(Types.DOWNLOAD_TEACHER_SCHEDULE, downloadTeacherSchedule);
  yield takeLatest(Types.REVIEW_GRADE_SCHEDULE, reviewGradeSchedule);
  yield takeLatest(Types.DOWNLOAD_GRADE_SCHEDULE, downloadGradeSchedule);
  yield takeLatest(Types.DOWNLOAD_TEACHER_INFO, downloadTeacherInfo);
  yield takeLatest(Types.SEND_EMAIL, sendEmail);
  yield takeLatest(Types.GET_DAILY_SCHEDULE, getDailySchedule);
  yield takeLatest(Types.VIEWS, views);
}

export default ReportSaga;
