import React from "react";
import { Provider } from "react-redux";
import { StatusBar, View, StyleSheet } from "react-native";
import { SafeAreaView } from "react-navigation";
import { PersistGate } from "redux-persist/integration/react";
import Constants from "expo-constants";
console.disableYellowBox = true; //disableYellowBox

import RootContainer from "./src";
import configStore from "./src/Store";

export default function App() {
  return (
    <Provider store={configStore.store}>
      <PersistGate loading={null} persistor={configStore.persistor}>
        <SafeAreaView style={{ flex: 1 }}>
          <StatusBar barStyle={"light-content"} />
          <View style={ss.statusBar} />
          <RootContainer onNavigationStateChange={null} />
        </SafeAreaView>
      </PersistGate>
    </Provider>
  );
}
const ss = StyleSheet.create({
  statusBar: {
    height: Constants.statusBarHeight
  }

  // rest of the styles
});
