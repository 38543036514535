import { connect } from "react-redux";
import AppNavigation from "./AppNavigation";
import {
  createReactNavigationReduxMiddleware,
  createReduxContainer
} from "react-navigation-redux-helpers";

const middleware = createReactNavigationReduxMiddleware(state => state.nav);
const AppWithNavigationState = createReduxContainer(AppNavigation);
const mapStateToProps = state => ({ state: state.nav });
const ReduxNavigation = connect(mapStateToProps)(AppWithNavigationState);

export { middleware, ReduxNavigation };
