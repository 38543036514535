import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getGrades: [],
  getGradesSuccess: null,
  getGradesFail: null,
  //
  insertGrade: ["grade"],
  insertGradeSuccess: null,
  insertGradeFail: null,
  //
  deleteGrade: ["uuid"],
  deleteGradeSuccess: null,
  deleteGradeFail: null,
  //
  updateGrade: ["grade"],
  updateGradeSuccess: null,
  updateGradeFail: null
});

export const INITIAL_STATE = {
  type: "",
  grades: [],
  message: ""
};

const getGrades = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getGradesSuccess = (state, action) => {
  return {
    type: action.type,
    grades: action.grades,
    message: action.message
  };
};

const getGradesFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const insertGrade = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertGradeSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertGradeFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const deleteGrade = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteGradeSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteGradeFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const updateGrade = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateGradeSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateGradeFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};


const HANDLERS = {
  [Types.GET_GRADES]: getGrades,
  [Types.GET_GRADES_SUCCESS]: getGradesSuccess,
  [Types.GET_GRADES_FAIL]: getGradesFail,
  //
  [Types.INSERT_GRADE]: insertGrade,
  [Types.INSERT_GRADE_SUCCESS]: insertGradeSuccess,
  [Types.INSERT_GRADE_FAIL]: insertGradeFail,
  //
  [Types.DELETE_GRADE]: deleteGrade,
  [Types.DELETE_GRADE_SUCCESS]: deleteGradeSuccess,
  [Types.DELETE_GRADE_FAIL]: deleteGradeFail,
  //
  [Types.UPDATE_GRADE]: updateGrade,
  [Types.UPDATE_GRADE_SUCCESS]: updateGradeSuccess,
  [Types.UPDATE_GRADE_FAIL]: updateGradeFail,
};

export const gradeState = createReducer(INITIAL_STATE, HANDLERS);
