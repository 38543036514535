import { StyleSheet } from "react-native";
import { colors, spaceSize, fontSize } from "../../Themes";
const buttonStatusDef = {
  width: "100%",
  // height: 25,
  alignItems: "center",
  justifyContent: "center",
  padding: 4,
  backgroundColor: colors.focus,
  borderRadius: spaceSize.borderRadius.medium
};
export const ss = StyleSheet.create({
  plainBg: {
    borderWidth: 1,
    borderColor: "white",
    width: 200
  },
  iconBg: {
    width: 40,
    height: 40,
    borderRadius: 50,
    marginRight: 10
  },
  //=======IconButton======
  icon: {
    color: "white",
    fontSize: 16
  },
  buttonBg: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginHorizontal: 8,
    //
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.focus
  },
  //=======================
  //=======StatusButton====
  statusBg: { ...buttonStatusDef, backgroundColor: colors.wait },
  Waiting: { ...buttonStatusDef, backgroundColor: colors.wait },
  Serving: { ...buttonStatusDef, backgroundColor: colors.busy },
  Done: { ...buttonStatusDef, backgroundColor: colors.white },
  "Q.Sale": { ...buttonStatusDef, backgroundColor: colors.focus },
  statusText: {
    color: colors.text,
    fontWeight: "bold",
    fontSize: fontSize.small
  },
  txtDone: {
    color: colors.black
  },
  //=======================
  //=======FillButton======
  fillBg: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.btnDisable,
    borderRadius: 4,
    overflow: "hidden"
  },
  fillText: {
    fontSize: fontSize.medium,
    color: "white",
    fontWeight: "bold"
  },
  fillIcon: {
    fontSize: 16,
    color: "white"
  },
  activeBg: {
    backgroundColor: colors.focus
  },
  textName: {
    fontSize: fontSize.medium,
    color: "white",
    textAlign: "center",
    fontWeight: "bold"
  },
  //=======================
  //=======IconTextButton==
  btnIconTextBg: {
    width: "100%",
    height: 40,
    alignItems: "center",
    flexDirection: "row"
  },
  iconBtnIconText: {
    fontSize: 34,
    color: "white",
    marginRight: 12
  },
  textBtnIconText: {
    fontSize: fontSize.medium,
    fontWeight: "bold",
    color: "white",
    textAlign: "left"
  }
  //=======================
});
