import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getAccounts: null,
  getAccountsSuccess: null,
  getAccountsFail: null,

  register: ["data"],
  registerSuccess: null,
  registerFail: null,

  deleteAccount: ["uuid"],
  deleteAccountSuccess: null,
  deleteAccountFail: null,

  resetTypeAccount: null
});

export const INITIAL_STATE = {
  type: "",
  listAccount: [],
  message: ""
};

const getAccounts = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getAccountsSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    listAccount: action.listAccount
  });
};

const getAccountsFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const register = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const registerSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const registerFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteAccount = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const deleteAccountSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const deleteAccountFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const resetTypeAccount = (state, action) => {
  return Object.assign({}, state, {
    type: ""
  });
};

const HANDLERS = {
  [Types.GET_ACCOUNTS]: getAccounts,
  [Types.GET_ACCOUNTS_SUCCESS]: getAccountsSuccess,
  [Types.GET_ACCOUNTS_FAIL]: getAccountsFail,

  [Types.REGISTER]: register,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAIL]: registerFail,

  [Types.DELETE_ACCOUNT]: deleteAccount,
  [Types.DELETE_ACCOUNT_SUCCESS]: deleteAccountSuccess,
  [Types.DELETE_ACCOUNT_FAIL]: deleteAccountFail,

  [Types.RESET_TYPE_ACCOUNT]: resetTypeAccount
};

export const accountInfo = createReducer(INITIAL_STATE, HANDLERS);
