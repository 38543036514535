import { EvilIcons } from "@expo/vector-icons";
import Moment from "moment";
import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity
} from "react-native";
import Toast from "react-native-easy-toast";
import { TextField } from "react-native-material-textfield";
import DateTimePicker from "react-native-modal-datetime-picker";
import BackAndSaveHeader from "../../Components/BackAndSaveHeader";
import { WebPicker } from "../../MicroComponents/Picker";
import { enumHours } from "../../Utilities/Enum";
import { Layout, colors, fontSize, spaceSize } from "../../Themes";
import { PrimaryButton } from "../../Components/CustomButton/CustomButton";

export default class TimeframeDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.refTextInputName = {};
    this.state = {
      title: this.props.navigation
        ? this.props.navigation.getParam("title", "Tạo mới")
        : this.props.title,
      timeframe: this.props.navigation
        ? this.props.navigation.getParam("timeframe", {
            uuid: null,
            name: "",
            beginTime: "",
            endTime: ""
          })
        : this.props.timeframe,
      isDateTimePickerVisible: false,
      dateTimePickerType: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (Platform.OS === "web")
      this.setState({
        timeframe: nextProps.timeframe
      });
  }

  onChangeName = text => {
    this.setState({
      timeframe: {
        ...this.state.timeframe,
        name: text
      }
    });
  };

  showDateTimePicker = type => {
    this.setState({
      isDateTimePickerVisible: true,
      dateTimePickerType: type
    });
    this.refTextInputName.blur();
  };

  cancelDateTimePicker = () => {
    switch (this.state.dateTimePickerType) {
      case "BeginTime":
        this.setState({
          timeframe: {
            ...this.state.timeframe,
            beginTime: ""
          }
        });
        break;
      case "EndTime":
        this.setState({
          timeframe: {
            ...this.state.timeframe,
            endTime: ""
          }
        });
        break;
      default:
        break;
    }
    this.setState({
      isDateTimePickerVisible: false,
      dateTimePickerType: ""
    });
  };

  confirmDatePicked = (date, dateTimePickerTypeParam) => {
    const dateTimePickerType = dateTimePickerTypeParam
      ? dateTimePickerTypeParam
      : this.state.dateTimePickerType;
    //
    switch (dateTimePickerType) {
      case "BeginTime":
        this.setState({
          timeframe: {
            ...this.state.timeframe,
            beginTime: Moment(date).format("HH:mm")
          }
        });
        break;
      case "EndTime":
        this.setState({
          timeframe: {
            ...this.state.timeframe,
            endTime: Moment(date).format("HH:mm")
          }
        });
        break;
      default:
        break;
    }
    this.setState({
      isDateTimePickerVisible: false,
      dateTimePickerType: ""
    });
  };

  routeTo = screen => {
    this.props.navigation.navigate({ routeName: screen });
  };

  onSave = () => {
    if (!this.state.timeframe.name || this.state.timeframe.name === "") {
      const message = "Nhập tên khung thời gian";
      if (Platform.OS === "web") alert(message);
      else this.refs.toast.show(message);

      return;
    }
    if (
      !this.state.timeframe.beginTime ||
      this.state.timeframe.beginTime === ""
    ) {
      const message = "Nhập thời điểm bắt đầu";
      if (Platform.OS === "web") alert(message);
      else this.refs.toast.show(message);
      return;
    }
    if (!this.state.timeframe.endTime || this.state.timeframe.endTime === "") {
      const message = "Nhập thời điểm kết thúc";
      if (Platform.OS === "web") alert(message);
      else this.refs.toast.show(message);
      return;
    }
    if (this.state.timeframe.uuid) {
      this.props.updateTimeframe(this.state.timeframe);
      if (Platform.OS !== "web") this.routeTo("TimeframeScreen");
    } else {
      this.props.insertTimeframe(this.state.timeframe);
      if (Platform.OS !== "web") this.routeTo("TimeframeScreen");
    }
  };

  render() {
    return (
      <View style={[styles.container]}>
        {Platform.OS !== "web" && (
          <BackAndSaveHeader
            title={this.state.title}
            goBack={() => this.routeTo("TimeframeScreen")}
            onSave={() => this.onSave()}
          />
        )}
        <KeyboardAvoidingView
          style={{ flex: 1, marginBottom: Layout.minerScale(12) }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <TextField
            ref={ref => (this.refTextInputName = ref)}
            fontSize={fontSize.large}
            labelFontSize={fontSize.medium}
            containerStyle={{ paddingHorizontal: Layout.minerScale(16) }}
            label={"Tên khung thời gian giảng dạy"}
            value={this.state.timeframe.name}
            onChangeText={text => this.onChangeName(text)}
            style={{
              ...Platform.select({ web: { outlineColor: "transparent" } })
            }}
            textColor={colors.textBody}
            tintColor={colors.textPlaceHolder}
            baseColor={colors.textPlaceHolder}
          />

          {Platform.OS === "web" ? (
            <View
              style={{
                justifyContent: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                marginVertical: spaceSize.margin.medium
              }}
            >
              <Text
                style={{
                  fontSize: fontSize.large,
                  color: colors.textBody,
                  paddingHorizontal: Layout.minerScale(16)
                }}
              >
                {"Thời điểm bắt đầu"}
              </Text>
              <WebPicker
                currentValue={this.state.timeframe.beginTime}
                options={enumHours("24h", 15)}
                style={{ width: Layout.minerScale(200) }}
                onChange={value => {
                  this.confirmDatePicked(
                    new Date("2019 01 01 " + value),
                    "BeginTime"
                  );
                }}
              />
            </View>
          ) : (
            <View style={{ justifyContent: "center" }}>
              <TextField
                fontSize={Layout.minerScale(20)}
                containerStyle={{ paddingHorizontal: Layout.minerScale(16) }}
                labelFontSize={Layout.minerScale(16)}
                label={"Thời điểm bắt đầu"}
                value={this.state.timeframe.beginTime}
                editable={false}
                placeholder={"Bắt đầu lúc"}
              />
              <EvilIcons
                name={"calendar"}
                style={styles.beginTimeIcon}
                onPress={() => this.showDateTimePicker("BeginTime")}
              />
            </View>
          )}
          {Platform.OS === "web" ? (
            <View
              style={{
                justifyContent: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: spaceSize.margin.largest
              }}
            >
              <Text
                style={{
                  fontSize: fontSize.large,
                  color: colors.textBody,
                  paddingHorizontal: Layout.minerScale(16)
                }}
              >
                {"Thời điểm kết thúc"}
              </Text>
              <WebPicker
                currentValue={this.state.timeframe.endTime}
                options={enumHours("24h", 15)}
                style={{
                  width: Layout.minerScale(200)
                }}
                onChange={value => {
                  this.confirmDatePicked(
                    new Date("2019 01 01 " + value),
                    "EndTime"
                  );
                }}
              />
            </View>
          ) : (
            <View style={{ justifyContent: "center" }}>
              <TextField
                fontSize={Layout.minerScale(20)}
                containerStyle={{ paddingHorizontal: Layout.minerScale(16) }}
                labelFontSize={Layout.minerScale(16)}
                label={"Thời điểm kết thúc"}
                value={this.state.timeframe.endTime}
                editable={false}
                placeholder={"Kết thúc lúc"}
              />
              {Platform.OS === "web" ? (
                <WebPicker options={enumHours("12h", 15)} />
              ) : (
                <EvilIcons
                  name={"calendar"}
                  style={styles.endTimeIcon}
                  onPress={() => this.showDateTimePicker("EndTime")}
                />
              )}
            </View>
          )}

          {Platform.OS !== "web" ? (
            <DateTimePicker
              mode="time"
              isVisible={this.state.isDateTimePickerVisible}
              onConfirm={this.confirmDatePicked}
              onCancel={this.cancelDateTimePicker}
              locale="en_GB"
              timePickerModeAndroid={"clock"}
              date={Moment(new Date())
                .minutes(Math.ceil(Moment(new Date()).minutes() / 15) * 15)
                .toDate()}
              minuteInterval={15}
              titleIOS={"Chọn thời gian"}
            />
          ) : (
            <View
              style={{
                alignItems: "center",
                marginTop: spaceSize.margin.largest
              }}
            >
              <TouchableOpacity style={styles.button} onPress={this.onSave}>
                <Text style={styles.buttonText}>Lưu</Text>
              </TouchableOpacity>
            </View>
          )}
          <Toast
            ref="toast"
            fadeInDuration={500}
            fadeOutDuration={750}
            opacity={0.8}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

TimeframeDetailScreen.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  beginTimeIcon: {
    position: "absolute",
    right: 0,
    padding: Layout.minerScale(12),
    color: "rgb(220,220,220)",
    fontSize: Layout.minerScale(42)
  },
  endTimeIcon: {
    position: "absolute",
    right: 0,
    padding: Layout.minerScale(12),
    color: "rgb(220,220,220)",
    fontSize: Layout.minerScale(42)
  },
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest
  }
});
