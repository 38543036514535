import Login from "./Login/LoginContainer";
//
import HomeScreen from "./Home/HomeContainer";
//
import ScheduleScreen from "./Schedule/ScheduleContainer";
//setting
import SettingScreen from "./Setting/SettingContainer";

import AccountDetailScreen from "./Setting/Account/AccountDetailContainer";
import AccountScreen from "./Setting/Account/AccountContainer";
//
import TeacherDetailScreen from "./Setting/Teacher/TeacherDetailContainer";
import TeacherScreen from "./Setting/Teacher/TeacherContainer";
//
import CourseDetailScreen from "./Setting/Course/CourseDetailContainer";
import CourseScreen from "./Setting/Course/CourseContainer";
//
import GradeScreen from "./Setting/GradeContainer";
import SubjectScreen from "./Setting/SubjectContainer";
import TimeframeDetailScreen from "./Setting/TimeframeDetailContainer";
import TimeframeScreen from "./Setting/TimeframeContainer";
import BranchScreen from "./Setting/Branch/BranchContainer";

export const Auth = {
  Login
};

export const Home = {
  HomeScreen
};

export const Schedule = {
  ScheduleScreen
};

export const TeacherSchedule = {};
export const GradeSchedule = {};
export const TeacherInfo = {};
//setting
export const Teacher = {
  TeacherScreen,
  TeacherDetailScreen
};
export const Course = {
  CourseScreen,
  CourseDetailScreen
};
export const Account = {
  AccountScreen,
  AccountDetailScreen
};
export const Setting = {
  SettingScreen,
  BranchScreen,
  GradeScreen,
  SubjectScreen,
  TimeframeScreen,
  TimeframeDetailScreen,
  ...Teacher,
  ...Course,
  ...Account
};
