import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ReportCreators } from "../../Models/Reducers/ReportReducer";
import { Creators as WeekdayCreators } from "../../Models/Reducers/WeekdayReducer";
import { Creators as TimeframeReducer } from "../../Models/Reducers/TimeframeReducer";
import { Creators as TeacherReducer } from "../../Models/Reducers/TeacherReducer";
import { Creators as BranchCreaters } from "../../Models/Reducers/BranchRecucer";
import { Creators as AppReducer } from "../../Models/Reducers/AppReducer";
import HomeScreen from "./HomeScreen";
const mapStateToProps = (state, props) => {
  return {
    courseState: state.courseState,
    teacherState: state.teacherState,
    weekdayState: state.weekdayState,
    timeframeState: state.timeframeState,
    reportState: state.reportState,
    loginState: state.loginState,
    branchInfo: state.branchInfo,
    token:
      state.loginState && state.loginState.auth && state.loginState.auth.token,
    currentScreen: state.appInfo.screen,
    currentSetting: state.appInfo.optionSetting
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportCreators,
      ...WeekdayCreators,
      ...TimeframeReducer,
      ...TeacherReducer,
      ...BranchCreaters,
      ...AppReducer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
