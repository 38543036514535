import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  views: [],
  viewsSuccess: null,
  //
  downloadTeacherSchedule: ["sendData"],
  downloadTeacherScheduleSuccess: null,
  downloadTeacherScheduleFail: null,
  //
  reviewGradeSchedule: ["branch", "listGrade"],
  reviewGradeScheduleSuccess: null,
  reviewGradeScheduleFail: null,
  //
  downloadGradeSchedule: ["branch", "listGrade"],
  downloadGradeScheduleSuccess: null,
  downloadGradeScheduleFail: null,
  //
  downloadTeacherInfo: ["branch"],
  downloadTeacherInfoSuccess: null,
  downloadTeacherInfoFail: null,
  //
  sendEmail: ["sendData"],
  sendEmailSuccess: null,
  sendEmailFail: null,
  //
  getDailySchedule: ["sendData"],
  getDailyScheduleSuccess: null,
  getDailyScheduleFail: null,
  //
  resetType: null
});

export const INITIAL_STATE = {
  type: "",
  schedules: [],
  dailySchedule: [],
  message: "",
  reviewGradeSchedule: []
};
const views = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const viewsSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    schedules: action.schedules,
    message: action.message
  });
};
//** */

const downloadTeacherSchedule = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const downloadTeacherScheduleSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    urlDownload: action.response
  });
};

const downloadTeacherScheduleFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
//** */

const reviewGradeSchedule = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const reviewGradeScheduleSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    reviewGradeSchedule: action.response
  });
};

const reviewGradeScheduleFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
//** */

const downloadGradeSchedule = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const downloadGradeScheduleSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    urlDownload: action.response
  });
};

const downloadGradeScheduleFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

//** */

const downloadTeacherInfo = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const downloadTeacherInfoSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    urlDownload: action.response
  });
};

const downloadTeacherInfoFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

////** */
const sendEmail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const sendEmailSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const sendEmailFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

////** */
const getDailySchedule = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getDailyScheduleSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    dailySchedule: action.dailySchedule
  });
};

const getDailyScheduleFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

////** */

const resetType = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const HANDLERS = {
  [Types.VIEWS]: views,
  [Types.VIEWS_SUCCESS]: viewsSuccess,
  //
  [Types.DOWNLOAD_TEACHER_SCHEDULE]: downloadTeacherSchedule,
  [Types.DOWNLOAD_TEACHER_SCHEDULE_SUCCESS]: downloadTeacherScheduleSuccess,
  [Types.DOWNLOAD_TEACHER_SCHEDULE_FAIL]: downloadTeacherScheduleFail,
  //
  [Types.REVIEW_GRADE_SCHEDULE]: reviewGradeSchedule,
  [Types.REVIEW_GRADE_SCHEDULE_SUCCESS]: reviewGradeScheduleSuccess,
  [Types.REVIEW_GRADE_SCHEDULE_FAIL]: reviewGradeScheduleFail,
  //
  [Types.DOWNLOAD_GRADE_SCHEDULE]: downloadGradeSchedule,
  [Types.DOWNLOAD_GRADE_SCHEDULE_SUCCESS]: downloadGradeScheduleSuccess,
  [Types.DOWNLOAD_GRADE_SCHEDULE_FAIL]: downloadGradeScheduleFail,
  //
  [Types.DOWNLOAD_TEACHER_INFO]: downloadTeacherInfo,
  [Types.DOWNLOAD_TEACHER_INFO_SUCCESS]: downloadTeacherInfoSuccess,
  [Types.DOWNLOAD_TEACHER_INFO_FAIL]: downloadTeacherInfoFail,
  //
  [Types.SEND_EMAIL]: sendEmail,
  [Types.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [Types.SEND_EMAIL_FAIL]: sendEmailFail,
  //
  [Types.GET_DAILY_SCHEDULE]: getDailySchedule,
  [Types.GET_DAILY_SCHEDULE_SUCCESS]: getDailyScheduleSuccess,
  [Types.GET_DAILY_SCHEDULE_FAIL]: getDailyScheduleFail,
  //
  [Types.RESET_TYPE]: resetType
};

export const reportState = createReducer(INITIAL_STATE, HANDLERS);
