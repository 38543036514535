import React, { Component } from "react";
import { Text, StyleSheet, View, FlatList } from "react-native";
import { Layout, fontSize, colors, spaceSize } from "../../Themes";
import { formatCurrency } from "../../Utilities/Format";

export default class ReviewGradeSchedule extends Component {
  renderSeparator = () => {
    return (
      <View
        style={{
          height: spaceSize.boderWidth.small,
          width: "100%",
          backgroundColor: colors.textBody
        }}
      />
    );
  };
  render() {
    return (
      <View style={ss.flatlistStyle}>
        <View style={ss.headerStyle}>
          <View style={[ss.vieSubjectStyle, { borderLeftWidth: 0 }]}>
            <Text style={ss.txtTitleStyle}>{`Môn học`}</Text>
          </View>
          <View style={ss.vieSubjectStyle}>
            <Text style={ss.txtTitleStyle}>{`Lớp`}</Text>
          </View>
          <View style={ss.vieSubjectStyle}>
            <Text style={ss.txtTitleStyle}>{`Giáo viên`}</Text>
          </View>
          <View
            style={[ss.vieSubjectStyle, { width: Layout.widthScale("7%") }]}
          >
            <Text style={ss.txtTitleStyle}>{`Thứ`}</Text>
          </View>
          <View
            style={[ss.vieSubjectStyle, { width: Layout.widthScale("10%") }]}
          >
            <Text style={ss.txtTitleStyle}>{`Thời gian`}</Text>
          </View>
          <View
            style={[
              ss.vieSubjectStyle,
              { width: Layout.widthScale("15%"), borderRightWidth: 0 }
            ]}
          >
            <Text style={ss.txtTitleStyle}>{`Học phí`}</Text>
          </View>
        </View>

        <FlatList
          // style={ss.flatlistStyle}
          data={this.props.reviewGradeSchedule}
          keyExtractor={(item, index) => index.toString()}
          listKey={(item, index) => `A` + index.toString()}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={this.renderSeparator}
          renderItem={({ item, index }) => {
            let length = item.subjects.length - 1;
            return (
              <View>
                <View style={ss.vieGradeStyle}>
                  <Text style={ss.txtGradeStyle}>{item.gradeName}</Text>
                </View>
                <FlatList
                  style={{ height: "auto" }}
                  data={item.subjects}
                  listKey={(item, index) => `B` + index.toString()}
                  ItemSeparatorComponent={this.renderSeparator}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({ item, index }) => {
                    const itemSub = item;
                    const indexSub = index;
                    return (
                      <View
                        style={[
                          ss.subjectStyle,
                          indexSub === length && {
                            borderBottomWidth: 1,
                            borderColor: colors.textBody
                          }
                        ]}
                      >
                        <View
                          style={[ss.vieSubjectStyle, { borderLeftWidth: 0 }]}
                        >
                          <Text style={ss.txtSubjectStyle}>
                            {itemSub.subjectName}
                          </Text>
                        </View>

                        <FlatList
                          data={itemSub.courses}
                          listKey={(item, index) => `C` + index.toString()}
                          ItemSeparatorComponent={this.renderSeparator}
                          showsVerticalScrollIndicator={false}
                          showsHorizontalScrollIndicator={false}
                          renderItem={({ item, index }) => {
                            const itemCou = item;
                            const indexCou = index;

                            return (
                              <View style={ss.classStyle}>
                                {/* Lop */}
                                <View style={ss.vieSubjectStyle}>
                                  <Text style={ss.txtSubjectStyle} numberOfLines={1} lineBreakMode="tail">
                                    {itemCou.courseName}
                                  </Text>
                                </View>
                                {/* Giaovien */}
                                <View style={ss.vieSubjectStyle}>
                                  <Text style={ss.txtStyle} numberOfLines={1} lineBreakMode="tail">
                                    {itemCou.teacherName + " "}
                                  </Text>
                                </View>
                                {/* Thu */}
                                <View
                                  style={[
                                    ss.vieSubjectStyle,
                                    { width: Layout.widthScale("7%") }
                                  ]}
                                >
                                  <Text style={ss.txtStyle} numberOfLines={1} lineBreakMode="tail">
                                    {itemCou.weekday + " "}
                                  </Text>
                                </View>
                                {/* Thoi gian */}
                                <View
                                  style={[
                                    ss.vieSubjectStyle,
                                    { width: Layout.widthScale("10%") }
                                  ]}
                                >
                                  <Text style={ss.txtStyle} numberOfLines={1} lineBreakMode="tail">
                                    {`${itemCou.timeframeFrom} - ${itemCou.timeframeTo}`}
                                  </Text>
                                </View>
                                {/* Hoc phi */}
                                <View
                                  style={[
                                    ss.vieSubjectStyle,
                                    {
                                      width: Layout.widthScale("15%"),
                                      borderRightWidth: 0
                                    }
                                  ]}
                                >
                                  <Text style={ss.txtStyle} numberOfLines={1} lineBreakMode="tail">
                                    {`${formatCurrency(itemCou.fee, 0)} VNĐ/${
                                      itemCou.duration
                                    }`}
                                  </Text>
                                </View>
                              </View>
                            );
                          }}
                        />
                      </View>
                    );
                  }}
                />
              </View>
            );
          }}
        />
      </View>
    );
  }
}

const ss = StyleSheet.create({
  flatlistStyle: {
    width: Layout.widthScale("80%"),
    flex: 1,
    alignSelf: "center",
    overflow: "hidden",
    backgroundColor: colors.bgBody,
    borderRadius: spaceSize.borderRadius.small,
    marginBottom: spaceSize.margin.medium,
    borderWidth: 1,
    borderColor: colors.textBody
  },
  headerStyle: {
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(40),
    borderBottomWidth: spaceSize.boderWidth.small,
    borderBottomColor: colors.textBody
  },
  vieGradeStyle: {
    height: Layout.minerScale(40),
    alignItems: "center",
    justifyContent: "center",
    borderBottomWidth: spaceSize.boderWidth.small,
    borderTopWidth: spaceSize.boderWidth.small,
    borderColor: colors.textBody
  },
  vieSubjectStyle: {
    height: "100%",
    minHeight:Layout.minerScale(30),
    width: Layout.widthScale("16%"),
    alignItems: "center",
    justifyContent: "center",
    borderLeftWidth: spaceSize.boderWidth.small,
    borderColor: colors.textBody
  },

  subjectStyle: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  classStyle: {
    //
    flexDirection: "row",
    alignItems: "center"
  },
  //
  txtTitleStyle: {
    fontSize: fontSize.large,
    color: colors.textBody,
    fontWeight: "bold"
  },
  txtGradeStyle: {
    fontSize: fontSize.large,
    color: colors.textBody
    // fontWeight: "bold"
  },
  txtSubjectStyle: {
    fontSize: fontSize.large,
    color: colors.textBody
    // fontWeight: "bold"
  },
  txtStyle: {
    fontSize: fontSize.medium,
    color: colors.textBody
    // fontWeight: "bold"
  }
});
