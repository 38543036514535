import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ReportCreators } from "../../Models/Reducers/ReportReducer";
import { Creators as WeekdayCreators } from "../../Models/Reducers/WeekdayReducer";
import { Creators as TimeframeReducer } from "../../Models/Reducers/TimeframeReducer";
import { Creators as TeacherReducer } from "../../Models/Reducers/TeacherReducer";
import TeacherScheduleScreen from "./TeacherScheduleScreen";
const mapStateToProps = (state, props) => {
  return {
    courseState: state.courseState,
    teacherState: state.teacherState,
    weekdayState: state.weekdayState,
    timeframeState: state.timeframeState,
    reportState: state.reportState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportCreators,
      ...WeekdayCreators,
      ...TimeframeReducer,
      ...TeacherReducer
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherScheduleScreen);
