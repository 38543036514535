import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as LoginCreators } from "../../Models/Reducers/LoginReducer";
import { Creators as WeekdayCreators } from "../../Models/Reducers/WeekdayReducer";
import { Creators as SubjectCreators } from "../../Models/Reducers/SubjectReducer";
import { Creators as GradeCreators } from "../../Models/Reducers/GradeReducer";
import { Creators as TimeframeCreators } from "../../Models/Reducers/TimeframeReducer";
import { Creators as CourseCreators } from "../../Models/Reducers/CourseReducer";
import { Creators as TeacherCreators } from "../../Models/Reducers/TeacherReducer";
import { Creators as AppCreators } from "../../Models/Reducers/AppReducer";
import LoginScreen from "./LoginScreen";

const mapStateToProps = (state, props) => {
  return {
    loginState: state.loginState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LoginCreators,
      ...WeekdayCreators,
      ...SubjectCreators,
      ...GradeCreators,
      ...TimeframeCreators,
      ...TeacherCreators,
      ...CourseCreators,
      ...AppCreators
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
