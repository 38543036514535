import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BranchScreen from "./BranchScreen";
import { Creators as BranchCreaters } from "../../../Models/Reducers/BranchRecucer";

const mapStateToProps = (state, props) => {
  return {
    listBranch: state.branchInfo.branchs,
    branchType: state.branchInfo.type,
    branchMess: state.branchInfo.message
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...BranchCreaters }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BranchScreen);
