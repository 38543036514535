import React, { Component } from "react";
import { Text, StyleSheet, View } from "react-native";
import { spaceSize, colors, Layout } from "../../Themes";
import TimeframeContainer from "../../Screens/Setting/TimeframeContainer";
import TimeframeDetailContainer from "../../Screens/Setting/TimeframeDetailContainer";
import Fade from "../Fade";

const timeframeNew = {
  uuid: null,
  name: "",
  beginTime: "",
  endTime: ""
};

export default class TimeSchedule extends Component {
  state = {
    timeframe: null
  };
  selectTime = timeframe => {
    this.setState({ timeframe });
  };
  newTime = () => {
    this.setState({ timeframe: null });
  };
  render() {
    return (
      <Fade>
        <View style={ss.container}>
          <View
            style={{
              flex: 2,
              marginRight: spaceSize.margin.largest,
              height: "auto",
              maxHeight: "100%"
            }}
          >
            <View style={ss.vieList}>
              <TimeframeContainer
                selectTime={this.selectTime}
                newTime={this.newTime}
              />
            </View>
          </View>
          <View style={ss.vieDetail}>
            <TimeframeDetailContainer
              title={
                this.state.timeframe
                  ? "Cập nhật - " + this.state.timeframe.name
                  : "Tạo mới"
              }
              timeframe={
                this.state.timeframe ? this.state.timeframe : timeframeNew
              }
            />
          </View>
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },

  vieList: {
    flex: 2,
    height: "auto",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
