//review release
const port = "10000";
const server = {
  dev: "https://conn-backend.herokuapp.com",
  product: "http://45.63.76.33:" + port
};

export const BASE_URL = server.dev;
export const BASE_TOKEN = "";
export const DEVICE_URL = "192.168.4.1";
