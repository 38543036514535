import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/TeacherReducer";

function* getTeachers(action) {
  try {
    let result = yield call(() =>
      API.get("/app/teacher/views").then((response) => response.data)
    );
    if (result.status === 1) {
      let teachers = result.response.map((teacher, idx) => {
        return {
          uuid: teacher.uuid,
          name: teacher.name,
          subjects: teacher.subjects,
          sortIndex: teacher.sortIndex,
          image: teacher.image,
          gender: teacher.gender,
          workAddress: teacher.workAddress,
          degree: teacher.degree,
          email: teacher.email,
          phone: teacher.phone,
          customSubjects: teacher.customSubjects,
          teachingTimes: teacher.teachingTimes.map((teachingTime) => {
            return {
              weekday: teachingTime.weekday,
              timeframe: teachingTime.timeframe,
            };
          }),
        };
      });
      yield put({
        type: Types.GET_TEACHERS_SUCCESS,
        teachers: teachers.sort((a, b) => a.name.localeCompare(b.name)),
        message: "Success",
      });
    } else {
      yield put({
        type: Types.GET_TEACHERS_FAIL,
        message: result.message,
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_TEACHERS_FAIL,
      message: "Không thể kết nối đến máy chủ...",
    });
  }
}

function* insertTeacher(action) {
  try {
    const result = yield call(() =>
      API.put("/app/teacher", action.teacher).then((response) => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.INSERT_TEACHER_SUCCESS,
        message: "Success",
      });
    } else {
      yield put({
        type: Types.INSERT_TEACHER_FAIL,
        message: result.message,
      });
    }
  } catch (e) {
    yield put({
      type: Types.INSERT_TEACHER_FAIL,
      message: "Không thể kết nối đến máy chủ...",
    });
  }
}
function* deleteTeacher(action) {
  try {
    const result = yield call(() =>
      API.delete("/app/teacher", { uuid: action.uuid }).then(
        (response) => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.DELETE_TEACHER_SUCCESS,
        message: "Success",
      });
    } else {
      yield put({
        type: Types.DELETE_TEACHER_FAIL,
        message: result.message,
      });
    }
  } catch (e) {
    yield put({
      type: Types.DELETE_TEACHER_FAIL,
      message: "Không thể kết nối đến máy chủ...",
    });
  }
}
function* updateTeacher(action) {
  try {
    const result = yield call(() =>
      API.put("/app/teacher", action.teacher).then((response) => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_TEACHER_SUCCESS,
        message: "Success",
      });
    } else {
      yield put({
        type: Types.UPDATE_TEACHER_FAIL,
        message: result.message,
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_TEACHER_FAIL,
      message: "Không thể kết nối đến máy chủ...",
    });
  }
}
function* TeacherSaga() {
  yield takeLatest(Types.GET_TEACHERS, getTeachers);
  yield takeLatest(Types.INSERT_TEACHER, insertTeacher);
  yield takeLatest(Types.UPDATE_TEACHER, updateTeacher);
  yield takeLatest(Types.DELETE_TEACHER, deleteTeacher);
}

export default TeacherSaga;
