import { AntDesign, EvilIcons, MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Platform
} from "react-native";
import BackHeader from "../../../Components/BackHeader";
import { Layout, colors, spaceSize, fontSize } from "../../../Themes";
import { Types as TeacherTypes } from "../../../Models/Reducers/TeacherReducer";
export default class TeacherScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeacher: {},
      listTeacher: this.props.listTeacher,
      refreshing: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.teacherType !== "") {
      switch (nextProps.teacherType) {
        case TeacherTypes.INSERT_TEACHER_FAIL:
          alert(nextProps.teacherMess);
          break;
        case TeacherTypes.UPDATE_TEACHER_FAIL:
          alert(nextProps.teacherMess);
          break;
        case TeacherTypes.DELETE_TEACHER_FAIL:
          alert(nextProps.teacherMess);
          break;
        case TeacherTypes.INSERT_TEACHER_SUCCESS:
          this.props.getTeachers();
          this.setState({ selectTeacher: null });
          break;
        case TeacherTypes.UPDATE_TEACHER_SUCCESS:
          this.props.getTeachers();
          this.setState({ selectTeacher: null });
          break;
        case TeacherTypes.DELETE_TEACHER_SUCCESS:
          this.props.getTeachers();
          this.setState({ selectTeacher: null });
          break;
      }
      this.setState({
        listTeacher: [...nextProps.listTeacher]
      });

      if (Platform.OS === "web") this.props.newTeacher();
    }
  }

  componentDidMount() {
    if (this.state.listTeacher.length > 0) {
      this.props.selectTeacher(this.state.listTeacher[0]);
      this.setState({ selectTeacher: this.state.listTeacher[0] });
    }
    this.props.getTeachers();
  }

  routeTo = (screen, params) => {
    this.props.navigation.navigate({
      routeName: screen,
      params
    });
  };

  onInsert = () => {
    if (Platform.OS === "web") {
      this.setState({ selectTeacher: null });
      this.props.newTeacher();
    } else this.routeTo("TeacherDetailScreen", {});
  };

  onUpdate = teacher => {
    if (Platform.OS === "web") {
      this.setState({ selectTeacher: teacher });
      this.props.selectTeacher(teacher);
    } else
      this.routeTo("TeacherDetailScreen", {
        title: "Cập nhật giảng viên",
        teacher: { ...teacher }
      });
  };
  onDelete = teacher => {
    if (teacher.uuid) {
      this.props.deleteTeacher(teacher.uuid);
    }
  };
  render() {
    const { selectTeacher } = this.state;
     const listTeacher = !!this.props.searchValue
       ? this.state.listTeacher.filter(g =>
           g.name.toLowerCase().includes(this.props.searchValue.toLowerCase())
         )
       : this.state.listTeacher;
    return (
      <View style={[styles.container]}>
        {Platform.OS !== "web" && (
          <BackHeader
            goBack={() => this.props.navigation.goBack()}
            title={"Danh sách giảng viên"}
          />
        )}
        <KeyboardAvoidingView
          style={{ flex: 1, marginBottom: Layout.minerScale(12) }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <TouchableOpacity
            style={styles.insertAction}
            onPress={() => this.onInsert()}
          >
            <EvilIcons name="plus" style={styles.insertIcon} />
          </TouchableOpacity>
          <FlatList
            style={{ flex: 1 }}
            refreshing={this.state.refreshing}
            onRefresh={() => {
              this.props.getTeachers();
            }}
            data={listTeacher}
            keyExtractor={this._keyExtractor}
            extraData={this.state || this.props}
            renderItem={({ item, index }) => (
              <View key={index}>
                <TouchableOpacity
                  style={styles.lineContainer}
                  onPress={() => this.onUpdate(item)}
                >
                  <View
                    style={{
                      width: fontSize.largest,
                      alignItems: "center"
                    }}
                  >
                    {Platform.OS === "web" ? (
                      !!selectTeacher &&
                      selectTeacher.uuid === item.uuid && (
                        <MaterialIcons
                          name="bookmark-border"
                          style={styles.icon}
                        />
                      )
                    ) : (
                      <MaterialIcons
                        name="bookmark-border"
                        style={styles.icon}
                      />
                    )}
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.textInput}>{`${index + 1}/ ${item.name}`}</Text>
                  </View>
                  <TouchableOpacity onPress={() => this.onDelete(item)}>
                    <AntDesign
                      name="delete"
                      style={[
                        styles.icon,
                        {
                          paddingRight: Layout.minerScale(24),
                          color: colors.buttonDelete
                        }
                      ]}
                    />
                  </TouchableOpacity>
                </TouchableOpacity>
                <View style={styles.line}></View>
              </View>
            )}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

TeacherScreen.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed"
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  insertAction: {
    height: Layout.minerScale(48),
    borderWidth: Layout.minerScale(1),
    borderColor: "rgb(220,220,220)",
    margin: Layout.minerScale(12),
    borderRadius: Layout.minerScale(12),
    borderStyle: "dashed",
    alignItems: "center",
    justifyContent: "center"
  },
  insertIcon: {
    color: "rgb(220,220,220)",
    fontSize: Layout.minerScale(42)
  },
  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(50),
    marginVertical: spaceSize.padding.small
  },
  textInput: {
    fontSize: fontSize.largest,
    marginLeft: spaceSize.margin.medium,
    color: colors.textBody
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.largest
  },
  line: {
    width: "100%",
    backgroundColor: colors.line,
    height: 1
  }
});
