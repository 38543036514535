import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/LoginReducer";

function* login(action) {
  try {
    const result = yield call(() =>
      API.post("/login", {
        username: action.username,
        password: action.password
      }).then(res => res.data)
    );

    if (result.status === 1) {
      yield put({
        type: Types.LOGIN_SUCCESS,
        token: result.response.token,
        userInfo: {
          ...result.response.userInfo,
          userTypes: result.response.userInfo.userTypes || []
        },
        message: ""
      });
    } else {
      yield put({
        type: Types.LOGIN_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.LOGIN_FAIL,
      message: e.message
    });
  }
}
function* LoginSaga() {
  yield takeLatest(Types.LOGIN, login);
}

export default LoginSaga;
