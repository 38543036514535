import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    height: 38,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4
  },
  icon: {
    paddingHorizontal: 12,
    color: "#fff",
    opacity: 0.7,
    fontSize: 20
  },
  iconClear: {
    paddingHorizontal: 12,
    color: "red",
    fontSize: 25,
    alignSelf: "flex-end"
  },
  textInput: {
    fontSize: 14,
    textAlign: "center",
    opacity: 0.9
  },
  touchClear: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }
});
