// (999)-999-9999
// string.protocol.formatPhone = () => {
//   if (this !== "" && strPhone !== null && strPhone.length === 10) {
//     const prefix = strPhone.substr(0, 3);
//     const middle = strPhone.substr(3, 3);
//     const suffix = strPhone.substr(6, 4);
//     return `(${prefix}) ${middle}-${suffix}`;
//   } else return strPhone;
// };
export const formatAMPM = date => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const formatPhone = strPhone => {
  if (strPhone !== "" && strPhone !== null && strPhone.length === 10) {
    const prefix = strPhone.substr(0, 3);
    const middle = strPhone.substr(3, 3);
    const suffix = strPhone.substr(6, 4);
    return `(${prefix}) ${middle}-${suffix}`;
  } else return strPhone;
};

// c: n số lẻ
// d: dấu phần ngàn
// t: dấu thập phân
export const formatCurrency = (
  numberFormat = 0,
  sole = 2,
  phanngan,
  thapphan
) => {
  try {
    var n = numberFormat,
      c = isNaN((sole = Math.abs(sole))) ? 2 : sole,
      d = phanngan == undefined ? "," : phanngan,
      t = thapphan == undefined ? "." : thapphan,
      s = n < 0 ? "-" : "",
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  } catch (e) {
    return 0;
  }
};

export const round2dec = (numberFormat = 0, decimals = 2) => {
  var num = numberFormat;
  // return Math.round(num * 100) / 100
  return Number(Math.round(num + "e" + decimals) + "e-" + decimals);
};

export const removeVietNamMark = strRemove => {
  var str = strRemove;
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str;
};
