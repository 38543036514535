import { AntDesign, EvilIcons, MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import {
  Animated,
  KeyboardAvoidingView,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform
} from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import BackHeader from "../../Components/BackHeader";
import { Layout, fontSize, colors } from "../../Themes";
import { Types as TimeframeTypes } from "../../Models/Reducers/TimeframeReducer";

const DeleteLeftAction = ({ dragX, onPress }) => {
  const scale = dragX.interpolate({
    inputRange: [0, Layout.minerScale(100)],
    outputRange: [0, Layout.minerScale(1)],
    extrapolate: "clamp"
  });
  return (
    <Animated.View
      style={{ width: Layout.minerScale(100), transform: [{ scale }] }}
    >
      <TouchableOpacity
        onPress={onPress}
        style={{
          backgroundColor: "rgb(220,220,220)",
          justifyContent: "center",
          height: "100%",
          alignItems: "center"
        }}
      >
        <AntDesign
          name="delete"
          style={{ color: "white", fontSize: Layout.minerScale(24) }}
        />
      </TouchableOpacity>
    </Animated.View>
  );
};
export default class TimeframeScreen extends Component {
  constructor(props) {
    super(props);
    this.refSubjectName = {};
    this.state = {
      timeframe: {},
      timeframes: [...this.props.timeframeState.timeframes],
      refreshing: false
    };
  }

  componentDidMount() {
    if (this.state.timeframes.length > 0) {
      this.props.selectTime(this.state.timeframes[0]);
      this.setState({ timeframe: this.state.timeframes[0] });
    }
    this.props.getTimeframes();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.timeframeState.type !== "") {
      switch (nextProps.timeframeState.type) {
        case TimeframeTypes.INSERT_TIMEFRAME_SUCCESS:
          this.props.getTimeframes();
          this.setState({ timeframe: null });
          break;
        case TimeframeTypes.INSERT_TIMEFRAME_FAIL:
          alert(nextProps.timeframeState.message);
          break;
        case TimeframeTypes.UPDATE_TIMEFRAME_SUCCESS:
          this.props.getTimeframes();
          this.setState({ timeframe: null });
          break;
        case TimeframeTypes.UPDATE_TIMEFRAME_FAIL:
          alert(nextProps.timeframeState.message);
          break;
        case TimeframeTypes.DELETE_TIMEFRAME_SUCCESS:
          this.props.getTimeframes();
          this.setState({ timeframe: null });
          break;
        case TimeframeTypes.DELETE_TIMEFRAME_FAIL:
          alert(nextProps.timeframeState.message);
          break;
      }
      this.setState({
        timeframes: [...nextProps.timeframeState.timeframes]
      });
      if (Platform.OS == "web") this.props.newTime();
    }
  }

  onUpdate = timeframe => {
    if (Platform.OS === "web") {
      this.setState({ timeframe });
      this.props.selectTime(timeframe);
    } else
      this.routeTo("TimeframeDetailScreen", {
        title: "Cập nhật - " + timeframe.name,
        timeframe
      });
  };

  onInsert = () => {
    if (Platform.OS === "web") {
      this.setState({ timeframe: null });
      this.props.newTime();
    } else this.routeTo("TimeframeDetailScreen", {});
  };

  onDelete = timeframe => {
    if (timeframe.uuid) {
      this.props.deleteTimeframe(timeframe.uuid);
    }
  };
  routeTo = (screen, params) => {
    this.props.navigation.navigate({
      routeName: screen,
      params
    });
  };

  render() {
    const { timeframe } = this.state;
    return (
      <View style={[styles.container]}>
        {Platform.OS !== "web" && (
          <BackHeader
            title={"Thời gian"}
            goBack={() => this.routeTo("SettingScreen")}
          />
        )}
        <KeyboardAvoidingView
          style={{ flex: 1, marginBottom: Layout.minerScale(12) }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <TouchableOpacity
            style={styles.insertAction}
            onPress={() => this.onInsert()}
          >
            <EvilIcons name="plus" style={styles.insertIcon} />
          </TouchableOpacity>
          <FlatList
            style={{ flex: 1 }}
            refreshing={this.state.refreshing}
            onRefresh={() => this.props.getTimeframes()}
            data={this.state.timeframes}
            keyExtractor={this._keyExtractor}
            extraData={this.state || this.props}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) => (
              <View key={index}>
                <TouchableOpacity
                  style={styles.lineContainer}
                  onPress={() => this.onUpdate(item)}
                >
                  <View
                    style={{
                      width: fontSize.largest,
                      alignItems: "center"
                    }}
                  >
                    {Platform.OS === "web" ? (
                      !!timeframe &&
                      timeframe.uuid === item.uuid && (
                        <MaterialIcons
                          name="bookmark-border"
                          style={styles.icon}
                        />
                      )
                    ) : (
                      <MaterialIcons
                        name="bookmark-border"
                        style={styles.icon}
                      />
                    )}
                  </View>
                  <View style={styles.lineTextContainer}>
                    <Text style={styles.lineTextName}>{item.name}</Text>
                    <Text style={styles.lineTextBeginEndTime}>
                      {item.beginTime + " - " + item.endTime}
                    </Text>
                  </View>
                  <AntDesign
                    name="delete"
                    style={[
                      styles.icon,
                      {
                        color: colors.buttonDelete
                      }
                    ]}
                    onPress={() => this.onDelete(item)}
                  />
                </TouchableOpacity>
                <View style={styles.line}></View>
              </View>
            )}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

TimeframeScreen.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  rightActions: {
    width: Layout.minerScale(100),
    flexDirection: "row",
    marginHorizontal: Layout.minerScale(12)
  },
  insertAction: {
    height: Layout.minerScale(48),
    borderWidth: Layout.minerScale(1),
    borderColor: "rgb(220,220,220)",
    margin: Layout.minerScale(12),
    borderRadius: Layout.minerScale(12),
    borderStyle: "dashed",
    alignItems: "center",
    justifyContent: "center"
  },
  insertIcon: {
    color: colors.textBody,
    fontSize: Layout.minerScale(42)
  },
  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(52)
  },
  lineTextContainer: {
    flex: 1,
    flexDirection: "column"
  },
  lineTextName: {
    flex: 1,
    fontSize: fontSize.largest,
    paddingLeft: Layout.minerScale(8),
    color: colors.textBody
  },
  lineTextBeginEndTime: {
    flex: 1,
    fontSize: fontSize.medium,
    paddingLeft: Layout.minerScale(8),
    color: colors.textPlaceHolder
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.largest
  },
  subjectName: {
    fontSize: Layout.minerScale(24),
    textAlign: "center",
    paddingLeft: Layout.minerScale(8)
  },
  line: {
    width: "100%",
    backgroundColor: colors.line,
    height: 1
  }
});
