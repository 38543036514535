import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CreatorsAccount } from "../../../Models/Reducers/AccountReducer";
import AccountScreen from "./AccountScreen";

const mapStateToProps = (state, props) => {
  return {
    accountType: state.accountInfo.type,
    accountMess: state.accountInfo.message,
    listAccount: (state.accountInfo && state.accountInfo.listAccount) || []
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreatorsAccount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountScreen);
