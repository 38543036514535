import React, { PureComponent } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import Icon from "../../Icon";
import { isHaveValue } from "../../../Utilities/ValueHandling"; //review
import { string, func } from "prop-types";

export default class Email extends PureComponent {
  static propTypes = {
    icon: string,
    value: string,
    placeholder: string,
    onReturnEmail: func,
    inputRef: func
  };
  static defaultProps = {
    icon: "",
    value: "",
    placeholder: "",
    onReturnEmail: () => {},
    inputRef: () => {}
  };
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      valid: null
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      // && nextProps.email
      this.onChangeText(nextProps.value);
    }
  }
  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  onChangeText = text => {
    let lValid = null;
    if (text === null || text === "") lValid = null;
    else if (!this.validateEmail(text)) {
      lValid = false;
    } else {
      lValid = true;
    }
    if (!isHaveValue(text)) lValid = null;

    this.props.onReturnEmail(text, lValid);
    this.setState({ value: text, valid: lValid });
  };

  render() {
    return (
      <View style={[ss.container, this.props.style]}>
        {this.props.icon && (
          <Icon
            name={this.props.icon}
            size={this.props.styleIcon && this.props.styleIcon.fontSize}
            color={
              !!this.props.validIconColor
                ? !!this.props.value
                  ? this.props.validIconColor
                  : this.props.styleIcon.color
                : this.props.styleIcon.color
            }
          />
        )}
        <TextInput
          ref={input => this.props.inputRef(input)}
          onChangeText={text => this.onChangeText(text)}
          style={[ss.textInput, this.props.styleText]}
          value={this.props.value}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
          onSubmitEditing={this.props.onSubmitEditing}
        />
        {!!this.props.value && (
          <Icon
            name={this.state.valid ? "check" : "close"}
            size={this.props.styleIcon && this.props.styleIcon.fontSize * 0.8}
            color={this.state.valid ? "green" : "red"}
          />
        )}
      </View>
    );
  }
}
const ss = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textInput: {
    marginLeft: 10,
    flex: 1,
    textAlign: "left"
  }
});
