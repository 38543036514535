import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getBranchs: [],
  getBranchsSuccess: null,
  getBranchsFail: null,
  //
  updateBranch: ["branch"],
  updateBranchSuccess: null,
  updateBranchFail: null,

  updateCompany: ["company"],
  updateCompanySuccess: null,
  updateCompanyFail: null,

  resetBranchType: null
});

export const INITIAL_STATE = {
  type: "",
  branchs: [],
  company: {},
  message: ""
};

const getBranchs = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getBranchsSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    branchs: action.branchs,
    company: action.company,
    message: action.message
  });
};

const getBranchsFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const updateBranch = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const updateBranchSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateBranchFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateCompany = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const updateCompanySuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateCompanyFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const resetBranchType = (state, action) => {
  return Object.assign({}, state, {
    type: ""
  });
};

const HANDLERS = {
  [Types.GET_BRANCHS]: getBranchs,
  [Types.GET_BRANCHS_SUCCESS]: getBranchsSuccess,
  [Types.GET_BRANCHS_FAIL]: getBranchsFail,
  //
  [Types.UPDATE_BRANCH]: updateBranch,
  [Types.UPDATE_BRANCH_SUCCESS]: updateBranchSuccess,
  [Types.UPDATE_BRANCH_FAIL]: updateBranchFail,

  [Types.UPDATE_COMPANY]: updateCompany,
  [Types.UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
  [Types.UPDATE_COMPANY_FAIL]: updateCompanyFail,

  [Types.RESET_BRANCH_TYPE]: resetBranchType
};

export const branchInfo = createReducer(INITIAL_STATE, HANDLERS);
