import React, { Component } from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions
} from "react-native";
import { Linking } from "react-native-web";
import { IconTextMate } from "../../Components/CustomButton";
import Fade from "../../Components/Fade";
import SearchInput from "../../MicroComponents/Input/SearchInput/SearchInput";
import { Types as ReportTypes } from "../../Models/Reducers/ReportReducer";
import { colors, fontSize, Layout, spaceSize } from "../../Themes";
import { removeVietNamMark } from "../../Utilities/Format";
import { BASE_URL } from "../../Models/URL";

export default class TeacherSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      allowDownload: false,

      url: `${BASE_URL}/report/teacher_schedules.xlsx`,
      //
      grid: this.props.weekdayState.weekdays
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(weekday => {
          return {
            title: weekday.name,
            cells: this.props.timeframeState.timeframes
              .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
              .map(timeframe => {
                return {
                  weekday: weekday.uuid,
                  timeframe: timeframe.uuid
                };
              })
          };
        })
    };
  }
  componentDidMount() {
    this.props.views();
    this.props.getTeachers();
    this.props.getWeekdays();
    this.props.getTimeframes();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.reportState.type !== "") {
      switch (nextProps.reportState.type) {
        case ReportTypes.DOWNLOAD_TEACHER_SCHEDULE_SUCCESS:
          if (this.state.disableButton) {
            this.setState({
              disableButton: !this.state.disableButton,
              allowDownload: true
            });
            //** gán lại url dowload nếu có */
            if (nextProps.reportState.urlDownload)
              this.setState({
                url: `${BASE_URL}${nextProps.reportState.urlDownload}`
              });
            setTimeout(() => {
              this.refs.downloadSchedule.props.onPress();
            }, 500);
          }
          this.props.resetType();
          break;
        case ReportTypes.DOWNLOAD_TEACHER_SCHEDULE_FAIL:
          alert(nextProps.reportState.message);
          this.setState({
            disableButton: !this.state.disableButton
          });
          this.props.resetType();
          break;
        //** */
        case ReportTypes.SEND_EMAIL_SUCCESS:
          if (this.state.disableButton) {
            this.setState({
              disableButton: !this.state.disableButton,
              allowDownload: true
            });
          }
          alert("Gửi mail thành công");
          this.props.resetType();
          break;
        case ReportTypes.SEND_EMAIL_FAIL:
          alert(nextProps.reportState.message);
          this.setState({
            disableButton: !this.state.disableButton
          });
          this.props.resetType();
          break;
      }
    }
    //
    this.setState({
      grid: nextProps.weekdayState.weekdays
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(weekday => {
          return {
            title: weekday.name,
            cells: nextProps.timeframeState.timeframes
              .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
              .map(timeframe => {
                return {
                  weekday: weekday.uuid,
                  timeframe: timeframe.uuid
                };
              })
          };
        })
    });
  }

  dowload = teacher => {
    this.setState({
      disableButton: !this.state.disableButton
    });
    this.props.downloadTeacherSchedule(teacher);
  };

  // sendEmail = teacher => {
  //   this.setState({
  //     disableButton: !this.state.disableButton
  //   });
  //   this.props.sendEmail(teacher);
  // };

  sendEmail = teacher => {
    this.setState({
      disableButton: !this.state.disableButton
    });
    let r = confirm("Bạn có chắc chắn muốn gửi email cho giảng viên?");
    if (r == true) {
      this.props.sendEmail(teacher);
    } else {
      return;
    }
  };

  getColumnTimeWidth = () => {
    const { width, height } = Dimensions.get("window");
    let ratio = width / height;
    return ratio < 1
      ? Layout.minerScale(90)
      : ratio < 2
      ? Layout.minerScale(100)
      : Layout.minerScale(120);
  };
  render() {
    let teacherSchedule = Object.assign([], this.props.reportState.schedules);
    if (!!this.state.searchVaue)
      teacherSchedule = teacherSchedule.filter(item =>
        removeVietNamMark(item.teacherName.toLowerCase()).includes(
          removeVietNamMark(this.state.searchVaue.toLowerCase())
        )
      );
    const width = this.getColumnTimeWidth();
    const ratio =
      Dimensions.get("window").width / Dimensions.get("window").height;
    return (
      <Fade>
        <View style={[ss.container]}>
          <View
            style={{
              height: Layout.minerScale(50),
              width: Layout.minerScale(500),
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "center",
              marginBottom: spaceSize.margin.medium
            }}
          >
            <SearchInput
              value={this.state.searchVaue}
              //
              onChangeText={searchVaue => this.setState({ searchVaue })}
              clearSearch={() => this.setState({ searchVaue: "" })}
              //
              maxLength={20}
              //
              styleWrapper={{
                height: "100%",
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",

                backgroundColor: colors.bgBody,
                borderRadius: spaceSize.borderRadius.large * 2
              }}
              styleText={{
                width: "80%",
                fontSize: fontSize.medium,
                color: colors.textBody
              }}
              styleIcon={{ fontSize: fontSize.largest }}
            />
            {/*  */}
            <IconTextMate
              nameIcon={"file-download"}
              onPress={() => {
                const listTeacher = teacherSchedule.map(f => f.teacher);
                this.dowload(listTeacher);
              }}
              //
              styleButton={{
                width: Layout.minerScale(30),
                marginLeft: spaceSize.margin.small,
                marginRight: spaceSize.margin.small
              }}
              styleIcon={{
                fontSize: Layout.minerScale(30),
                color: colors.iconDownloadAll
              }}
            />
            {/*  */}
            <IconTextMate
              nameIcon={"email"}
              onPress={() => {
                const listTeacher = teacherSchedule.map(f => f.teacher);
                this.sendEmail(listTeacher);
              }}
              //
              styleButton={{
                width: Layout.minerScale(30)
              }}
              styleIcon={{
                fontSize: Layout.minerScale(30),
                color: colors.iconEmailAll
              }}
            />
          </View>

          <FlatList
            columnWrapperStyle={ss.flatlistStyle}
            numColumns={2}
            data={teacherSchedule}
            keyExtractor={(item, index) => index.toString()}
            //
            initialNumToRender={1}
            //
            renderItem={({ item, index }) => {
              const schedule = item;

              const idx = index;
              return (
                <View
                  key={idx}
                  style={[
                    ss.vieTeacher,
                    ratio < 1.2 && { width: "100%", marginRight: 0 }
                  ]}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Text style={ss.teacherName}>{schedule.teacherName}</Text>
                    <IconTextMate
                      nameIcon={"file-download"}
                      onPress={() => this.dowload([schedule.teacher])}
                      //
                      styleButton={{
                        height: "100%",
                        width: Layout.minerScale(30),
                        marginRight: spaceSize.margin.small
                      }}
                      styleIcon={{
                        fontSize: Layout.minerScale(30),
                        color: colors.iconDownload
                      }}
                    />
                    <IconTextMate
                      nameIcon={"email"}
                      onPress={() => this.sendEmail([schedule.teacher])}
                      //
                      styleButton={{
                        height: "100%",
                        width: Layout.minerScale(30)
                      }}
                      styleIcon={{
                        fontSize: Layout.minerScale(30),
                        color: colors.iconEmail
                      }}
                    />
                  </View>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "flex-start"
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          borderWidth: Layout.minerScale(1),
                          borderColor: colors.textBody,
                          height: Layout.minerScale(32)
                        }}
                      />
                      {this.props.timeframeState.timeframes
                        .sort((a, b) => a.beginTime - b.beginTime)
                        .filter(timeframe =>
                          schedule.courses
                            .map(course => course.timeframe)
                            .includes(timeframe.uuid)
                        )
                        .map((timeframe, index) => (
                          <View key={index} style={ss.timeframeTitle}>
                            <Text style={ss.timeframeTitleText}>
                              {timeframe.beginTime + " - " + timeframe.endTime}
                            </Text>
                          </View>
                        ))}
                    </View>
                    <FlatList
                      horizontal={true}
                      data={this.state.grid}
                      extraData={this.state}
                      keyExtractor={(item, index) => index.toString()}
                      showsHorizontalScrollIndicator={false}
                      initialNumToRender={2}
                      renderItem={({ item, index }) => {
                        const column = item;
                        const idx1 = index;

                        return (
                          <View
                            key={idx1}
                            style={{
                              width: width
                            }}
                          >
                            <View
                              style={{
                                borderWidth: Layout.minerScale(1),
                                borderColor: colors.textBody,
                                height: Layout.minerScale(32),
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: fontSize.medium,
                                  color: colors.textBody
                                }}
                              >
                                {column.title}
                              </Text>
                            </View>
                            {column.cells
                              .filter(cell =>
                                schedule.courses
                                  .map(course => course.timeframe)
                                  .includes(cell.timeframe)
                              )
                              .map((cell, idx2) => {
                                let item = schedule.courses.find(
                                  f =>
                                    cell.weekday === f.weekday &&
                                    cell.timeframe === f.timeframe
                                );
                                return (
                                  <TouchableOpacity
                                    key={idx2}
                                    style={{
                                      borderWidth: Layout.minerScale(1),
                                      borderColor: colors.textBody,
                                      height: Layout.minerScale(32),
                                      backgroundColor: item
                                        ? "green"
                                        : "transparent",
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }}
                                    disabled={true}
                                  >
                                    <Text style={ss.courseName}>
                                      {item ? item.courseName : ""}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              })}
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
              );
            }}
          />
        </View>
        {/* HIDE to download */}
        <Text
          ref="downloadSchedule"
          style={{ opacity: 0 }}
          onPress={() => {
            this.setState({ allowDownload: false });
            //

            Linking.canOpenURL(this.state.url).then(supported => {
              if (supported) {
                Linking.openURL(this.state.url);
              } else {
                alert("Can't download file teacher_schedule.xlsx");
              }
            });
          }}
        />
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: spaceSize.margin.medium,
    marginRight: spaceSize.margin.medium
  },
  vieTeacher: {
    backgroundColor: colors.bgNav075,
    marginRight: spaceSize.margin.medium,
    marginBottom: spaceSize.margin.medium,
    padding: spaceSize.padding.small,
    borderRadius: spaceSize.borderRadius.small,
    overflow: "hidden"
  },
  teacherName: {
    flex: 1,
    fontSize: fontSize.largest,
    color: colors.textBody,
    paddingBottom: spaceSize.padding.small,
    fontWeight: "bold"
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: spaceSize.padding.small
  },
  timeframeTitleText: {
    fontSize: fontSize.medium,
    color: colors.textBody
  },
  courseName: {
    fontSize: fontSize.medium,
    color: colors.textBody
  },
  flatlistStyle: {
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "center"
  }
});
