// ENUM

export const enumDateFormat = "MM/DD/YY hh:mm A";
export const enumTimeFormat = "hh:mm A";

export const enumTimeAutoFetch = 5000;

export const enumAccount = {
  ruleOption: {
    admin: "Admin",
    manager: "Manager",
    employee: "Employee"
  },
  listType: [
    { value: "Admin", label: "Admin" },
    { value: "Manager", label: "Quản lý" },
    { value: "Employee", label: "Giáo vụ - Tư Vấn viên" }
  ]
};
export const enumMenuOption = {
  settingOption: {
    email: "Email",
    taikhoan: "Tài Khoản",
    chinhanh: "Chi nhánh",
    thoigian: "Thời gian",
    khoi: "Khối",
    mon: "Môn",
    lop: "Lớp",
    giangvien: "Giảng viên"
  },
  navigationOption: {
    trangchu: "HomeScreen",
    xeplich: "ScheduleScreen",
    tkbGiangvien: "TeacherSchedule",
    tkbKhoi: "GradeSchedule",
    thongtinGv: "TeacherInfo",
    catdat: "SettingScreen"
  }
};

//
export const enumDaysOfWeek = {
  full: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
};

export const enumDaysOfWeek2 = {
  full: [
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
    { day: "Sunday" }
  ]
};

export const enumMonthsOfYear = {
  full: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  short: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  numeric: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
};

export const enumTimezone = {
  timeZone: [
    "-11 Midway USA",
    "-10 Honolulu USA",
    "-9 Adak USA",
    "-8 Anchorage USA",
    "-7 Los Angeles USA",
    "-6 Costa Rica USA",
    "-5 Chicago USA",
    "-4 New Tork USA",
    "-3 Halifax Canada",
    "-2 Nuuk Greeland",
    "-1 Cape Verde Cabo Verde",
    "0 Azores Portugal",
    "+1 British Summer Time",
    "+2 Paris France",
    "+3 Moscow Russia",
    "+4 Dubai UAE",
    "+5 Tashkent Uzbekistan",
    "+6 Rangoon Mianma",
    "+7 Bangkok Thailand",
    "+8 Kuala Kumpur Malaysia",
    "+9 Tokyo Japan",
    "+10 Sydney Australia",
    "+11 Magadan Russia",
    "+12 Auckland New Zealand"
  ]
};

export const enumHours = (format, interval) => {
  var times = [];
  var tt = 0;
  var ap = ["AM", "PM"];
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60);
    var mm = tt % 60;
    //
    const PM12 = format === "24h" ? hh : hh % 12;
    const ampm = ap[Math.floor(hh / 12)];
    let time =
      (PM12 === 0 && ampm === "PM" ? "12" : "0" + PM12).slice(-2) +
      ":" +
      ("0" + mm).slice(-2) +
      (format === "24h" ? "" : ampm);
    //
    times[i] = time;
    //
    tt = tt + interval;
  }
  return times.map(m => {
    return {
      label: m,
      value: m
    };
  });
};

export const enumHolidays = {
  holidays: [
    "New Year's Day",
    "Martin Luther King Day",
    "President's Day",
    "Memorial Day",
    "Independence Day",
    "Labor Day",
    "Columbus Day",
    "Veterans Day",
    "Thanksgiving Day",
    "Christmas Day"
  ]
};

export const enumMaxLength = {
  name: 128,
  address: 255, // email
  code: 32,
  zipCode: 16,
  description: 2048,
  passWord: 255,
  index: 4,
  time: 4
};
