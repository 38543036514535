import React, { Component } from "react";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import {
  KeyboardAvoidingView,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Platform
} from "react-native";
import { Layout, colors, spaceSize, fontSize } from "../../../Themes";
import BackHeader from "../../../Components/BackHeader";
import { Types as TYPE_BRANCH } from "../../../Models/Reducers/BranchRecucer";

export default class BranchScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      selectedBranch: {},
      listBranch: this.props.listBranch
    };
  }

  componentDidMount() {
    if (this.props.listBranch.length > 0) {
      this.props.selectBranch(this.props.listBranch[0]);
      this.setState({ selectedBranch: this.props.listBranch[0] });
    }
    this.props.getBranchs();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.branchType !== "") {
      switch (nextProps.branchType) {
        case TYPE_BRANCH.GET_BRANCHS_SUCCESS:
          this.setState({ listBranch: nextProps.listBranch });
          // this.onSelectBranch(null);
          break;
        case TYPE_BRANCH.UPDATE_BRANCH_SUCCESS:
          this.props.getBranchs();
          break;

        default:
          break;
      }
      this.props.resetBranchType();
    }
  }
  onSelectBranch = branch => {
    if (Platform.OS === "web") {
      this.props.selectBranch(branch);
      this.setState({ selectedBranch: branch });
    }
  };

  render() {
    const { selectedBranch } = this.state;
    return (
      <View style={ss.container}>
        {Platform.OS !== "web" && (
          <BackHeader
            goBack={() => this.props.navigation.goBack()}
            title={"Chi nhánh"}
          />
        )}

        <ScrollView style={{ marginVertical: spaceSize.margin.medium }}>
          <FlatList
            style={{ flex: 1 }}
            refreshing={this.state.refreshing}
            onRefresh={() => this.props.getBranchs()}
            data={this.state.listBranch}
            keyExtractor={this._keyExtractor}
            extraData={this.state || this.props}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) => (
              <View key={index}>
                <View style={ss.lineContainer}>
                  <View
                    style={{
                      width: fontSize.largest,
                      alignItems: "center"
                    }}
                  >
                    {Platform.OS === "web" ? (
                      !!selectedBranch &&
                      selectedBranch.uuid === item.uuid && (
                        <MaterialIcons name="bookmark-border" style={ss.icon} />
                      )
                    ) : (
                      <MaterialIcons name="bookmark-border" style={ss.icon} />
                    )}
                  </View>

                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() => this.onSelectBranch(item)}
                  >
                    <Text style={ss.textInput}>{item.name}</Text>
                  </TouchableOpacity>
                </View>
                <View style={ss.line}></View>
              </View>
            )}
          />
        </ScrollView>
      </View>
    );
  }
}

BranchScreen.navigationOptions = {
  header: null
};

const ss = StyleSheet.create({
  container: {
    flex: 1
  },

  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(50),
    marginVertical: spaceSize.padding.small
  },
  line: {
    width: "100%",
    backgroundColor: colors.line,
    height: 1
  },
  textInput: {
    fontSize: fontSize.largest,
    color: colors.textBody,
    marginLeft: spaceSize.margin.medium
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.large
  }
});
