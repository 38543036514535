import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AccountDetailScreen from "./AccountDetailScreen";
import { Creators as CreatorsAccount } from "../../../Models/Reducers/AccountReducer";

const mapStateToProps = (state, props) => {
  return {
    accountType: state.accountInfo.type,
    accountMess: state.accountInfo.message,
    listAccount: (state.accountInfo && state.accountInfo.listAccount) || [],

    listBranch: state.branchInfo.branchs
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreatorsAccount
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailScreen);
