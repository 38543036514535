import React from "react";
import { Dimensions, View } from "react-native";
import { createAppContainer } from "react-navigation";
import { createStackNavigator } from "react-navigation-stack";

import { Auth, Home } from "../Screens";
// flow stack
const AuthStack = createStackNavigator(
  {
    ...Auth
  },
  { headerMode: "none" }
);
// flow stack
const MainStack = createStackNavigator(
  {
    ...Home
  },
  { initialRouteName: "HomeScreen", headerMode: "none" }
);

const AppNavigation = createStackNavigator(
  {
    AuthStack: AuthStack,
    MainStack: MainStack
  },
  { initialRouteName: "AuthStack", headerMode: "none" }
);

const AppContainer = createAppContainer(AppNavigation);

export default AppContainer;
