import persion from "./res/persion.svg";
import key from "./res/key.svg";
import eyeOff from "./res/eyeOff.svg";
import eye from "./res/eye.svg";
import birthdayCake from "./res/birthdayCake.svg";
import check from "./res/check.svg";
import close from "./res/close.svg";
import email from "./res/email.svg";
import gender from "./res/gender.svg";
import note from "./res/note.svg";
import arrowBack from "./res/arrowBack.svg";
import arrowBottom from "./res/arrowBottom.svg";
import arrowTop from "./res/arrowTop.svg";
import camera from "./res/camera.svg";
import phone from "./res/phone.svg";
import gift from "./res/gift.svg";
import heart from "./res/heart.svg";
import heartFill from "./res/heartFill.svg";
import location from "./res/location.svg";
import logout from "./res/logout.svg";
import pencil from "./res/pencil.svg";
import scan from "./res/scan.svg";
import search from "./res/search.svg";
import store from "./res/store.svg";
import time from "./res/time.svg";
import timeInterval from "./res/timeInterval.svg";
import world from "./res/world.svg";
import calendar from "./res/calendar.svg";
import percent from "./res/percent.svg";
import searchClose from "./res/searchClose.svg";
import cart from "./res/cart.svg";
import cartArrow from "./res/cartArrow.svg";

export const data = {
  gift: {
    type: "svg",
    source: gift
  },
  heart: {
    type: "svg",
    source: heart
  },
  heartFill: {
    type: "svg",
    source: heartFill
  },
  location: {
    type: "svg",
    source: location
  },
  logout: {
    type: "svg",
    source: logout
  },
  pencil: {
    type: "svg",
    source: pencil
  },
  scan: {
    type: "svg",
    source: scan
  },
  search: {
    type: "svg",
    source: search
  },
  searchClose: {
    type: "svg",
    source: searchClose
  },
  store: {
    type: "svg",
    source: store
  },
  time: {
    type: "svg",
    source: time
  },
  timeInterval: {
    type: "svg",
    source: timeInterval
  },
  timeInterval: {
    type: "svg",
    source: timeInterval
  },
  world: {
    type: "svg",
    source: world
  },

  persion: {
    type: "svg",
    source: persion
  },
  key: {
    type: "svg",
    source: key
  },
  eye: {
    type: "svg",
    source: eye
  },
  eyeOff: {
    type: "svg",
    source: eyeOff
  },
  birthdayCake: {
    type: "svg",
    source: birthdayCake
  },
  check: {
    type: "svg",
    source: check
  },
  close: {
    type: "svg",
    source: close
  },
  email: {
    type: "svg",
    source: email
  },
  gender: {
    type: "svg",
    source: gender
  },
  note: {
    type: "svg",
    source: note
  },
  arrowBack: {
    type: "svg",
    source: arrowBack
  },
  arrowBottom: {
    type: "svg",
    source: arrowBottom
  },
  arrowTop: {
    type: "svg",
    source: arrowTop
  },
  camera: {
    type: "svg",
    source: camera
  },
  calendar: {
    type: "svg",
    source: calendar
  },
  phone: {
    type: "svg",
    source: phone
  },
  percent: {
    type: "svg",
    source: percent
  },
  cart: {
    type: "svg",
    source: cart
  },
  cartArrow: {
    type: "svg",
    source: cartArrow
  },

  circleLine: {
    type: "vector-icon",
    source: "circle-outline"
  },
  circleLineDot: {
    type: "vector-icon",
    source: "circle-slice-8"
  },

  facebook: {
    type: "vector-icon",
    source: "facebook"
  },
  google: {
    type: "vector-icon",
    source: "google"
  },
  checkbox_marked: {
    type: "vector-icon",
    source: "checkbox-marked-outline"
  },
  checkbox_blank: {
    type: "vector-icon",
    source: "checkbox-blank-outline"
  },
  plus: {
    type: "vector-icon",
    source: "plus"
  },
  minus: {
    type: "vector-icon",
    source: "minus"
  }
};
