import { bool, string, func } from "prop-types";
import React, { PureComponent } from "react";
import { TextInput, View, StyleSheet } from "react-native";
import Icon from "../Icon";
import { ButtonIcon } from "../Button";

export default class InputPassword extends PureComponent {
  static propTypes = {
    icon: string,
    value: string,
    placeholder: string,
    onChangeText: func,
    inputRef: func,
    secureTextEntry: bool
  };

  static defaultProps = {
    icon: "",
    value: "",
    placeholder: "",
    onChangeText: () => {},
    inputRef: () => {},
    secureTextEntry: true
  };
  constructor(props) {
    super(props);
    this.state = {
      secureTextEntry: this.props.secureTextEntry
    };
  }

  render() {
    return (
      <View style={[ss.container, this.props.style]}>
        {this.props.icon === "" ? (
          <Icon
            name="key"
            size={this.props.styleIcon && this.props.styleIcon.fontSize}
            color={
              !!this.props.validIconColor
                ? !!this.props.value
                  ? this.props.validIconColor
                  : this.props.styleIcon.color
                : this.props.styleIcon.color
            }
          />
        ) : (
          <Icon
            name={this.props.icon}
            size={this.props.styleIcon && this.props.styleIcon.fontSize}
            color={this.props.styleIcon && this.props.styleIcon.color}
          />
        )}
        <TextInput
          ref={input => this.props.inputRef(input)}
          onChangeText={this.props.onChangeText}
          style={[ss.textInput, this.props.styleText]}
          value={this.props.value}
          placeholder={this.props.placeholder}
          secureTextEntry={this.state.secureTextEntry}
          maxLength={this.props.maxLength}
          onSubmitEditing={this.props.onSubmitEditing}
          returnKeyType={this.props.returnKeyType}
        />

        {this.state.secureTextEntry ? (
          <ButtonIcon
            icon="eyeOff"
            styleIcon={this.props.styleIcon}
            onPress={() => this.setState({ secureTextEntry: false })}
          />
        ) : (
          <ButtonIcon
            icon="eye"
            styleIcon={this.props.styleIcon}
            onPress={() => this.setState({ secureTextEntry: true })}
          />
        )}
      </View>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textInput: {
    marginLeft: 10,
    flex: 1,
    textAlign: "left"
  }
});
