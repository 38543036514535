import React, { Component } from "react";
import { Animated } from "react-native";

export default class Fade extends Component {
  state = {
    opacity: new Animated.Value(0)
  };

  componentDidMount() {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 500
    }).start();
  }
  render() {
    return (
      <Animated.View style={{ flex: 1, opacity: this.state.opacity }}>
        {this.props.children}
      </Animated.View>
    );
  }
}
