import { all } from "redux-saga/effects";

//token: "",
import AccountSaga from "./AccountSaga";
import CourseSaga from "./CourseSaga";
import GradeSaga from "./GradeSaga";
import LoginSaga from "./LoginSaga";
import SubjectSaga from "./SubjectSaga";
import TeacherSaga from "./TeacherSaga";
import TimeframeSaga from "./TimeframeSaga";
import WeekdaySaga from "./WeekdaySaga";
import ReportSaga from "./ReportSaga";
import BranchSaga from "./BranchSaga";

export default function* RootSagas() {
  yield all([
    AccountSaga(),
    LoginSaga(),
    SubjectSaga(),
    GradeSaga(),
    TimeframeSaga(),
    TeacherSaga(),
    WeekdaySaga(),
    CourseSaga(),
    ReportSaga(),
    BranchSaga()
  ]);
}
