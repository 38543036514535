import React from "react";
import { TouchableOpacity, View, Text, ActivityIndicator } from "react-native";
import { ss } from "./CustomButtonStyles";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import MateCom from "react-native-vector-icons/MaterialCommunityIcons";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import AntDesign from "react-native-vector-icons/AntDesign";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";

export const PrimaryButton = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={props.buttonStyle}
    disabled={props.disabled}
  >
    <View style={[ss.fillBg, props.styleButton, props.isActive && ss.activeBg]}>
      {props.isLoading ? (
        <ActivityIndicator color={"#eee"} />
      ) : (
        <Text style={[ss.textName, props.textStyle]}>{props.name}</Text>
      )}
    </View>
  </TouchableOpacity>
);

export const PlainButton = props => (
  <TouchableOpacity onPress={props.onPress}>
    <View style={[ss.defaultBg, ss.plainBg]}>
      <Text style={ss.textName}>{props.name}</Text>
    </View>
  </TouchableOpacity>
);

export const IconButton = props => (
  <TouchableOpacity
    onPress={props.onPress}
    disabled={props.disabled}
    style={props.styleButton || ss.buttonBg}
  >
    {!!props.typeIcon === false ||
      (props.typeIcon === "MaterialIcons" && (
        <MaterialIcons
          name={props.nameIcon}
          style={props.styleIcon || ss.icon}
        />
      ))}
    {props.typeIcon === "MaterialCommunityIcons" && (
      <MateCom name={props.nameIcon} style={props.styleIcon || ss.icon} />
    )}
    {props.typeIcon === "FontAwesome" && (
      <FontAwesome name={props.nameIcon} style={props.styleIcon || ss.icon} />
    )}
    {props.typeIcon === "AntDesign" && (
      <AntDesign name={props.nameIcon} style={props.styleIcon || ss.icon} />
    )}
    {props.typeIcon === "SimpleLineIcons" && (
      <SimpleLineIcons
        name={props.nameIcon}
        style={props.styleIcon || ss.icon}
      />
    )}
  </TouchableOpacity>
);

export const StatusButton = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={[ss.statusBg, props.styleButton, styles[props.status]]}
    disabled={props.isDisabled || false}
  >
    <Text
      style={[ss.statusText, props.textStyle, styles["txt" + props.status]]}
    >
      {props.status}
    </Text>
  </TouchableOpacity>
);

export const FillButton = props => (
  <TouchableOpacity
    disabled={props.disabled}
    onPress={props.onPress}
    style={[ss.fillBg, props.styleButton]}
  >
    {props.icon ? (
      <MaterialIcons
        name={props.nameIcon}
        style={[ss.fillIcon, props.styleIcon]}
      />
    ) : props.isLoading ? (
      <ActivityIndicator color={"#eee"} />
    ) : (
      <Text style={[ss.fillText, props.textStyle]}>{props.text}</Text>
    )}
  </TouchableOpacity>
);

export const IconTextButton = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={[ss.btnIconTextBg, props.styleButton]}
    disabled={props.disabled}
  >
    <View style={{ width: props.text ? "15%" : "100%" }}>
      <FontAwesome
        name={props.nameIcon}
        style={[ss.iconBtnIconText, props.styleIcon]}
      />
    </View>
    {props.text && (
      <View style={{ width: "80%" }}>
        <Text style={[ss.textBtnIconText, props.textStyle]}>{props.text}</Text>
      </View>
    )}
  </TouchableOpacity>
);

export const IconTextButton2 = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={[ss.btnIconTextBg, props.styleButton]}
    disabled={props.disabled}
  >
    <View style={{ width: props.text ? "15%" : "100%" }}>
      <AntDesign
        name={props.nameIcon}
        style={[ss.iconBtnIconText, props.styleIcon]}
      />
    </View>
    {props.text && (
      <View style={{ width: "80%" }}>
        <Text style={[ss.textBtnIconText, props.textStyle]}>{props.text}</Text>
      </View>
    )}
  </TouchableOpacity>
);

export const IconTextSimpleLineIcons = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={[ss.btnIconTextBg, props.styleButton]}
    disabled={props.disabled}
  >
    <View style={{ width: props.text ? "15%" : "100%" }}>
      <SimpleLineIcons
        name={props.nameIcon}
        style={[ss.iconBtnIconText, props.styleIcon]}
      />
    </View>
    {props.text && (
      <View style={{ width: "80%" }}>
        <Text style={[ss.textBtnIconText, props.textStyle]}>{props.text}</Text>
      </View>
    )}
  </TouchableOpacity>
);

export const IconTextMate = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={props.styleButton || ss.btnIconTextBg}
    disabled={props.disabled}
  >
    <View style={{ width: props.text ? "15%" : "100%" }}>
      <MaterialIcons
        name={props.nameIcon}
        style={[ss.iconBtnIconText, props.styleIcon]}
        // size={props.styleIcon && props.styleIcon.fontSize}
        // color={props.styleIcon && props.styleIcon.color}
      />
    </View>
    {props.text && (
      <View style={{ width: "80%" }}>
        <Text style={[ss.textBtnIconText, props.textStyle]}>{props.text}</Text>
      </View>
    )}
  </TouchableOpacity>
);
export const IconTextMateCom = props => (
  <TouchableOpacity
    onPress={props.onPress}
    style={props.styleButton || ss.btnIconTextBg}
    disabled={props.disabled}
  >
    <View style={{ width: props.text ? "15%" : "100%" }}>
      <MateCom
        name={props.nameIcon}
        // style={[ss.iconBtnIconText, props.styleIcon]}
        style={props.styleIcon || ss.iconBtnIconText}
      />
    </View>
    {props.text && (
      <View style={{ width: "80%" }}>
        <Text style={[ss.textBtnIconText, props.textStyle]}>{props.text}</Text>
      </View>
    )}
  </TouchableOpacity>
);
