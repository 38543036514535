import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/SubjectReducer";

function* getSubjects(action) {
  try {
    let result = yield call(() =>
      API.get("/app/subject/views").then(response => response.data)
    );
    if (result.status === 1) {
      let subjects = result.response.map((subject, idx) => {
        return {
          uuid: subject.uuid,
          name: subject.name,
          gradeUuid: subject.gradeUuid,
          sortIndex: subject.sortIndex
        };
      });
      yield put({
        type: Types.GET_SUBJECTS_SUCCESS,
        subjects: subjects.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        message: "Success"
      });
    } else {
      yield put({
        type: Types.GET_SUBJECTS_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_SUBJECTS_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* insertSubject(action) {
  try {
    const result = yield call(() =>
      API.put("/app/subject", action.subject).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.INSERT_SUBJECT_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.INSERT_SUBJECT_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.INSERT_SUBJECT_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* deleteSubject(action) {
  try {
    const result = yield call(() =>
      API.delete("/app/subject", { uuid: action.uuid }).then(
        response => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.DELETE_SUBJECT_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.DELETE_SUBJECT_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.DELETE_SUBJECT_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* updateSubject(action) {
  try {
    const result = yield call(() =>
      API.put("/app/subject", action.subject).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_SUBJECT_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_SUBJECT_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_SUBJECT_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* SubjectSaga() {
  yield takeLatest(Types.GET_SUBJECTS, getSubjects);
  yield takeLatest(Types.INSERT_SUBJECT, insertSubject);
  yield takeLatest(Types.UPDATE_SUBJECT, updateSubject);
  yield takeLatest(Types.DELETE_SUBJECT, deleteSubject);
}

export default SubjectSaga;
