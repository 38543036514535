import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
    getWeekdays: [],
    getWeekdaysSuccess: null,
    getWeekdaysFail: null,
});

export const INITIAL_STATE = {
    type: "",
    weekdays: [],
    message: ""
};

const getWeekdays = (state, action) => {
    return Object.assign({}, state, {
        type: action.type,
        message: ""
    });
};

const getWeekdaysSuccess = (state, action) => {
    return Object.assign({}, state, {
        type: action.type,
        weekdays: action.weekdays,
        message: action.message
    });
};

const getWeekdaysFail = (state, action) => {
    return Object.assign({}, state, {
        type: action.type,
        message: action.message
    });
};

const HANDLERS = {
    [Types.GET_WEEKDAYS]: getWeekdays,
    [Types.GET_WEEKDAYS_SUCCESS]: getWeekdaysSuccess,
    [Types.GET_WEEKDAYS_FAIL]: getWeekdaysFail
};

export const weekdayState = createReducer(INITIAL_STATE, HANDLERS);
