import { bool, string, func } from "prop-types";
import React, { PureComponent } from "react";
import { TextInput, View, StyleSheet } from "react-native";
import Icon from "../Icon";

export default class Input extends PureComponent {
  static propTypes = {
    value: string,
    placeholder: string,
    onChangeText: func,
    inputRef: func
  };

  static defaultProps = {
    value: "",
    placeholder: "",
    onChangeText: () => {},
    inputRef: () => {}
  };

  render() {
    return (
      <View style={[ss.container, this.props.style]}>
        {this.props.icon && (
          <Icon
            name={this.props.icon}
            size={this.props.styleIcon && this.props.styleIcon.fontSize}
            color={
              !!this.props.validIconColor
                ? !!this.props.value
                  ? this.props.validIconColor
                  : this.props.styleIcon.color
                : this.props.styleIcon.color
            }
          />
        )}
        <TextInput
          ref={input => this.props.inputRef(input)}
          editable={this.props.editable}
          onChangeText={this.props.onChangeText}
          style={[ss.textInput, this.props.styleText]}
          value={this.props.value}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
          onSubmitEditing={this.props.onSubmitEditing}
          returnKeyType={this.props.returnKeyType}
        />
      </View>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textInput: {
    marginLeft: 10,
    flex: 1,
    textAlign: "left"
  }
});
