import { AntDesign, EvilIcons, MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  FlatList
} from "react-native";
import BackHeader from "../../../Components/BackHeader";
import { Layout, fontSize, colors, spaceSize } from "../../../Themes";
import { Types as CourseTypes } from "../../../Models/Reducers/CourseReducer";
export default class CourseScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCourse: {},
      courses: [...this.props.courseState.courses],
      refreshing: false
    };
  }

  componentDidMount() {
    if (this.state.courses.length > 0) {
      this.onUpdate(this.state.courses[0]);
    }
    this.props.getCourses();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.courseState.type !== "") {
      switch (nextProps.courseState.type) {
        case CourseTypes.INSERT_COURSE_FAIL:
          alert(nextProps.courseState.message);
          break;
        case CourseTypes.UPDATE_COURSE_FAIL:
          alert(nextProps.courseState.message);
          break;
        case CourseTypes.DELETE_COURSE_FAIL:
          alert(nextProps.courseState.message);
          break;
        case CourseTypes.INSERT_COURSE_SUCCESS:
          this.props.getCourses();
          this.setState({ selectedCourse: null });
          break;
        case CourseTypes.UPDATE_COURSE_SUCCESS:
          this.props.getCourses();
          this.setState({ selectedCourse: null });
          break;
        case CourseTypes.DELETE_COURSE_SUCCESS:
          this.props.getCourses();
          this.setState({ selectedCourse: null });
          break;
      }
      this.setState({
        courses: [...nextProps.courseState.courses]
      });
      if (Platform.OS === "web") this.props.newCourse();
    }
  }

  routeTo = (screen, params) => {
    this.props.navigation.navigate({
      routeName: screen,
      params
    });
  };

  onInsert = () => {
    if (Platform.OS === "web") {
      this.props.newCourse();
      this.setState({ selectedCourse: null });
    } else this.routeTo("CourseDetailScreen", {});
  };

  onUpdate = course => {
    console.log("tandeptrai", course)
    if (Platform.OS === "web") {
      this.props.selectCourse(course);
      this.setState({ selectedCourse: course });
    } else
      this.routeTo("CourseDetailScreen", {
        title: "Cập nhật khóa học",
        course: { ...course }
      });
  };
  onDelete = course => {
    if (course.id.uuid) {
      this.props.deleteCourse(course.id.uuid);
    }
  };
  render() {
    const { selectedCourse } = this.state;
    const courses = !!this.props.searchValue
      ? this.state.courses.filter(g =>
          g.name.toLowerCase().includes(this.props.searchValue.toLowerCase())
        )
      : this.state.courses;
    return (
      <View style={[styles.container]}>
        {Platform.OS !== "web" && (
          <BackHeader
            title={"Danh sách khóa học"}
            goBack={() => this.routeTo("SettingScreen")}
          />
        )}
        <KeyboardAvoidingView
          style={{ flex: 1, marginBottom: Layout.minerScale(12) }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <TouchableOpacity
            style={styles.insertAction}
            onPress={() => this.onInsert()}
          >
            <EvilIcons name="plus" style={styles.insertIcon} />
          </TouchableOpacity>
          <FlatList
            style={{ flex: 1 }}
            refreshing={this.state.refreshing}
            onRefresh={() => {
              this.props.getCourses();
            }}
            data={courses}
            keyExtractor={this._keyExtractor}
            extraData={this.state || this.props}
            scrollEventThrottle={16}
            renderItem={({ item, index }) => (
              <View key={index}>
                <View style={styles.lineContainer}>
                  <View
                    style={{
                      width: fontSize.largest,
                      alignItems: "center"
                    }}
                  >
                    {Platform.OS === "web" ? (
                      !!selectedCourse &&
                      !!selectedCourse.id &&
                      selectedCourse.id.uuid === item.id.uuid && (
                        <MaterialIcons
                          name="bookmark-border"
                          style={styles.icon}
                        />
                      )
                    ) : (
                      <MaterialIcons
                        name="bookmark-border"
                        style={styles.icon}
                      />
                    )}
                  </View>
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() => this.onUpdate(item)}
                  >
                    <Text style={styles.textInput}>{`${index + 1}/ ${item.name}`}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.onDelete(item)}>
                    <AntDesign
                      name="delete"
                      style={[
                        styles.icon,
                        {
                          paddingRight: Layout.minerScale(24),
                          color: colors.buttonDelete
                        }
                      ]}
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles.line}></View>
              </View>
            )}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

CourseScreen.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed"
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  insertAction: {
    height: Layout.minerScale(48),
    borderWidth: Layout.minerScale(1),
    borderColor: "rgb(220,220,220)",
    margin: Layout.minerScale(12),
    borderRadius: Layout.minerScale(12),
    borderStyle: "dashed",
    alignItems: "center",
    justifyContent: "center"
  },
  insertIcon: {
    color: "rgb(220,220,220)",
    fontSize: Layout.minerScale(42)
  },
  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(50),
    marginVertical: spaceSize.padding.small
  },
  textInput: {
    fontSize: fontSize.largest,
    color: colors.textBody,
    marginLeft: spaceSize.margin.medium
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.largest
  },
  line: {
    width: "100%",
    backgroundColor: colors.line,
    height: 1
  }
});
