import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CourseDetailScreen from "./CourseDetailScreen";
import { Creators as CourseCreators } from "../../../Models/Reducers/CourseReducer";

const mapStateToProps = (state, props) => {
  return {
    courseState: state.courseState,
    teacherState: state.teacherState,
    gradeState: state.gradeState,
    subjectState: state.subjectState,
    weekdayState: state.weekdayState,
    timeframeState: state.timeframeState,
    branchInfo: state.branchInfo
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CourseCreators
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDetailScreen);
