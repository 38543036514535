import React, { Component } from "react";
import { Platform, StyleSheet, View, Dimensions } from "react-native";
import { spaceSize, colors, Layout, fontSize } from "../../Themes";
import GradeContainer from "../../Screens/Setting/GradeContainer";
import Fade from "../Fade";
import SearchInput from "../../MicroComponents/Input/SearchInput/SearchInput";

export default class Grade extends Component {
  state = {
    searchVaue: ""
  };
  render() {
    const { width, height } = Dimensions.get("window");

    const flex = width / height < 1 ? 8 : width / height < 1.5 ? 4 : 2;
    return (
      <Fade>
        <View style={ss.container}>
          <View style={{ flex: 1 }} />
          <View style={{ height: "auto", maxHeight: "100%", flex: flex }}>
            <SearchInput
              value={this.state.searchVaue}
              //
              onChangeText={searchVaue => this.setState({ searchVaue })}
              clearSearch={() => this.setState({ searchVaue: "" })}
              //
              maxLength={20}
              //
              styleWrapper={ss.search}
              styleText={[
                {
                  width: "80%",
                  fontSize: fontSize.medium,
                  color: colors.textBody
                },
                {
                  ...Platform.select({
                    web: { outlineColor: "transparent" }
                  })
                }
              ]}
              styleIcon={{ fontSize: fontSize.largest }}
            />
            <View style={[ss.vieList]}>
              <GradeContainer searchValue={this.state.searchVaue} />
            </View>
          </View>
          <View style={{ flex: 1 }} />
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  search: {
    height: Layout.minerScale(40),
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgBody,
    borderRadius: spaceSize.borderRadius.large * 2,
    marginBottom: spaceSize.margin.large
  },
  vieList: {
    flex: 2,
    height: "auto",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
