import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CreatorsSubject } from "../../Models/Reducers/SubjectReducer";
import { Creators as CreatorsGrade } from "../../Models/Reducers/GradeReducer";
import { Creators as CreatorsTimeframe } from "../../Models/Reducers/TimeframeReducer";
import { Creators as BranchCreaters } from "../../Models/Reducers/BranchRecucer";
import { Creators as AccountCreaters } from "../../Models/Reducers/AccountReducer";
import SettingScreen from "./SettingScreen";

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreatorsSubject,
      ...CreatorsGrade,
      ...CreatorsTimeframe,
      ...BranchCreaters,
      ...AccountCreaters
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SettingScreen);
