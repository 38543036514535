import React, { Component } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import CompanyContainer from "../../Screens/Setting/Account/CompanyContainer";
import { colors, Layout, spaceSize } from "../../Themes";
import Fade from "../Fade";

export default class Email extends Component {
  render() {
    const { width, height } = Dimensions.get("window");
    const flex = width / height > 1.5 ? 2 : 4;

    return (
      <Fade>
        <View style={ss.container}>
          <View style={{ flex: 1 }} />
          <View style={[ss.vieList, { flex: flex }]}>
            <CompanyContainer />
          </View>
          <View style={{ flex: 1 }} />
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  vieList: {
    flex: 2,
    height: "auto",
    maxHeight: "100%",
    marginRight: spaceSize.margin.largest,
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
