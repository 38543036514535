import React, { Component } from "react";
import { Text, StyleSheet, View } from "react-native";
import { spaceSize, colors, Layout } from "../../Themes";
import AccountContainer from "../../Screens/Setting/Account/AccountContainer";
import AccountDetailContainer from "../../Screens/Setting/Account/AccountDetailContainer";
import CompanyContainer from "../../Screens/Setting/Account/CompanyContainer";
import Fade from "../Fade";
import { enumAccount } from "../../Utilities/Enum";
const accountNew = {
  uuid: null,
  subsidiaryUuid: null,
  type: enumAccount.ruleOption.employee,
  username: "",
  password: "",
  name: "",
  phone: ""
};
export default class Account extends Component {
  state = {
    account: null
  };

  selectAccount = account => {
    this.setState({ account });
  };
  newAccount = () => {
    this.setState({ account: accountNew });
  };
  render() {
    return (
      <Fade>
        <View style={ss.container}>
          <View style={ss.vieList}>
            <AccountContainer
              selectAccount={this.selectAccount}
              newAccount={this.newAccount}
            />
          </View>

          <View style={ss.vieDetail}>
            <AccountDetailContainer
              account={this.state.account ? this.state.account : accountNew}
            />
          </View>
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  vieList: {
    flex: 2,
    height: "auto",
    maxHeight: "100%",
    marginRight: spaceSize.margin.largest,
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
