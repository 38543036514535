import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as TeacherReducer } from "../../Models/Reducers/TeacherReducer";
import { Creators as ReportReducer } from "../../Models/Reducers/ReportReducer";
import TeacherInfo from "./TeacherInfo";
const mapStateToProps = (state, props) => {
  return {
    teacherState: state.teacherState,
    reportState: state.reportState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TeacherReducer,
      ...ReportReducer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeacherInfo);
