import React, { Component } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import Toast from "react-native-easy-toast";
import { TextField } from "react-native-material-textfield";
import BackAndSaveHeader from "../../../Components/BackAndSaveHeader";
import { WebPicker } from "../../../MicroComponents/Picker";
import { Types as ACCOUNT_TYPE } from "../../../Models/Reducers/AccountReducer";
import { colors, fontSize, Layout, spaceSize } from "../../../Themes";
import { enumAccount } from "../../../Utilities/Enum";

export default class TeacherDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.refTextInputName = {};
    this.state = {
      account: this.props.navigation
        ? this.props.navigation.getParam("account", {
            uuid: null,
            name: ""
          })
        : this.props.account,
      showPass: true
    };
  }
  componentWillReceiveProps(nextProps) {
    if (Platform.OS === "web")
      if (nextProps.accountType === ACCOUNT_TYPE.REGISTER_SUCCESS)
        this.setState({ account: null });
      else
        this.setState({
          account: {
            ...nextProps.account,
            subsidiaryUuid: !!nextProps.account.subsidiaryUuid
              ? nextProps.account.subsidiaryUuid
              : this.props.listBranch[0].uuid
          }
        });
  }

  vaidate = () => {
    const { account } = this.state;
    if (!!!account.name) {
      alert("Vui lòng nhập tên người dùng");
      return false;
    }
    if (!!!account.username) {
      alert("Vui lòng nhập tên tài khoản");
      return false;
    }
    if (!!!account.password) {
      alert("Vui lòng nhập mật khẩu");
      return false;
    }
    return true;
  };
  onSave = () => {
    if (!this.vaidate()) return;
    this.props.register(this.state.account);
    if (Platform.OS !== "web") this.routeTo("TeacherScreen");
  };

  routeTo = screen => {
    this.props.navigation.navigate({ routeName: screen });
  };

  onChangeName = text => {
    this.setState({
      account: {
        ...this.state.account,
        name: text
      }
    });
  };
  onChangePhone = text => {
    this.setState({
      account: {
        ...this.state.account,
        phone: text
      }
    });
  };
  onChangeUsername = text => {
    this.setState({
      account: {
        ...this.state.account,
        username: text
      }
    });
  };
  onChangePassword = text => {
    this.setState({
      account: {
        ...this.state.account,
        password: text
      }
    });
  };
  onChangeType = text => {
    this.setState({
      account: {
        ...this.state.account,
        type: text
      }
    });
  };
  onChangeBranch = text => {
    this.setState({
      account: {
        ...this.state.account,
        subsidiaryUuid: text
      }
    });
  };
  showPass = () => {
    if (this.state.showPass) this.setState({ showPass: false });
    else this.setState({ showPass: true });
  };
  render() {
    const optionBranch = this.props.listBranch.map(branch => {
      return {
        value: branch.uuid,
        label: branch.name
      };
    });
    return (
      <View style={styles.container}>
        {Platform.OS !== "web" && (
          <BackAndSaveHeader
            title={this.state.title}
            goBack={() => this.routeTo("TeacherScreen")}
            onSave={() => this.onSave()}
          />
        )}
        <KeyboardAvoidingView
          enableOnAndroid={true}
          style={{
            flex: 1
          }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
            <TextField
              ref={ref => (this.refTextInputName = ref)}
              fontSize={fontSize.large}
              containerStyle={{
                flex: 1,
                paddingHorizontal: Layout.minerScale(16)
              }}
              labelFontSize={fontSize.medium}
              label={"Tên Người dùng"}
              style={{
                ...Platform.select({
                  web: { outlineColor: "transparent" }
                })
              }}
              textColor={colors.textBody}
              tintColor={colors.textPlaceHolder}
              baseColor={colors.textPlaceHolder}
              value={this.state.account.name || ""}
              onChangeText={text => this.onChangeName(text)}
            />
            <TextField
              ref={ref => (this.refTextInputName = ref)}
              fontSize={fontSize.large}
              containerStyle={{
                paddingHorizontal: Layout.minerScale(16)
              }}
              labelFontSize={fontSize.medium}
              label={"Tên Tài Khoản"}
              style={{
                ...Platform.select({
                  web: { outlineColor: "transparent" }
                })
              }}
              textColor={colors.textBody}
              tintColor={colors.textPlaceHolder}
              baseColor={colors.textPlaceHolder}
              value={this.state.account.username || ""}
              onChangeText={text => this.onChangeUsername(text)}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <TextField
                ref={ref => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16)
                }}
                labelFontSize={fontSize.medium}
                label={"Mật Khẩu"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" }
                  })
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.account.password || ""}
                onChangeText={text => this.onChangePassword(text)}
                secureTextEntry={this.state.showPass}
              />
              <TouchableOpacity
                style={{
                  width: Layout.minerScale(50),
                  aspectRatio: 1 / 1
                }}
                onPress={() => this.showPass()}
              >
                <MaterialCommunityIcons
                  name={this.state.showPass ? "eye" : "eye-off"}
                  size={Layout.minerScale(25)}
                  color={colors.textBody}
                />
              </TouchableOpacity>
            </View>

            <TextField
              ref={ref => (this.refTextInputName = ref)}
              fontSize={fontSize.large}
              containerStyle={{
                paddingHorizontal: Layout.minerScale(16)
              }}
              labelFontSize={fontSize.medium}
              label={"Số Điện Thoại"}
              style={{
                ...Platform.select({
                  web: { outlineColor: "transparent" }
                })
              }}
              textColor={colors.textBody}
              tintColor={colors.textPlaceHolder}
              baseColor={colors.textPlaceHolder}
              value={this.state.account.phone || ""}
              maxLength={10}
              onChangeText={text => this.onChangePhone(text)}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingHorizontal: Layout.minerScale(16),
                marginVertical: spaceSize.margin.medium
              }}
            >
              <Text
                style={{
                  fontSize: fontSize.large,
                  color: colors.textBody,
                  textAlign: "left"
                }}
              >
                {"Loại Tài Khoản"}
              </Text>
              <WebPicker
                currentValue={this.state.account.type}
                options={enumAccount.listType}
                style={{ width: Layout.minerScale(200) }}
                onChange={value => this.onChangeType(value)}
              />
            </View>
            {this.state.account.type !== enumAccount.ruleOption.admin && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingHorizontal: Layout.minerScale(16),
                  marginVertical: spaceSize.margin.medium
                }}
              >
                <Text
                  style={{
                    fontSize: fontSize.large,
                    color: colors.textBody,
                    textAlign: "left"
                  }}
                >
                  {"Chi Nhánh"}
                </Text>
                <WebPicker
                  currentValue={this.state.account.subsidiaryUuid}
                  options={optionBranch}
                  style={{ width: Layout.minerScale(200) }}
                  onChange={value => this.onChangeBranch(value)}
                />
              </View>
            )}
          </ScrollView>
          <View
            style={{
              alignItems: "center",
              marginTop: spaceSize.margin.largest
            }}
          >
            <TouchableOpacity style={styles.button} onPress={this.onSave}>
              <Text style={styles.buttonText}>Lưu</Text>
            </TouchableOpacity>
          </View>
          <Toast
            ref="toast"
            fadeInDuration={500}
            fadeOutDuration={750}
            opacity={0.8}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

TeacherDetailScreen.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed"
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  subjectSwitchContainer: {
    flexDirection: "row",
    height: Layout.minerScale(30),
    alignItems: "center"
  },
  nameSubjectSwitch: {
    fontSize: fontSize.medium,
    color: colors.textBody,
    paddingHorizontal: Layout.minerScale(10)
  },
  titleSubjectSwitchContainer: {
    fontSize: fontSize.largest,
    color: colors.textBody
  },
  titleTimeframeContainer: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody,
    paddingBottom: Layout.minerScale(8)
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12)
  },
  timeframeTitleText: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody
  },
  checkAll: {},
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest
  }
});
