import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/AccountReducer";
import { writelog } from "../../Utilities/Logging";

function* getAccounts(action) {
  try {
    const result = yield call(() =>
      API.get("/app/user/views").then(res => res.data)
    );

    if (result.status === 1) {
      yield put({
        type: Types.GET_ACCOUNTS_SUCCESS,
        listAccount: result.response.map(account => {
          return {
            uuid: account.uuid,
            subsidiaryUuid: account.subsidiaryUuid,
            type: account.type,
            username: account.username,
            password: account.password,
            name: account.name,
            phone: account.phone
          };
        })
      });
    } else {
      yield put({
        type: Types.GET_ACCOUNTS_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    writelog("LoginSaga ", "getAccounts", e, 0);
    yield put({
      type: Types.GET_ACCOUNTS_FAIL,
      message: "Không thể kết nối máy chủ!"
    });
  }
}

function* register(action) {
  try {

    const result = yield call(() =>
      API.put("/app/user", {
        uuid: action.data.uuid,
        subsidiaryUuid: action.data.subsidiaryUuid,
        type: action.data.type,
        username: action.data.username,
        password: action.data.password,
        name: action.data.name,
        phone: action.data.phone
      }).then(res => res.data)
    );

    if (result.status === 1) {
      yield put({
        type: Types.REGISTER_SUCCESS
      });
    } else {
      yield put({
        type: Types.REGISTER_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    writelog("AccountSaga ", "register", e, 0);
    yield put({
      type: Types.REGISTER_FAIL,
      message: "Không thể kết nối máy chủ"
    });
  }
}

function* deleteAccount(action) {
  try {
    const result = yield call(() =>
      API.delete("/app/user", { uuid: action.uuid }).then(res => res.data)
    );

    if (result.status === 1) {
      yield put({
        type: Types.DELETE_ACCOUNT_SUCCESS
      });
    } else {
      yield put({
        type: Types.DELETE_ACCOUNT_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    writelog("AccountSaga ", "deleteAccount", e, 0);
    yield put({
      type: Types.DELETE_ACCOUNT_FAIL,
      message: "Không thể kết nối máy chủ"
    });
  }
}

function* AccountSaga() {
  yield takeLatest(Types.GET_ACCOUNTS, getAccounts);
  yield takeLatest(Types.REGISTER, register);
  yield takeLatest(Types.DELETE_ACCOUNT, deleteAccount);
}

export default AccountSaga;
