import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getCourses: [],
  getCoursesSuccess: null,
  getCoursesFail: null,
  //
  insertCourse: ["course"],
  insertCourseSuccess: null,
  insertCourseFail: null,
  //
  deleteCourse: ["uuid"],
  deleteCourseSuccess: null,
  deleteCourseFail: null,
  //
  updateCourse: ["course"],
  updateCourseSuccess: null,
  updateCourseFail: null,

  setScheduleCourse: ["course"],
  setScheduleCourseSuccess: null,
  setScheduleCourseFail: null,

  resetTypeCourse: null
});

export const INITIAL_STATE = {
  type: "",
  courses: [],
  message: ""
};

const getCourses = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getCoursesSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    courses: action.courses,
    message: action.message
  });
};

const getCoursesFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertCourse = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertCourseSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertCourseFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const deleteCourse = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteCourseSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteCourseFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const updateCourse = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateCourseSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateCourseFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const setScheduleCourse = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const setScheduleCourseSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const setScheduleCourseFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const resetTypeCourse = (state, action) => {
  return Object.assign({}, state, {
    type: ""
  });
};

const HANDLERS = {
  [Types.GET_COURSES]: getCourses,
  [Types.GET_COURSES_SUCCESS]: getCoursesSuccess,
  [Types.GET_COURSES_FAIL]: getCoursesFail,
  //
  [Types.INSERT_COURSE]: insertCourse,
  [Types.INSERT_COURSE_SUCCESS]: insertCourseSuccess,
  [Types.INSERT_COURSE_FAIL]: insertCourseFail,
  //
  [Types.DELETE_COURSE]: deleteCourse,
  [Types.DELETE_COURSE_SUCCESS]: deleteCourseSuccess,
  [Types.DELETE_COURSE_FAIL]: deleteCourseFail,
  //
  [Types.UPDATE_COURSE]: updateCourse,
  [Types.UPDATE_COURSE_SUCCESS]: updateCourseSuccess,
  [Types.UPDATE_COURSE_FAIL]: updateCourseFail,

  [Types.SET_SCHEDULE_COURSE]: setScheduleCourse,
  [Types.SET_SCHEDULE_COURSE_SUCCESS]: setScheduleCourseSuccess,
  [Types.SET_SCHEDULE_COURSE_FAIL]: setScheduleCourseFail,

  [Types.RESET_TYPE_COURSE]: resetTypeCourse
};

export const courseState = createReducer(INITIAL_STATE, HANDLERS);
