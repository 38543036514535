import { AntDesign, Ionicons, SimpleLineIcons } from "@expo/vector-icons";
import { ScreenOrientation } from "expo";
import React, { Component } from "react";
import { StyleSheet, Text, Platform, View, Image } from "react-native";
import { Layout, colors, images } from "../Themes";
import { spaceSize } from "../Themes";
import { fontSize } from "../Themes";
import { enumAccount } from "../Utilities/Enum";
import {
  IconTextButton,
  IconTextMate,
  IconTextMateCom,
  IconTextButton2
} from "./CustomButton";

export default class DrawerContent extends Component {
  constructor(props) {
    super(props);
  }

  navigateToScreen = async routeName => {
    if (Platform.OS === "web") {
      this.props.goTo(routeName);
    } else {
      await this.props.navigation.closeDrawer();
      if (this.props.activeItemKey === routeName) return;
      ScreenOrientation.lockAsync(ScreenOrientation.Orientation.PORTRAIT);
      this.props.navigation.navigate({ routeName: routeName });
    }
  };

  render() {
    const { currentScreen } = this.props;
    const userTypes = this.props.userInfo ? this.props.userInfo.userTypes : [];
    const userName = this.props.userInfo && this.props.userInfo.name;
    return (
      <View style={[ss.container]}>
        <View style={ss.headerContainer}>
          {Platform.OS === "web" ? (
            !!this.props.showLogo && (
              <Image
                style={{
                  height: Layout.minerScale(68),
                  width: Layout.minerScale(210)
                }}
                resizeMode={"contain"}
                source={images.logo}
              />
            )
          ) : (
            <Image
              style={{
                height: Layout.minerScale(68),
                width: Layout.minerScale(210)
              }}
              resizeMode={"contain"}
              source={images.logo}
            />
          )}
          {/* <Ionicons style={ss.headerIcon} name="ios-school" />
          <Text style={ss.headerTitle}>{"Titan Education"}</Text> */}
        </View>
        <View style={ss.userContainer}>
          <IconTextMateCom
            nameIcon={"account"}
            text={userName}
            styleIcon={ss.userIcon}
            textStyle={ss.userText}
          />
        </View>
        <View style={ss.screenContainer}>
          {/* Màn hình chính */}
          {(userTypes.includes(enumAccount.ruleOption.employee) ||
            userTypes.includes(enumAccount.ruleOption.manager)) && (
            <View
              style={
                currentScreen === "HomeScreen"
                  ? ss.viewItemSelected
                  : ss.viewItem
              }
            >
              <IconTextButton
                nameIcon={"home"}
                text={"Màn hình chính"}
                onPress={() => this.navigateToScreen("HomeScreen")}
                styleIcon={
                  currentScreen === "HomeScreen"
                    ? ss.styleIconSelected
                    : ss.styleIcon
                }
                textStyle={
                  currentScreen === "HomeScreen"
                    ? ss.textItemSelected
                    : ss.textItem
                }
              />
            </View>
          )}
          {/* Xếp lịch lớp học */}
          {userTypes.includes(enumAccount.ruleOption.manager) && (
            <View
              style={
                currentScreen === "ScheduleScreen"
                  ? ss.viewItemSelected
                  : ss.viewItem
              }
            >
              <IconTextButton
                nameIcon={"calendar"}
                text={"Xếp lịch lớp học"}
                onPress={() => this.navigateToScreen("ScheduleScreen")}
                styleIcon={
                  currentScreen === "ScheduleScreen"
                    ? ss.styleIconSelected
                    : ss.styleIcon
                }
                textStyle={
                  currentScreen === "ScheduleScreen"
                    ? ss.textItemSelected
                    : ss.textItem
                }
              />
            </View>
          )}
          {/* Thời khóa biểu Giảng viên */}
          {(userTypes.includes(enumAccount.ruleOption.employee) ||
            userTypes.includes(enumAccount.ruleOption.manager)) && (
            <View
              style={
                currentScreen === "TeacherSchedule"
                  ? ss.viewItemSelected
                  : ss.viewItem
              }
            >
              <IconTextMateCom
                nameIcon={"calendar-clock"}
                text={"Thời khóa biểu Giảng viên"}
                onPress={() => this.navigateToScreen("TeacherSchedule")}
                styleIcon={
                  currentScreen === "TeacherSchedule"
                    ? ss.styleIconSelected
                    : ss.styleIcon
                }
                textStyle={
                  currentScreen === "TeacherSchedule"
                    ? ss.textItemSelected
                    : ss.textItem
                }
              />
            </View>
          )}
          {/* Thời khóa biểu Khối, Lớp */}
          {(userTypes.includes(enumAccount.ruleOption.employee) ||
            userTypes.includes(enumAccount.ruleOption.manager)) && (
            <View
              style={
                currentScreen === "GradeSchedule"
                  ? ss.viewItemSelected
                  : ss.viewItem
              }
            >
              <IconTextMateCom
                nameIcon={"calendar-multiple"}
                text={"Thời khóa biểu Khối"}
                onPress={() => this.navigateToScreen("GradeSchedule")}
                styleIcon={
                  currentScreen === "GradeSchedule"
                    ? ss.styleIconSelected
                    : ss.styleIcon
                }
                textStyle={
                  currentScreen === "GradeSchedule"
                    ? ss.textItemSelected
                    : ss.textItem
                }
              />
            </View>
          )}
          {/* Thông tin Giảng viên */}
          {(userTypes.includes(enumAccount.ruleOption.employee) ||
            userTypes.includes(enumAccount.ruleOption.manager)) && (
            <View
              style={
                currentScreen === "TeacherInfo"
                  ? ss.viewItemSelected
                  : ss.viewItem
              }
            >
              <IconTextMate
                nameIcon={"people"}
                text={"Thông tin Giảng viên"}
                onPress={() => this.navigateToScreen("TeacherInfo")}
                styleIcon={
                  currentScreen === "TeacherInfo"
                    ? ss.styleIconSelected
                    : ss.styleIcon
                }
                textStyle={
                  currentScreen === "TeacherInfo"
                    ? ss.textItemSelected
                    : ss.textItem
                }
              />
            </View>
          )}
          {/* Setting */}
          {!userTypes.includes(enumAccount.ruleOption.employee) && (
            <View
              style={
                currentScreen === "SettingScreen"
                  ? ss.viewItemSelected
                  : ss.viewItem
              }
            >
              <IconTextButton
                nameIcon={"cog"}
                text={"Cài đặt"}
                onPress={() => this.navigateToScreen("SettingScreen")}
                styleIcon={
                  currentScreen === "SettingScreen"
                    ? ss.styleIconSelected
                    : ss.styleIcon
                }
                textStyle={
                  currentScreen === "SettingScreen"
                    ? ss.textItemSelected
                    : ss.textItem
                }
              />
            </View>
          )}
          <View style={ss.viewItem}>
            <IconTextButton2
              nameIcon={"logout"}
              text={"Đăng Xuất"}
              onPress={() => this.navigateToScreen("Login")}
              styleIcon={[ss.styleIcon]}
              textStyle={ss.textItem}
            />
          </View>
        </View>
      </View>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    width: Layout.minerScale(250),
    alignItems: "center"
  },

  line: {
    width: "100%",
    borderBottomWidth: spaceSize.boderWidth.medium,
    borderBottomColor: "rgb(220,220,220)"
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: spaceSize.margin.large,
    width: "100%"
  },
  headerIcon: {
    paddingRight: spaceSize.padding.small,
    fontSize: fontSize.large,
    color: "red"
  },
  headerTitle: {
    fontSize: fontSize.large,
    color: "red"
  },
  userContainer: {
    width: "100%",
    marginTop: spaceSize.margin.large,
    paddingVertical: spaceSize.padding.small,
    paddingLeft: spaceSize.padding.medium + spaceSize.padding.small,
    borderBottomWidth: 1,
    borderBottomColor: "#EEEEEE"
  },
  userIcon: {
    fontSize: fontSize.hugest,
    color: colors.textNav
  },
  userText: {
    fontSize: fontSize.large,
    color: colors.textNav,
    fontWeight: "normal"
  },
  screenContainer: {
    width: "100%",
    paddingLeft: spaceSize.padding.medium
  },
  touchableOpacityStyle: {
    padding: spaceSize.padding.small,
    paddingVertical: spaceSize.padding.medium,
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  iconSetting: {
    fontSize: fontSize.medium,
    paddingHorizontal: spaceSize.padding.small
  },
  screenTextStyle: {
    fontSize: fontSize.medium,
    textAlign: "center"
  },
  selectedTextStyle: {
    color: "#00adff"
  },
  viewItem: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    //
    height: Layout.minerScale(50),
    width: "100%",
    //
    marginTop: spaceSize.margin.large,
    paddingLeft: spaceSize.padding.medium
  },
  viewItemSelected: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    //
    height: Layout.minerScale(50),
    borderTopLeftRadius: Layout.minerScale(25),
    borderBottomLeftRadius: Layout.minerScale(25),
    width: "100%",
    //
    marginTop: spaceSize.margin.large,
    paddingLeft: spaceSize.padding.medium,
    backgroundColor: colors.bgNavItem
  },
  styleIcon: {
    fontSize: fontSize.large,
    color: colors.textNav
  },
  styleIconSelected: {
    fontSize: fontSize.large,
    color: colors.textNavSelected
  },
  textItem: {
    color: colors.textNav,
    fontSize: fontSize.medium,
    fontWeight: "normal"
  },
  textItemSelected: {
    color: colors.textNavSelected,
    fontSize: fontSize.medium,
    fontWeight: "normal"
  }
});
