import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CreatorsSubject } from "../../Models/Reducers/SubjectReducer";
import SubjectScreen from "./SubjectScreen";

const mapStateToProps = (state, props) => {
  return {
    subjectState: state.subjectState,
    gradeState: state.gradeState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreatorsSubject
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubjectScreen);
