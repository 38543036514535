import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CreatorsTeacher } from "../../../Models/Reducers/TeacherReducer";
import TeacherScreen from "./TeacherScreen";
const mapStateToProps = (state, props) => {
  return {
    teacherType: state.teacherState.type,
    teacherMess: state.teacherState.message,
    listTeacher: (state.teacherState && state.teacherState.teachers) || []
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreatorsTeacher
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeacherScreen);
