import React, { PureComponent } from "react";
import { View, StyleSheet, Text, TextInput, Platform } from "react-native";
import { onlyNumber } from "../../../Utilities/ValueHandling";
import { bool, number, func, object, array } from "prop-types";
import { formatCurrency } from "../../../Utilities/Format";

const maxPercent = 100;
export default class NumberInput extends PureComponent {
  static propTypes = {
    // value: number.isRequired,
    onReturnNumber: func.isRequired,
    isFractions: bool,
    isPercent: bool,
    isCurrency: bool,
    defaultNumber: number,
    returnDelay: bool
  };

  static defaultProps = {
    // value: 0,
    onReturnNumber: () => {},
    isFractions: true,
    isPercent: false,
    isCurrency: false,
    defaultNumber: 0,
    returnDelay: false
  };
  constructor(props) {
    super(props);
    this.state = {
      number: this.props.defaultNumber.toString(),
      formated: ""
    };
  }
  componentDidMount() {
    const { value } = this.props;
    this.setState({
      number: value ? value.toString() : this.props.defaultNumber.toString(),
      formated: value ? this.numberFormat(value) : ""
    });
  }
  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({
      number: value ? value.toString() : this.props.defaultNumber.toString(),
      formated: value ? this.numberFormat(value) : ""
    });
  }
  numberFormat = number => {
    if (!this.props.isFractions) return number.toString();
    else if (this.props.isPercent) {
      if (number > 100) return formatCurrency(maxPercent);
      else return formatCurrency(number, 0, ".", ",");
    } else return formatCurrency(number, 0, ".", ",");
  };
  onChangeNumber = (number, text) => {
    // if (this.props.isFractions) text = text;
    // else
    text = onlyNumber(text, this.props.isFractions ? "." : "");
    if (number === this.props.defaultNumber.toString()) {
      if (text === this.props.defaultNumber.toString()) return;
      else
        this.setState({
          number: text.substr(1, 1),
          formated: text.substr(1, 1)
        });
    } else if (
      number.indexOf(".") > -1 &&
      text.substr(text.length - 1, 1) === "." &&
      text.length > number.length
    )
      return;
    else {
      this.setState({
        number: text,
        formated: text
      });
    }
    if (!this.props.isFractions && !this.props.returnDelay) {
      const { onReturnNumber } = this.props;
      if (
        text !==
        (this.props.value
          ? this.props.value.toString()
          : this.props.defaultNumber.toString())
      )
        onReturnNumber(
          this.props.isPercent && Number(text) > 100 ? maxPercent : Number(text)
        ); // return number to parent component
    }
  };
  onSubmit = () => {
    const realNumber = Number(this.state.number);
    this.setState({
      formated: this.numberFormat(realNumber)
    });
    const { onReturnNumber } = this.props;
    onReturnNumber(
      this.props.isPercent && realNumber > 100 ? maxPercent : realNumber
    ); // return number to parent component
  };
  render() {
    const { number, formated } = this.state;

    const {
      isCurrency,
      isPercent,
      percentStyle,
      input,
      textStyle,
      disabled
    } = this.props;
    return (
      <View style={[ss.input, input && input, { flexDirection: "row" }]}>
        <View style={{ flex: 9 }}>
          <TextInput
            onChangeText={text => {
              this.onChangeNumber(number, text);
            }}
            onEndEditing={this.onSubmit}
            onBlur={this.onSubmit}
            // keyboardType="numeric"
            value={formated !== "" ? formated : number}
            style={[
              ss.text,
              textStyle,
              { ...Platform.select({ web: { outlineColor: "transparent" } }) }
            ]}
            placeholder={
              isCurrency || isPercent
                ? `${this.props.defaultNumber.toString()}.00`
                : this.props.defaultNumber.toString()
            }
            editable={!disabled}
            onFocus={this.props.onFocus}
            maxLength={this.props.maxLength}
          />
        </View>

        {isCurrency ? (
          <Text style={[ss.percentStyle, percentStyle]}>VNĐ</Text>
        ) : null}
        {isPercent ? (
          <Text style={[ss.percentStyle, percentStyle]}>%</Text>
        ) : null}
      </View>
    );
  }
}
const ss = StyleSheet.create({
  input: {
    height: "100%",
    width: "100%",
    borderRadius: 5,
    justifyContent: "space-between",
    alignItems: "center"
  },
  text: {
    color: "#fff"
  },
  percentStyle: {
    fontSize: 14,
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold"
  }
});
