import React from "react";
import { createElement } from "react-native-web";
import { Layout, colors, fontSize } from "../../Themes";

const Option = ({ label, value }) =>
  createElement("option", {
    children: label,
    value,
    style: { color: colors.textInput }
  });

export const WebPicker = ({ style, currentValue, onChange, options }) =>
  createElement("select", {
    value: currentValue,
    style: [
      {
        // Container Style
        backgroundColor: "transparent",
        height: Layout.minerScale(35),
        borderWidth: 1,
        // Text Style
        fontSize: fontSize.medium,
        color: colors.textBody,
        textAlign: "center",
        fontWeight: "bold"
      },
      style
    ],
    onChange: event => onChange(event.target.value),
    children: options.map(option => (
      <Option key={`${option.label}_${option.value}`} {...option} />
    ))
  });

export default WebPicker;
