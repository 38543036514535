import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { spaceSize, colors, Layout, fontSize } from "../../Themes";
import TeacherContainer from "../../Screens/Setting/Teacher/TeacherContainer";
import TeacherDetailContainer from "../../Screens/Setting/Teacher/TeacherDetailContainer";
import Fade from "../Fade";
import SearchInput from "../../MicroComponents/Input/SearchInput/SearchInput";

const teacherNew = {
  uuid: null,
  name: "",
  image: null,
  gender: "Male",
  workAddress: null,
  degree: null,
  email: null,
  phone: null,
  subjects: [],
  teachingTimes: []
};
export default class TimeSchedule extends Component {
  state = {
    teacher: null,
    searchVaue: ""
  };

  selectTeacher = teacher => {
    this.setState({ teacher });
  };
  newTeacher = () => {
    this.setState({ teacher: teacherNew });
  };
  render() {
    return (
      <Fade>
        <View style={ss.container}>
          <View
            style={{
              flex: 2,
              marginRight: spaceSize.margin.largest,
              height: "auto",
              maxHeight: "100%"
            }}
          >
            <SearchInput
              value={this.state.searchVaue}
              //
              onChangeText={searchVaue => this.setState({ searchVaue })}
              clearSearch={() => this.setState({ searchVaue: "" })}
              //
              maxLength={20}
              //
              styleWrapper={ss.search}
              styleText={[
                {
                  width: "80%",
                  fontSize: fontSize.medium,
                  color: colors.textBody
                },
                {
                  ...Platform.select({
                    web: { outlineColor: "transparent" }
                  })
                }
              ]}
              styleIcon={{ fontSize: fontSize.largest }}
            />
            <View style={ss.vieList}>
              <TeacherContainer
                selectTeacher={this.selectTeacher}
                newTeacher={this.newTeacher}
                searchValue={this.state.searchVaue}
              />
            </View>
          </View>
          <View style={ss.vieDetail}>
            <TeacherDetailContainer
              title={
                this.state.teacher
                  ? "Cập nhật - " + this.state.teacher.name
                  : "Tạo mới giảng viên"
              }
              teacher={this.state.teacher ? this.state.teacher : teacherNew}
            />
          </View>
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  search: {
    height: Layout.minerScale(40),
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgBody,
    borderRadius: spaceSize.borderRadius.large * 2,
    marginBottom: spaceSize.margin.large
  },
  vieList: {
    flex: 2,
    height: "auto",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
