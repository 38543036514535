import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/CourseReducer";

function* getCourses(action) {
  try {
    let result = yield call(() =>
      API.get("/app/course/views").then(response => response.data)
    );
    if (result.status === 1) {
      let courses = result.response.map((course, idx) => {
        return {
          id: {
            uuid: course.id.uuid,
            subsidiaryUuid: course.id.subsidiaryUuid
          },
          fee: course.fee,
          duration: course.duration,
          name: course.name,
          grade: course.grade,
          subject: course.subject,
          teacher: course.teacher,
          schedules: course.courseTimes,
          sortIndex: course.sortIndex
        };
      });
      yield put({
        type: Types.GET_COURSES_SUCCESS,
        courses: courses.sort((a, b) => a.name.localeCompare(b.name)),
        message: "Success"
      });
    } else {
      yield put({
        type: Types.GET_COURSES_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_COURSES_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* insertCourse(action) {
  try {
    const result = yield call(() =>
      API.put("/app/course", {
        ...action.course,
        courseTimes: action.course.schedules
      }).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.INSERT_COURSE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.INSERT_COURSE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.INSERT_COURSE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* deleteCourse(action) {
  try {
    const result = yield call(() =>
      API.delete("/app/course", { uuid: action.uuid }).then(
        response => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.DELETE_COURSE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.DELETE_COURSE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.DELETE_COURSE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* updateCourse(action) {
  try {
    const result = yield call(() =>
      API.put("/app/course", {
        ...action.course,
        courseTimes: action.course.schedules
      }).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_COURSE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_COURSE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_COURSE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* setScheduleCourse(action) {
  try {
    const result = yield call(() =>
      API.put("/app/course/set/schedule", {
        ...action.course,
        courseTimes: action.course.schedules
      }).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_COURSE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_COURSE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_COURSE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* CourseSaga() {
  yield takeLatest(Types.GET_COURSES, getCourses);
  yield takeLatest(Types.INSERT_COURSE, insertCourse);
  yield takeLatest(Types.UPDATE_COURSE, updateCourse);
  yield takeLatest(Types.DELETE_COURSE, deleteCourse);
  yield takeLatest(Types.SET_SCHEDULE_COURSE, setScheduleCourse);
}

export default CourseSaga;
