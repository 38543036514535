import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import Icon from "../Icon";

export default class ButtonIconText extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleText: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };

  static defaultProps = {
    title: "",
    icon: "",
    style: {},
    styleIcon: {},
    styleText: {},
    disabled: false,
    onPress: () => {}
  };
  render() {
    return (
      <TouchableOpacity
        style={[ss.container, this.props.style]}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
      >
        <Icon
          name={this.props.icon}
          size={this.props.styleIcon.fontSize}
          color={this.props.styleIcon.color}
        />
        <Text style={[ss.title, this.props.styleText]}>{this.props.title}</Text>
      </TouchableOpacity>
    );
  }
}
const ss = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%"
  },
  title: {
    marginLeft: 5,
    textAlign: "center",
    fontSize: 16,
    color: "#FFF"
  }
});
