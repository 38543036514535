import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getSubjects: [],
  getSubjectsSuccess: null,
  getSubjectsFail: null,
  //
  insertSubject: ["subject"],
  insertSubjectSuccess: null,
  insertSubjectFail: null,
  //
  deleteSubject: ["uuid"],
  deleteSubjectSuccess: null,
  deleteSubjectFail: null,
  //
  updateSubject: ["subject"],
  updateSubjectSuccess: null,
  updateSubjectFail: null
});

export const INITIAL_STATE = {
  type: "",
  subjects: [],
  message: ""
};

const getSubjects = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getSubjectsSuccess = (state, action) => {
  return {
    type: action.type,
    subjects: action.subjects,
    message: action.message
  };
};

const getSubjectsFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const insertSubject = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertSubjectSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertSubjectFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const deleteSubject = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteSubjectSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteSubjectFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const updateSubject = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateSubjectSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateSubjectFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const HANDLERS = {
  [Types.GET_SUBJECTS]: getSubjects,
  [Types.GET_SUBJECTS_SUCCESS]: getSubjectsSuccess,
  [Types.GET_SUBJECTS_FAIL]: getSubjectsFail,
  //
  [Types.INSERT_SUBJECT]: insertSubject,
  [Types.INSERT_SUBJECT_SUCCESS]: insertSubjectSuccess,
  [Types.INSERT_SUBJECT_FAIL]: insertSubjectFail,
  //
  [Types.DELETE_SUBJECT]: deleteSubject,
  [Types.DELETE_SUBJECT_SUCCESS]: deleteSubjectSuccess,
  [Types.DELETE_SUBJECT_FAIL]: deleteSubjectFail,
  //
  [Types.UPDATE_SUBJECT]: updateSubject,
  [Types.UPDATE_SUBJECT_SUCCESS]: updateSubjectSuccess,
  [Types.UPDATE_SUBJECT_FAIL]: updateSubjectFail
};

export const subjectState = createReducer(INITIAL_STATE, HANDLERS);
