import React, { Component } from "react";
import { View, StatusBar, NetInfo, Text } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReduxNavigation } from "./Navigation/ReduxNavigation";
import { API } from "./Models/API";
import { Messages } from "./Utilities/Message";
import {Creators as DeviceStatusReducer} from "./Models/Reducers/DeviceStatusReducer";

class RootContainer extends Component {
  componentDidMount() {
    ////==>  android
    ////==>  edit AndroidManifest.xml
    ////==>  <uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" />
    NetInfo.isConnected.addEventListener("connectionChange", isConnected => {
      const status = isConnected ? "online" : "offline";
      this.props.updateNetworkStatus(status);
      // console.log(
      //   "Network status: " + (isConnected ? "online" : "offline"),
      //   NetInfo
      // );
    });
    ////==> catch API reponse ERR
    // API.addResponseTransform(response => {
    //   console.log("add Response Transform ===> ", response);
    // });
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.props.deviceStatus &&
          this.props.deviceStatus.networkStatus === "offline" && (
            <View
              style={{
                height: 25,
                width: "100%",
                backgroundColor: "black",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "white", fontSize: 15, textAlign: "center" }}
              >
                {Messages.networkError}
              </Text>
            </View>
          )}
        <ReduxNavigation />
      </View>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { deviceStatus: state.deviceStatus };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...DeviceStatusReducer }, dispatch);

// Export default Activity Container
export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
