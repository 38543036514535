import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ReportCreators } from "../../Models/Reducers/ReportReducer";
import { Creators as CourseCreators } from "../../Models/Reducers/CourseReducer";
import { Creators as WeekdayCreators } from "../../Models/Reducers/WeekdayReducer";
import { Creators as TimeframeReducer } from "../../Models/Reducers/TimeframeReducer";
import { Creators as TeacherReducer } from "../../Models/Reducers/TeacherReducer";

import ScheduleScreen from "./ScheduleScreen";

const mapStateToProps = (state, props) => {
  return {
    listTeacher: (state.teacherState && state.teacherState.teachers) || [],
    listCoure: (state.courseState && state.courseState.courses) || [],

    courseState: state.courseState,
    teacherState: state.teacherState,
    weekdayState: state.weekdayState,
    timeframeState: state.timeframeState,
    reportState: state.reportState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReportCreators,
      ...CourseCreators,
      ...WeekdayCreators,
      ...TimeframeReducer,
      ...TeacherReducer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleScreen);
