import { Platform } from "react-native";

/**
 * @providesModule Colors
 */
export const colors = {
  bgNav: "rgb(33,33,33)",
  bgNav075: "rgba(33,33,33, 0.75)",
  bgNavItem: "rgba(255,255,255,0.87)",

  bgMain: "#fff",
  bgMain075: "rgba(255,255,255, 0.75)",

  bgMenu1: "#5cb85c",
  bgMenu2: "rgba(0,0,0,0.5)",

  bgBody: "rgba(33, 33, 33, 0.75)",

  focus: "rgb(227,80,80)",
  focusOption: "rgba(0,0,0,0.7)",

  bgSecond: "rgb(228,242,253)",

  bgItem: "rgb(255,255,255)",
  bgInput: "rgb(255,255,255)",
  bgTabbar: "rgb(235,239,242)",

  shadow: "rgba(0,0,0,0.1)",
  backdropColor: "rgba(0,0,0,0.5)",
  backdropColor2: "rgba(170,170,170,0.4)",
  //
  bgIconEnable: "rgb(76, 171, 242)",
  bgIconDisable: "rgb(170,170,170)",
  bgIconSpecial: "rgb(227,80,80)",
  //
  bgSelected: "#5cb85c",
  //
  borderEnable: "rgb(76, 171, 242)",
  borderDisable: "rgb(170,170,170)",

  //
  line: "rgb(235,239,242)",
  buttonEnable: "#5cb85c",
  buttonDisable: "rgb(170,170,170)",
  buttonDisable025: "rgba(170,170,170,0.25)",
  buttonDisable05: "rgba(170,170,170,0.5)",
  buttonSpecial: "rgb(227,80,80)",

  buttonSave: "#5cb85c",
  buttonDelete: "rgb(227,80,80)",
  //
  lineEnable: "rgb(76, 171, 242)",
  lineDisable: "rgb(170,170,170)",
  lineDisable05: "rgba(170,170,170,0.5)",
  lineSpecial: "rgb(227,80,80)",

  textNav: "rgba(255,255,255,0.87)",
  textNavSelected: "rgba(0,0,0,0.7)",
  textBody: Platform.OS === "web" ? "rgba(255,255,255,0.87)" : "rgb(85,85,85)",

  textHeader: "#FFFFFF",
  textFooter: "#FFFFFF",
  textGroupSecond: "#FFFFFF",
  textButton: "#FFFFFF",
  textIcon: "#000",

  textCopyright: "#808080",
  textInput: "rgb(85,85,85)",
  textContent: "rgb(85,85,85)",
  textPlaceHolder: "rgb(170,170,170)",
  //
  textActive: "rgb(76, 171, 242)",
  textDisable: "rgb(170,170,170)",
  textSpecial: "rgb(227,80,80)",

  //
  icon: "#FFFFFF",
  iconNav: "#FFFFFF",
  iconEnable: "rgb(76, 171, 242)",
  iconDisable: "rgb(170,170,170)",
  iconSpecial: "rgb(227,80,80)",
  iconDownload: "rgb(100,240,0)",
  iconDownloadAll: "rgb(83,131,236)",
  iconEmail: "#FFFFFF",
  iconEmailAll: "rgb(227,80,80)",

  //custom
  label: "rgba(76, 171, 242, 0.75)",
  labelSpecial: "rgba(227,80,80, 0.75)",
  iconPayment: "rgb(100,240,0)",
  facebook: "rgb(75,102,174)",
  google: "rgb(83,131,236)",
  //
  spin: "#FFFFFF"
};
