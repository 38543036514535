import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { spaceSize, colors, Layout, fontSize } from "../../Themes";

import CourseContainer from "../../Screens/Setting/Course/CourseContainer";
import CourseDetailContainer from "../../Screens/Setting/Course/CourseDetailContainer";
import Fade from "../Fade";
import SearchInput from "../../MicroComponents/Input/SearchInput/SearchInput";

const courseNew = {
  uuid: null,
  name: "",
  schedules: []
};
export default class Course extends Component {
  state = {
    course: null,
    searchVaue: ""
  };

  selectCourse = course => {
    this.setState({ course });
  };
  newCourse = () => {
    this.setState({ course: null });
  };

  render() {
    return (
      <Fade>
        <View style={ss.container}>
          <View
            style={{
              flex: 2,
              marginRight: spaceSize.margin.largest,
              height: "auto",
              maxHeight: "100%"
            }}
          >
            <SearchInput
              value={this.state.searchVaue}
              //
              onChangeText={searchVaue => this.setState({ searchVaue })}
              clearSearch={() => this.setState({ searchVaue: "" })}
              //
              maxLength={20}
              //
              styleWrapper={ss.search}
              styleText={[
                {
                  width: "80%",
                  fontSize: fontSize.medium,
                  color: colors.textBody
                },
                {
                  ...Platform.select({
                    web: { outlineColor: "transparent" }
                  })
                }
              ]}
              styleIcon={{ fontSize: fontSize.largest }}
            />
            <View style={ss.vieList}>
              <CourseContainer
                selectCourse={this.selectCourse}
                newCourse={this.newCourse}
                searchValue={this.state.searchVaue}
              />
            </View>
          </View>
          <View style={ss.verticalLine}></View>
          <View style={ss.vieDetail}>
            <CourseDetailContainer
              title={
                this.state.course
                  ? "Cập nhật - " + this.state.course.name
                  : "Tạo mới Lớp"
              }
              course={this.state.course ? this.state.course : courseNew}
            />
          </View>
        </View>
      </Fade>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  search: {
    height: Layout.minerScale(40),
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgBody,
    borderRadius: spaceSize.borderRadius.large * 2,
    marginBottom: spaceSize.margin.large
  },
  vieList: {
    flex: 1,
    height: "auto",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  },
  vieDetail: {
    flex: 5,
    height: "auto",
    maxHeight: "100%",
    overflow: "hidden",
    borderRadius: Layout.minerScale(20),
    padding: Layout.minerScale(10),
    backgroundColor: colors.bgBody
  }
});
