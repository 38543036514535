import { MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Layout } from "../Themes";

export default class IconTitleHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View style={styles.headerContainer}>
        {this.props.icon}
        <Text style={styles.title}>{this.props.title}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(50),
    backgroundColor: "#fff",
    borderBottomColor: "rgba(128,128,128,0.5)",
    borderBottomWidth: 1,
    zIndex: 1
  },

  title: {
    fontSize: Layout.minerScale(24),
    textAlign: "center"
  }
});
