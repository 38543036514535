import React, { Component } from "react";
import {
  Image,
  ImageBackground,
  Platform,
  StyleSheet,
  View,
  AsyncStorage
} from "react-native";
import { TextField } from "react-native-material-textfield";
import { Button } from "../../MicroComponents/Button/Button";
import { API } from "../../Models/API";
import { Types as LOGIN_TYPE } from "../../Models/Reducers/LoginReducer";
import { colors, images, Layout, spaceSize, fontSize } from "../../Themes";

export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLogin: false
    };
  }
  componentDidMount() {}
  //eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0aXRhbiJ9.iw_43fw4N5mT6yQcDLrN6RQ9SOP1mBbXVFpElNkdpI5RIry4wNPi7-lJwmR32Eqf-wwncHgycPIS-eLUgdDZ4w
  async componentWillReceiveProps(nextProps) {
    if (nextProps.loginState.type !== "") {
      if (nextProps.loginState.type === LOGIN_TYPE.LOGIN_SUCCESS) {
        this.setState({ isLogin: false });

        await API.setHeader("Authorization", nextProps.loginState.auth.token);
        setTimeout(() => {
          this.getData();
        }, 500);
        try {
          await AsyncStorage.setItem(
            "CURRENT_SCREEN",
            JSON.stringify({
              currentScreen: "",
              currentOption: ""
            })
          );
        } catch (error) {
          // Error retrieving data
          console.log(error.message);
        }
        setTimeout(() => {
          this.props.navigation.navigate({
            routeName: "HomeScreen"
          });
        }, 1000);
      }
      if (nextProps.loginState.type === LOGIN_TYPE.LOGIN_FAIL) {
        this.setState({ isLogin: false });

        alert(nextProps.loginState.message);
      }

      this.props.resetTypeLogin();
    }
  }
  getData = () => {
    this.props.getWeekdays();
    this.props.getSubjects();
    this.props.getGrades();
    this.props.getTimeframes();
    this.props.getTeachers();
    this.props.getCourses();
  };
  onLogin = () => {
    // this.getData();
    // this.props.navigation.navigate({ routeName: "HomeScreen" });
    this.setState({ isLogin: true });
    this.props.changeSetting("");
    this.props.changeScreen("");
    this.props.login(this.state.username, this.state.password);
  };

  render() {
    return (
      <ImageBackground source={images.bgMain} style={[styles.loginBackground]}>
        <Image
          source={images.logo}
          resizeMode={"contain"}
          style={styles.loginImage}
        />
        <View style={[styles.contentContainer]}>
          <View style={[styles.loginContainer]}>
            <TextField
              label="Username"
              value={this.state.username}
              onChangeText={username => this.setState({ username })}
              style={{
                ...Platform.select({
                  web: { outlineColor: "transparent" }
                })
              }}
              selectTextOnFocus={true}
              onSubmitEditing={() => this.Password.focus()}
              //
              fontSize={fontSize.large}
              labelFontSize={fontSize.medium}
              textColor={colors.textBody}
              baseColor={colors.textPlaceHolder}
            />
            <TextField
              label="Password"
              ref={ref => (this.Password = ref)}
              value={this.state.password}
              secureTextEntry={true}
              onChangeText={password => this.setState({ password })}
              onSubmitEditing={this.onLogin}
              selectTextOnFocus={true}
              //
              style={{
                ...Platform.select({
                  web: { outlineColor: "transparent" }
                })
              }}
              fontSize={fontSize.large}
              labelFontSize={fontSize.medium}
              textColor={colors.textBody}
              baseColor={colors.textPlaceHolder}
            />
            <Button
              title={`Đăng nhập`}
              onPress={this.onLogin}
              loading={this.state.isLogin}
              style={[
                styles.loginButton,
                !!this.state.username &&
                  !!this.state.password && {
                    backgroundColor: colors.buttonEnable
                  }
              ]}
              styleText={{ color: colors.textBody }}
            />
          </View>
        </View>
      </ImageBackground>
    );
  }
}

LoginScreen.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: "center"
  },
  loginContainer: {
    width: Layout.minerScale(300),
    maxWidth: 500,
    //
    alignItems: "center",
    justifyContent: "center",
    //
    backgroundColor: colors.bgBody,
    borderRadius: spaceSize.borderRadius.large,
    //
    padding: spaceSize.padding.small
  },
  loginImage: {
    height: Layout.minerScale(200),
    width: Layout.minerScale(300),
    maxWidth: 500,
    marginVertical: spaceSize.margin.hugest
  },
  loginBackground: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  loginButton: {
    height: Layout.minerScale(40),
    width: Layout.minerScale(200),
    //
    padding: spaceSize.padding.small,
    //
    borderRadius: spaceSize.borderRadius.large,
    backgroundColor: colors.bgBody,
    //
    marginTop: spaceSize.margin.large
  }
});
