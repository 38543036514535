/** Setting Môn */
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import {
  Animated,
  KeyboardAvoidingView,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
  Platform,
  FlatList,
} from "react-native";
import { RectButton } from "react-native-gesture-handler";
import BackHeader from "../../Components/BackHeader";
import { Layout, fontSize, spaceSize, colors } from "../../Themes";
import { Types as SubjectTypes } from "../../Models/Reducers/SubjectReducer";
import { WebPicker } from "../../MicroComponents/Picker";

const DeleteAction = ({ dragX, onPress }) => {
  const scale = dragX.interpolate({
    inputRange: [-100, 0],
    outputRange: [1, 0],
    extrapolate: "clamp",
  });
  return (
    <Animated.View style={{ flex: 1, transform: [{ scale }] }}>
      <RectButton
        style={{
          backgroundColor: "rgb(220,220,220)",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        onPress={onPress}
      >
        <AntDesign
          name="delete"
          style={{ color: "gray", fontSize: Layout.minerScale(24) }}
        />
      </RectButton>
    </Animated.View>
  );
};

export default class SubjectScreen extends Component {
  constructor(props) {
    super(props);
    this.refSubjectName = {};
    this.state = {
      subjects: [...this.props.subjectState.subjects],
      subject: {
        name: "",
        gradeUuid:
          this.props.gradeState.grades.length > 0 &&
          this.props.gradeState.grades[0].uuid,
      },
      refreshing: false,
    };
  }

  componentDidMount() {
    this.props.getSubjects();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.subjectState.type !== "") {
      switch (nextProps.subjectState.type) {
        case SubjectTypes.INSERT_SUBJECT_FAIL:
          alert(nextProps.subjectState.message);
          break;
        case SubjectTypes.UPDATE_SUBJECT_FAIL:
          alert(nextProps.subjectState.message);
          break;
        case SubjectTypes.DELETE_SUBJECT_FAIL:
          alert(nextProps.subjectState.message);
          break;
        case SubjectTypes.INSERT_SUBJECT_SUCCESS:
          this.props.getSubjects();
          break;
        case SubjectTypes.UPDATE_SUBJECT_SUCCESS:
          this.props.getSubjects();
          break;
        case SubjectTypes.DELETE_SUBJECT_SUCCESS:
          this.props.getSubjects();
          break;
      }
      this.setState({
        subjects: [...nextProps.subjectState.subjects],
        gradeUuid:
          this.props.gradeState.grades.length > 0 &&
          this.props.gradeState.grades[0].uuid,
      });
    }
  }

  onChangeOldSubjectName = (subjectUuid, name) => {
    this.setState({
      subjects: this.state.subjects.map((subject) => {
        if (subject.uuid === subjectUuid) return { ...subject, name };
        return { ...subject };
      }),
    });
  };

  onChangeOldGrade = (subjectUuid, value) => {
    // const findGrade = this.props.gradeState.grades.find(f => f.uuid === value);

    this.setState({
      subjects: this.state.subjects.map((subject) => {
        if (subject.uuid === subjectUuid)
          return { ...subject, gradeUuid: value };
        return { ...subject };
      }),
    });
  };

  onChangeNewSubjectGrade = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      subject: {
        ...prevState.subject,
        gradeUuid: value,
      },
    }));
  };
  onChangeNewSubjectName = (text) => {
    this.setState((prevState) => ({
      ...prevState,
      subject: {
        ...prevState.subject,
        name: text,
      },
    }));
  };

  routeTo = (screen) => {
    this.props.navigation.navigate({ routeName: screen });
  };

  onDelete = (subject) => {
    if (subject.uuid) {
      this.props.deleteSubject(subject.uuid);
    }
  };

  onUpdate = (subject) => {
    if (subject.uuid && subject.name && subject.name !== "")
      return this.props.updateSubject(subject);
    this.props.getSubjects();
  };

  onInsert = () => {
    if (!!this.state.subject.name && !!this.state.subject.gradeUuid) {
      this.props.insertSubject({
        ...this.state.subject,
      });
      this.setState({
        subject: {
          name: "",
          gradeUuid:
            this.props.gradeState.grades.length > 0 &&
            this.props.gradeState.grades[0].uuid,
        },
      });
      this.refSubjectName.focus();
    }
  };

  render() {
    let grades =
      this.props.gradeState &&
      this.props.gradeState.grades &&
      this.props.gradeState.grades.length > 0
        ? this.props.gradeState.grades.map((item) => {
            return {
              value: item.uuid,
              label: item.name,
            };
          })
        : [];
    grades.unshift({ value: "", label: "Không" });
    const subjects = !!this.props.searchValue
      ? this.state.subjects.filter((g) =>
          g.name.toLowerCase().includes(this.props.searchValue.toLowerCase())
        )
      : this.state.subjects;

    return (
      <View style={[styles.container]}>
        {Platform.OS !== "web" && (
          <BackHeader
            title="Bộ môn"
            goBack={() => this.routeTo("SettingScreen")}
          />
        )}
        <KeyboardAvoidingView
          style={{
            flex: 1,
            marginBottom: Layout.minerScale(12),
          }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: Layout.minerScale(50),
            }}
          >
            <AntDesign
              name="plus"
              style={styles.icon}
              onPress={() => this.refSubjectName.focus()}
            />
            <TextInput
              ref={(ref) => (this.refSubjectName = ref)}
              style={styles.textInput}
              onChangeText={(text) => this.onChangeNewSubjectName(text)}
              value={this.state.subject.name}
            />
            <WebPicker
              currentValue={this.state.subject.gradeUuid}
              options={grades}
              style={{ width: Layout.minerScale(200) }}
              onChange={(value) => this.onChangeNewSubjectGrade(value)}
            />
            <AntDesign
              name="check"
              style={[
                styles.icon,
                {
                  color: colors.buttonSave,
                  paddingRight: Layout.minerScale(12),
                },
              ]}
              onPress={() => this.onInsert()}
            />
            <AntDesign
              name="delete"
              style={[
                styles.icon,
                {
                  color: colors.buttonDelete,
                  paddingRight: Layout.minerScale(24),
                  opacity: 0,
                },
              ]}
            />
          </View>
          <View style={styles.line}></View>

          <View style={{ flex: 1 }}>
            <FlatList
              style={{ flex: 1 }}
              refreshing={this.state.refreshing}
              onRefresh={() => this.props.getSubjects()}
              data={subjects}
              keyExtractor={this._keyExtractor}
              extraData={this.state || this.props}
              scrollEventThrottle={16}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item, index }) => {
                return (
                  <View key={index}>
                    <View style={styles.lineContainer}>
                      <MaterialIcons
                        name="bookmark-border"
                        style={styles.icon}
                      />
                      <TextInput
                        style={styles.textInput}
                        onChangeText={(text) =>
                          this.onChangeOldSubjectName(item.uuid, text)
                        }
                        value={item.name}
                      />
                      <WebPicker
                        currentValue={item.gradeUuid || "Khối"}
                        options={grades}
                        style={{ width: Layout.minerScale(200) }}
                        onChange={(value) =>
                          this.onChangeOldGrade(item.uuid, value)
                        }
                      />
                      <AntDesign
                        name="check"
                        style={[
                          styles.icon,
                          {
                            color: colors.buttonSave,
                            paddingRight: Layout.minerScale(12),
                          },
                        ]}
                        onPress={() => this.onUpdate(item)}
                      />
                      <AntDesign
                        name="delete"
                        style={[
                          styles.icon,
                          {
                            color: colors.buttonDelete,
                            paddingRight: Layout.minerScale(24),
                          },
                        ]}
                        onPress={() => this.onDelete(item)}
                      />
                    </View>
                    <View style={styles.line}></View>
                  </View>
                );
              }}
            />
          </View>
        </KeyboardAvoidingView>
      </View>
    );
  }
}

SubjectScreen.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rightActions: {
    width: Layout.minerScale(100),
    flexDirection: "row",
    marginHorizontal: spaceSize.margin.small,
  },
  line: {
    borderBottomWidth: Layout.minerScale(1),
    borderBottomColor: "rgb(220,220,220)",
    marginHorizontal: spaceSize.margin.small,
  },
  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(50),
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.largest,
    paddingHorizontal: spaceSize.padding.small,
    paddingVertical: spaceSize.padding.small,
    paddingRight: 0,
  },
  subjectName: {
    fontSize: fontSize.small,
    textAlign: "center",
    paddingLeft: spaceSize.padding.small,
  },
  textInput: {
    flex: 1,
    fontSize: fontSize.largest,
    paddingLeft: spaceSize.padding.small,
    color: colors.textBody,
    ...Platform.select({ web: { outlineColor: "transparent" } }),
  },
});
