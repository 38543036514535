import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/WeekdayReducer";

function* getWeekdays(action) {
  try {
    let result = yield call(() => API.get("/app/weekday/views").then(response => response.data));
    if (result.status === 1) {
      let weekdays = result.response.map((weekday, idx) => {
        return {
          uuid: weekday.uuid,
          name: weekday.name,
          code: weekday.code,
          sortIndex: weekday.sortIndex
        };
      });
      yield put({
        type: Types.GET_WEEKDAYS_SUCCESS,
        weekdays: weekdays,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.GET_WEEKDAYS_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_WEEKDAYS_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}


function* WeekdaySaga() {
  yield takeLatest(Types.GET_WEEKDAYS, getWeekdays);
}

export default WeekdaySaga;
