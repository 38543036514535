export const writelog = (
  screenName = "",
  functionName = "",
  message = "",
  type = 0 // 1: success, 0: fail
) => {
  if (type === 0)
    console.log(
      `\n\n${"[FAIL]\t"}${screenName} => ${functionName} :\n${message}`
    );
  else
    console.log(
      `\n\n${"[SUCCESS]\t"}${screenName} => ${functionName} : \n${message}`
    );
};
