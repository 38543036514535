import React from "react";
import { Text, View, StyleSheet, Image } from "react-native";
import PropTypes from "prop-types";
import { IconButton } from "./CustomButton";
import { colors, images } from "../Themes";

export const TeacherCard = ({
  item,
  children,
  //
  containerStyle,
  infoStyle,
  socialStyle,
  imageStyle,
  nameStyle,
  actionStyle,
  iconStyle
}) => {
  return (
    <View style={[containerStyle || ss.containerStyle]}>
      <View style={[infoStyle || ss.infoStyle]}>
        <Image
          source={
            item.image || (item.gender === "Male" ? images.gvNam : images.gvNu)
          }
          style={imageStyle || ss.imageStyle}
        />
        <View style={{ flex: 1, marginLeft: 16 }}>
          <Text style={nameStyle}>{item.name}</Text>
          {children}
        </View>
        <View style={actionStyle || ss.actionStyle}>
          <IconButton
            typeIcon="MaterialIcons"
            nameIcon="edit"
            styleButton={{ backgroundColor: "transparent" }}
            styleIcon={iconStyle || ss.iconStyle}
          />
          <IconButton
            typeIcon="MaterialIcons"
            nameIcon="clear"
            styleButton={{ backgroundColor: "transparent" }}
            styleIcon={iconStyle || ss.iconStyle}
          />
        </View>
      </View>
      {/*  */}
      <View style={socialStyle || ss.socialStyle}>
        <IconButton
          typeIcon="FontAwesome"
          nameIcon="instagram"
          styleButton={{ backgroundColor: "transparent" }}
          styleIcon={iconStyle || ss.iconStyle}
        />
        <IconButton
          typeIcon="FontAwesome"
          nameIcon="twitter"
          styleButton={{ backgroundColor: "transparent" }}
          styleIcon={iconStyle || ss.iconStyle}
        />
        <IconButton
          typeIcon="FontAwesome"
          nameIcon="facebook"
          styleButton={{ backgroundColor: "transparent" }}
          styleIcon={iconStyle || ss.iconStyle}
        />
        <IconButton
          typeIcon="AntDesign"
          nameIcon="mail"
          styleButton={{ backgroundColor: "transparent" }}
          styleIcon={iconStyle || ss.iconStyle}
        />
      </View>
    </View>
  );
};

TeacherCard.propTypes = {
  children: PropTypes.element,
  item: PropTypes.object
};

const ss = StyleSheet.create({
  containerStyle: {
    height: 120,
    width: 400,
    backgroundColor: "#fff"
  },
  infoStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(170,170,170)"
  },
  socialStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: 30
  },
  actionStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    //
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    //
    height: 30,
    width: 60
    //
  },
  imageStyle: {
    height: 70,
    width: 70,
    borderRadius: 70 / 2
  },
  shadow: {
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 2,
      width: 3
    },
    elevation: 1
  },
  iconStyle: { color: colors.textPlaceHolder, fontSize: 16 }
});
