import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getTimeframes: [],
  getTimeframesSuccess: null,
  getTimeframesFail: null,
  //
  insertTimeframe: ["timeframe"],
  insertTimeframeSuccess: null,
  insertTimeframeFail: null,
  //
  deleteTimeframe: ["uuid"],
  deleteTimeframeSuccess: null,
  deleteTimeframeFail: null,
  //
  updateTimeframe: ["timeframe"],
  updateTimeframeSuccess: null,
  updateTimeframeFail: null
});

export const INITIAL_STATE = {
  type: "",
  timeframes: [],
  message: ""
};

const getTimeframes = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const getTimeframesSuccess = (state, action) => {
  return {
    type: action.type,
    timeframes: action.timeframes.sort((a, b) =>
      a.beginTime < b.beginTime ? 1 : b.beginTime < a.beginTime ? -1 : 0
    ),
    message: action.message
  };
};

const getTimeframesFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const insertTimeframe = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertTimeframeSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const insertTimeframeFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const deleteTimeframe = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteTimeframeSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const deleteTimeframeFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const updateTimeframe = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateTimeframeSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};

const updateTimeframeFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const HANDLERS = {
  [Types.GET_TIMEFRAMES]: getTimeframes,
  [Types.GET_TIMEFRAMES_SUCCESS]: getTimeframesSuccess,
  [Types.GET_TIMEFRAMES_FAIL]: getTimeframesFail,
  //
  [Types.INSERT_TIMEFRAME]: insertTimeframe,
  [Types.INSERT_TIMEFRAME_SUCCESS]: insertTimeframeSuccess,
  [Types.INSERT_TIMEFRAME_FAIL]: insertTimeframeFail,
  //
  [Types.DELETE_TIMEFRAME]: deleteTimeframe,
  [Types.DELETE_TIMEFRAME_SUCCESS]: deleteTimeframeSuccess,
  [Types.DELETE_TIMEFRAME_FAIL]: deleteTimeframeFail,
  //
  [Types.UPDATE_TIMEFRAME]: updateTimeframe,
  [Types.UPDATE_TIMEFRAME_SUCCESS]: updateTimeframeSuccess,
  [Types.UPDATE_TIMEFRAME_FAIL]: updateTimeframeFail
};

export const timeframeState = createReducer(INITIAL_STATE, HANDLERS);
