import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CourseCreators } from "../../../Models/Reducers/CourseReducer";
import CourseScreen from "./CourseScreen";
const mapStateToProps = (state, props) => {
  return {
    courseState: state.courseState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CourseCreators
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseScreen);
