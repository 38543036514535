import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  login: ["username", "password"],
  loginSuccess: null,
  loginFail: null,
  logout: null,
  resetTypeLogin: null
});

export const INITIAL_STATE = {
  type: "",
  auth: {
    token: null
  },
  userInfo: { userTypes: [] },
  message: ""
};

const login = (state, action) => {
  return Object.assign({}, state, {
    type: action.type
  });
};

const loginSuccess = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    auth: {
      token: action.token
    },
    userInfo: action.userInfo,
    message: "Success"
  });
};

const loginFail = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    message: action.message
  });
};
const resetTypeLogin = (state, action) => {
  return Object.assign({}, state, {
    type: ""
  });
};

const logout = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    auth: { token: null },
    message: ""
  });
};

const HANDLERS = {
  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAIL]: loginFail,
  [Types.RESET_TYPE_LOGIN]: resetTypeLogin,
  [Types.LOGOUT]: logout
};

export const loginState = createReducer(INITIAL_STATE, HANDLERS);
