import { bool, string, func } from "prop-types";
import React, { PureComponent } from "react";
import {
  TextInput,
  Text,
  View,
  StyleSheet,
  TouchableWithoutFeedback
} from "react-native";
import Icon from "../Icon";

export default class Input extends PureComponent {
  static propTypes = {
    selectedValue: string,
    placeholder: string,
    onPress: func
  };

  static defaultProps = {
    value: "",
    placeholder: "",
    onPress: () => {}
  };

  render() {
    return (
      <TouchableWithoutFeedback onPress={this.props.onPress}>
        <View style={[ss.container, this.props.style]}>
          {this.props.icon && (
            <Icon
              name={this.props.icon}
              size={this.props.styleIcon.fontSize}
              color={
                !!this.props.validIconColor
                  ? !!this.props.value
                    ? this.props.validIconColor
                    : this.props.styleIcon.color
                  : this.props.styleIcon.color
              }
            />
          )}
          {this.props.value === "" ? (
            <TextInput
              editable={false}
              style={[ss.textInput, this.props.styleText]}
              placeholder={this.props.placeholder}
            />
          ) : (
            <Text style={[ss.textInput, this.props.styleText]}>
              {this.props.value}
            </Text>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textInput: {
    marginLeft: 10,
    flex: 1,
    textAlign: "left"
  }
});
