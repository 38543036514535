import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import immutableTransform from "redux-persist-transform-immutable";
import { combineReducers } from "redux";
// Import Reducers
import { navigationReducer } from "../Navigation";
import * as Reducers from "../Models/Reducers";

const persistConfig = {
  transforms: [immutableTransform()],
  key: "root",
  storage
};

export const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    nav: navigationReducer,
    ...Reducers
  })
);
