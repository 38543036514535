import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  updateNetworkStatus: ["status"],
  changeOrientation: ["status"]
});

export const INITIAL_STATE = {
  type: "",
  networkStatus: "",
  orientation: ""
};

const updateNetworkStatus = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    networkStatus: action.status
  });
};

const changeOrientation = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    orientation: action.status
  });
};

const HANDLERS = {
  [Types.UPDATE_NETWORK_STATUS]: updateNetworkStatus,
  [Types.CHANGE_ORIENTATION]: changeOrientation
};

export const deviceStatus = createReducer(INITIAL_STATE, HANDLERS);
