import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/GradeReducer";

function* getGrades(action) {
  try {
    let result = yield call(() =>
      API.get("/app/grade/views").then(response => response.data)
    );
    if (result.status === 1) {
      let grades = result.response.map((grade, idx) => {
        return {
          uuid: grade.uuid,
          name: grade.name,
          sortIndex: grade.sortIndex
        };
      });

      yield put({
        type: Types.GET_GRADES_SUCCESS,
        grades: grades.sort((a, b) =>
          a.name.length > -b.name.length
            ? 1
            : a.name.length < b.name.length
            ? -1
            : 0
        ),
        // .sort((a, b) => a.name.localeCompare(b.name))

        message: "Success"
      });
    } else {
      yield put({
        type: Types.GET_GRADES_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_GRADES_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* insertGrade(action) {
  try {
    const result = yield call(() =>
      API.put("/app/grade", action.grade).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.INSERT_GRADE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.INSERT_GRADE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.INSERT_GRADE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* deleteGrade(action) {
  try {
    const result = yield call(() =>
      API.delete("/app/grade", { uuid: action.uuid }).then(
        response => response.data
      )
    );
    if (result.status === 1) {
      yield put({
        type: Types.DELETE_GRADE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.DELETE_GRADE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.DELETE_GRADE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* updateGrade(action) {
  try {
    const result = yield call(() =>
      API.put("/app/grade", action.grade).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_GRADE_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_GRADE_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_GRADE_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}
function* GradeSaga() {
  yield takeLatest(Types.GET_GRADES, getGrades);
  yield takeLatest(Types.INSERT_GRADE, insertGrade);
  yield takeLatest(Types.UPDATE_GRADE, updateGrade);
  yield takeLatest(Types.DELETE_GRADE, deleteGrade);
}

export default GradeSaga;
