import { MaterialCommunityIcons as MateComIcon } from "@expo/vector-icons";
import * as MediaLibrary from "expo-media-library";
import * as Permissions from "expo-permissions";
import React, { Component } from "react";
import {
  Animated,
  AppState,
  FlatList,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  AsyncStorage
} from "react-native";
import DrawerContent from "../../Components/DrawerContent";
import {
  Account,
  Branch,
  Course,
  Email,
  Grade,
  Subject,
  Teacher,
  TimeSchedule
} from "../../Components/MenuBody";
//
import { MenuSetting } from "../../Components/MenuHeader";
import { TeacherCard } from "../../Components/TeacherCard";
import { WebPicker } from "../../MicroComponents/Picker";
import { API } from "../../Models/API";
import { colors, fontSize, images, Layout, spaceSize } from "../../Themes";
import { enumAccount, enumMenuOption } from "../../Utilities/Enum";
import { isHaveValue } from "../../Utilities/ValueHandling";
import GradeSchedule from "../GradeSchedule";
import ScheduleScreen from "../Schedule/ScheduleContainer";
import TeacherInfo from "../TeacherInfo";
import TeacherSchedule from "../TeacherSchedule/TeacherScheduleContainer";

export default class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentScreen: "",
      currentOption: "",
      //
      disableButton: false,
      allowDownload: false,
      openMenu: false,
      menuWidth: new Animated.Value(Layout.minerScale(55)),
      appState: AppState.currentState,
      grid: this.props.weekdayState.weekdays
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(weekday => {
          return {
            title: weekday.name,
            cells: this.props.timeframeState.timeframes
              .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
              .map(timeframe => {
                return {
                  weekday: weekday.uuid,
                  timeframe: timeframe.uuid
                };
              })
          };
        }),
      branchSelected:
        this.props.branchInfo &&
        this.props.branchInfo.branchs.length > 0 &&
        this.props.branchInfo.branchs[0].uuid
    };
  }
  async componentDidMount() {
    // Load current screen, current option
    try {
      const storeValue =
        (await AsyncStorage.getItem("CURRENT_SCREEN")) || "none";
      if (!!storeValue) {
        const storeJson = JSON.parse(storeValue);
        this.setState({
          currentScreen: storeJson.currentScreen,
          currentOption: storeJson.currentOption
        });
      }
    } catch (error) {
      // Error retrieving data
      console.log(error.message);
    }
    //
    if (
      isHaveValue(this.props.loginState, "userTypes") &&
      !this.props.loginState.userInfo.userTypes.includes(
        enumAccount.ruleOption.admin
      )
    ) {
      if (!!this.props.currentScreen)
        this.onSetCurrentScreen(this.props.currentScreen);
      else this.onSetCurrentScreen(enumMenuOption.navigationOption.trangchu);

      if (!!this.props.currentSetting)
        this.setState({ currentOption: this.props.currentSetting });
    }

    try {
      API.setHeader("Authorization", this.props.token);
      setTimeout(() => {
        this.props.views();
        this.props.getTeachers();
        this.props.getWeekdays();
        this.props.getTimeframes();
        this.props.getBranchs();
        //
        let branchSelected = this.state.branchSelected;
        if (
          isHaveValue(this.props.loginState, "userTypes") &&
          this.props.loginState.userInfo.userTypes.includes(
            enumAccount.ruleOption.employee
          )
        ) {
          branchSelected = this.props.loginState.userInfo.subsidiaryUuid;
        }
        this.props.getDailySchedule(branchSelected);
      }, 500);
    } catch (e) {
      console.log(e);
    }
  }

  async componentWillUnmount() {
    // save current screen,  current option
    try {
      await AsyncStorage.setItem(
        "CURRENT_SCREEN",
        JSON.stringify({
          currentScreen: this.state.currentScreen,
          currentOption: this.state.currentOption
        })
      );
    } catch (error) {
      // Error retrieving data
      console.log(error.message);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      grid: nextProps.weekdayState.weekdays
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(weekday => {
          return {
            title: weekday.name,
            cells: nextProps.timeframeState.timeframes
              .sort((a, b) => a.beginTime.localeCompare(b.beginTime))
              .map(timeframe => {
                return {
                  weekday: weekday.uuid,
                  timeframe: timeframe.uuid
                };
              })
          };
        })
    });
  }

  saveFile = async uri => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status === "granted") {
      const asset = await MediaLibrary.createAssetAsync(uri);
      await MediaLibrary.createAlbumAsync("Download", asset, false);
    }
  };

  dowload = () => {
    this.setState({
      disableButton: !this.state.disableButton
    });
    this.props.makeXlxs();
  };

  //handel UI
  onSetCurrentScreen = nextScreen => {
    if (nextScreen === "Login")
      this.props.navigation.navigate({ routeName: "Login" });
    else {
      this.setState({ currentScreen: nextScreen });
      this.props.changeScreen(nextScreen);
    }
  };
  changeMenu = () => {
    if (this.state.openMenu) {
      this.setState({ openMenu: false });
      Animated.timing(this.state.menuWidth, {
        toValue: Layout.minerScale(55),
        duration: 200
      }).start();
    } else {
      this.setState({ openMenu: true });
      Animated.timing(this.state.menuWidth, {
        toValue: Layout.minerScale(250),
        duration: 200
      }).start();
    }
  };
  render() {
    const { currentOption, currentScreen, openMenu } = this.state;
    const optionBranch = this.props.branchInfo.branchs.map(item => {
      return {
        value: item.uuid,
        label: item.name
      };
    });
    return (
      <ImageBackground style={[ss.container]} source={images.bgMain}>
        <Animated.View style={[ss.vieMenu, { width: this.state.menuWidth }]}>
          <DrawerContent
            goTo={this.onSetCurrentScreen.bind(this)}
            currentScreen={currentScreen}
            showLogo={openMenu}
            userInfo={this.props.loginState.userInfo}
          />
          <TouchableOpacity
            style={{
              position: "absolute",
              bottom: spaceSize.padding.small,
              right: spaceSize.padding.small,
              margin: spaceSize.margin.small
            }}
            onPress={this.changeMenu}
          >
            <MateComIcon
              name={openMenu ? "arrow-collapse" : "arrow-expand"}
              size={fontSize.large}
              color={colors.textNav}
            ></MateComIcon>
          </TouchableOpacity>
        </Animated.View>
        {/*  */}
        <View
          style={{
            flex: 1
          }}
        >
          {/* HEADER */}
          {/* <View
            style={{
              height: Layout.minerScale(100),
              paddingVertical: spaceSize.margin.large
            }}
          >
            <MenuSetting
              currentOption={this.state.currentOption}
              setCurrentOption={opt => this.setState({ currentOption: opt })}
            />
          </View> */}

          {/* BODY */}
          {currentScreen === enumMenuOption.navigationOption.trangchu && (
            <FlatList
              columnWrapperStyle={ss.flatlistStyle}
              numColumns={3}
              data={this.props.reportState.dailySchedule}
              keyExtractor={(item, index) => "A" + index.toString()}
              listKey={(item, index) => `A` + index.toString()}
              maxToRenderPerBatch={2}
              ListHeaderComponent={
                isHaveValue(this.props.loginState, "userTypes") &&
                !this.props.loginState.userInfo.userTypes.includes(
                  enumAccount.ruleOption.employee
                ) && (
                  <View style={[ss.header]}>
                    <Text
                      style={[ss.textStyleBranch, { textAlign: "left" }]}
                    >{`Vui lòng chọn chi nhánh:`}</Text>
                    <WebPicker
                      currentValue={this.state.branchSelected}
                      options={optionBranch}
                      style={{ width: Layout.minerScale(200) }}
                      onChange={value => {
                        this.setState({ branchSelected: value });
                        this.props.getDailySchedule(value);
                      }}
                    />
                  </View>
                )
              }
              renderItem={({ item, index }) => {
                return (
                  <TeacherCard
                    item={{
                      name: item.teacherName,
                      gender: item.teacherGender
                    }}
                    children={
                      <View style={{}}>
                        <Text style={ss.textStyle}>{`Mobile: ${
                          !!item.teacherPhone ? item.teacherPhone : ""
                        }`}</Text>
                        <FlatList
                          data={item.schedules}
                          keyExtractor={(item, index) => "B" + index.toString()}
                          listKey={(item, index) => `B` + index.toString()}
                          renderItem={({ item, index }) => {
                            return (
                              <View
                                key={index}
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center"
                                }}
                              >
                                <Text style={ss.textStyle}>{`${
                                  !!item.courseName ? item.courseName : ""
                                }`}</Text>

                                <Text
                                  style={{
                                    fontSize: fontSize.small,
                                    color: colors.textPlaceHolder
                                  }}
                                >
                                  {" ◉ "}
                                </Text>

                                <Text style={ss.textStyle}>{`${
                                  !!item.beginTime ? item.beginTime : ""
                                } - ${!!item.endTime ? item.endTime : ""} (${
                                  !!item.timeframeName ? item.timeframeName : ""
                                })`}</Text>
                              </View>
                            );
                          }}
                        />
                      </View>
                    }
                    imageStyle={ss.imageStyle}
                    containerStyle={ss.teacherCardContainer}
                    nameStyle={ss.nameStyle}
                    socialStyle={ss.socialStyle}
                    iconStyle={ss.iconStyle}
                    actionStyle={ss.actionStyle}
                  />
                );
              }}
            />
          )}

          {currentScreen === enumMenuOption.navigationOption.xeplich && (
            <ScheduleScreen />
          )}

          {currentScreen === enumMenuOption.navigationOption.tkbGiangvien && (
            <TeacherSchedule />
          )}

          {currentScreen === enumMenuOption.navigationOption.tkbKhoi && (
            <GradeSchedule />
          )}

          {currentScreen === enumMenuOption.navigationOption.thongtinGv && (
            <TeacherInfo />
          )}

          {currentScreen === enumMenuOption.navigationOption.catdat && (
            <View style={ss.vieBody}>
              {currentOption === enumMenuOption.settingOption.email && (
                <Email />
              )}
              {currentOption === enumMenuOption.settingOption.taikhoan && (
                <Account />
              )}
              {currentOption === enumMenuOption.settingOption.thoigian && (
                <TimeSchedule />
              )}
              {currentOption === enumMenuOption.settingOption.giangvien && (
                <Teacher />
              )}
              {currentOption === enumMenuOption.settingOption.mon && (
                <Subject />
              )}
              {currentOption === enumMenuOption.settingOption.khoi && <Grade />}
              {currentOption === enumMenuOption.settingOption.lop && <Course />}
              {currentOption === enumMenuOption.settingOption.chinhanh && (
                <Branch />
              )}
            </View>
          )}
        </View>
        {currentScreen === enumMenuOption.navigationOption.catdat && (
          <MenuSetting
            currentOption={this.state.currentOption}
            setCurrentOption={opt => {
              this.setState({ currentOption: opt });
              this.props.changeSetting(opt);
            }}
            userTypes={
              isHaveValue(this.props.loginState, "userTypes")
                ? this.props.loginState.userInfo.userTypes
                : []
            }
          />
        )}
      </ImageBackground>
    );
  }
}

HomeScreen.navigationOptions = {
  header: null
};

const ss = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  header: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(500),
    //
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    //
    backgroundColor: colors.bgBody,
    borderRadius: spaceSize.borderRadius.small,
    //
    paddingHorizontal: spaceSize.padding.medium,
    marginBottom: spaceSize.margin.largest * 3,
    marginTop: spaceSize.margin.medium
  },
  textStyleBranch: {
    flex: 1,
    fontSize: fontSize.largest,
    color: colors.textBody,
    textAlign: "center"
  },
  vieMenu: {
    height: "100%",
    marginRight: spaceSize.margin.largest,
    backgroundColor: colors.bgNav075,
    borderBottomRightRadius: spaceSize.borderRadius.large,
    borderTopRightRadius: spaceSize.borderRadius.large,
    justifyContent: "space-between",
    overflow: "hidden"
  },
  vieBody: {
    flex: 1,
    marginVertical: spaceSize.margin.large
  },
  button: {
    width: Layout.minerScale(100),
    height: Layout.minerScale(32),
    marginRight: spaceSize.margin.medium,
    marginTop: spaceSize.margin.medium,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#5cb85c",
    borderRadius: Layout.minerScale(4)
  },
  textbutton: {
    fontSize: Layout.minerScale(16),
    color: "white"
  },
  titleSubjectSwitchContainer: {
    fontSize: Layout.minerScale(22),
    color: "gray"
  },
  titleTimeframeContainer: {
    fontSize: Layout.minerScale(20),
    color: "gray",
    paddingBottom: Layout.minerScale(8),
    fontWeight: "bold"
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: "gray",
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12)
  },
  timeframeTitleText: { fontSize: Layout.minerScale(20), color: "gray" },
  flatlistStyle: {
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "center"
  },
  nameStyle: {
    flex: 1,
    fontSize: fontSize.largest,
    color: colors.textBody
  },
  teacherCardContainer: {
    minHeight: Layout.minerScale(130),
    width: Layout.minerScale(400),
    backgroundColor: colors.bgBody,
    marginTop: spaceSize.margin.largest,
    marginRight: spaceSize.margin.largest,
    paddingHorizontal: spaceSize.padding.small,
    borderRadius: spaceSize.borderRadius.small
  },
  textStyle: {
    fontSize: fontSize.medium,
    color: colors.textPlaceHolder
  },
  imageStyle: {
    height: Layout.minerScale(60),
    width: Layout.minerScale(60),
    borderRadius: Layout.minerScale(60) / 2
  },
  socialStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: Layout.minerScale(30)
  },
  iconStyle: { color: colors.textPlaceHolder, fontSize: Layout.minerScale(20) },
  actionStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    //
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    //
    height: Layout.minerScale(30),
    width: Layout.minerScale(60)
    //
  }
});
