import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { TextInput, View, TouchableOpacity, Platform } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import { styles } from "./SearchInputStyles";
// import { Colors } from "../../../Themes";

export default class SearchInput extends PureComponent {
  render() {
    const props = this.props;
    return (
      <View style={props.styleWrapper || styles.inputWrapper}>
        <Icon name={"search"} style={[styles.icon, props.styleIcon]} />
        <TextInput
          underlineColorAndroid={"transparent"}
          onChangeText={props.onChangeText}
          style={[
            styles.textInput,
            props.styleText,
            {
              ...Platform.select({
                web: { outlineColor: "transparent" }
              })
            }
          ]}
          value={props.value}
          maxLength={props.maxLength}
          placeholder={props.holder || "Search..."}
          // placeholderTextColor={Colors.placeholder}
          onEndEditing={props.onPress ? props.onPress : null}
        />
        {!!props.value && (
          <TouchableOpacity
            onPress={props.clearSearch}
            style={styles.touchClear}
          >
            <Icon name={"clear"} style={[styles.iconClear, props.styleIcon]} />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChangeText: PropTypes.func
};

SearchInput.defaultProps = {
  value: "",
  onChangeText: () => {}
};
