import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "../API";
import { Types } from "../Reducers/BranchRecucer";

function* getBranchs(action) {
  try {
    let result = yield call(() =>
      API.get("/app/company").then(response => response.data)
    );
    // if (1) {
    //   let branchs = [
    //     {
    //       uuid: "1",
    //       name: "Mạc Ðĩnh Chi",
    //       address: "94 Mạc Ðĩnh Chi, Phường Ða Kao, Q.1 , TP. Hồ Chí Minh",
    //       email: "info@titan.edu.vn",
    //       phone: "08.3822 0058",
    //       website: "www.titan.edu.vn"
    //     },
    //     {
    //       uuid: "2",
    //       name: "Xuân Hồng",
    //       address: "95 Xuân Hồng, Phường 4, Tân Bình, Hồ Chí Minh",
    //       email: "info@titan.edu.vn",
    //       phone: "094 363 95 95",
    //       website: "www.titan.edu.vn"
    //     }
    //   ];
    if (result.status === 1) {
      yield put({
        type: Types.GET_BRANCHS_SUCCESS,
        branchs: result.response.subsidiaries.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        company: result.response
      });
    } else {
      yield put({
        type: Types.GET_BRANCHS_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.GET_BRANCHS_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* updateCompany(action) {
  try {
    const result = yield call(() =>
      API.put("/app/company", action.company).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_COMPANY_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_COMPANY_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_BRANCH_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* updateBranch(action) {
  try {
    const result = yield call(() =>
      API.put("/app/subsidiary", action.branch).then(response => response.data)
    );
    if (result.status === 1) {
      yield put({
        type: Types.UPDATE_BRANCH_SUCCESS,
        message: "Success"
      });
    } else {
      yield put({
        type: Types.UPDATE_BRANCH_FAIL,
        message: result.message
      });
    }
  } catch (e) {
    yield put({
      type: Types.UPDATE_BRANCH_FAIL,
      message: "Không thể kết nối đến máy chủ..."
    });
  }
}

function* BranchSaga() {
  yield takeLatest(Types.GET_BRANCHS, getBranchs);
  yield takeLatest(Types.UPDATE_BRANCH, updateBranch);
  yield takeLatest(Types.UPDATE_COMPANY, updateCompany);
}

export default BranchSaga;
