import { Layout } from "./Layout";
const def = 5;
const step = 2;
export const fontSize = {
  tiniest: Layout.minerScale(def + step * 1),
  tiny: Layout.minerScale(def + step * 2),
  smallest: Layout.minerScale(def + step * 3),
  small: Layout.minerScale(def + step * 4),
  medium: Layout.minerScale(def + step * 5),
  large: Layout.minerScale(def + step * 6),
  largest: Layout.minerScale(def + step * 7),
  huge: Layout.minerScale(def + step * 8),
  hugest: Layout.minerScale(def + step * 9)
};
