import React, { Component } from "react";
import { View } from "react-native";
import MateIcon from "react-native-vector-icons/MaterialCommunityIcons";
import AntIcon from "react-native-vector-icons/AntDesign";
import { string, number } from "prop-types";
import {data} from "./source";
import { SvgXml } from "react-native-svg";

export default class Icon extends Component {
  static propTypes = {
    name: string.isRequired,
    color: string,
    size: number
  };

  static defaultProps = {
    name: "",
    color: "#808080",
    size: 24
  };

  render() {
    const { name } = this.props;
    const icon = data.hasOwnProperty(name) ? data[this.props.name] : undefined;
    return (
      <View>
        {icon === undefined ? (
          <MateIcon
            name={name}
            size={this.props.size}
            color={this.props.color}
          />
        ) : // <AntIcon
        //   name={"question"}
        //   size={this.props.size}
        //   color={this.props.color}
        // />
        icon.type === "svg" ? (
          // <MateIcon
          //   name={icon.source}
          //   size={this.props.size}
          //   color={this.props.color}
          // />
          <SvgXml
            width={this.props.size}
            height={this.props.size}
            fill={this.props.color}
            xml={icon.source}
          />
        ) : (
          <MateIcon
            name={icon.source}
            size={this.props.size}
            color={this.props.color}
          />
        )}
      </View>
    );
  }
}
