import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
  Platform,
  FlatList
} from "react-native";
import BackHeader from "../../Components/BackHeader";
import { Types as GradeTypes } from "../../Models/Reducers/GradeReducer";
import { Layout, fontSize, spaceSize, colors } from "../../Themes";

export default class GradeScreen extends Component {
  constructor(props) {
    super(props);
    this.refGradeName = {};
    this.state = {
      grades: Object.assign([], this.props.gradeState.grades),
      grade: {
        name: ""
      },
      refreshing: false
    };
  }

  componentDidMount() {
    this.props.getGrades();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.gradeState.type !== "") {
      switch (nextProps.gradeState.type) {
        case GradeTypes.INSERT_GRADE_SUCCESS:
          this.props.getGrades();
          break;
        case GradeTypes.INSERT_GRADE_FAIL:
          alert(nextProps.gradeState.message);
          break;
        case GradeTypes.UPDATE_GRADE_SUCCESS:
          this.props.getGrades();
          break;
        case GradeTypes.UPDATE_GRADE_FAIL:
          alert(nextProps.gradeState.message);
          break;
        case GradeTypes.DELETE_GRADE_SUCCESS:
          this.props.getGrades();
          break;
        case GradeTypes.DELETE_GRADE_FAIL:
          alert(nextProps.gradeState.message);
          break;
      }
      this.setState({
        grades: nextProps.gradeState ? [...nextProps.gradeState.grades] : []
      });
    }
  }

  onChangeOldGradetName = (gradeUuid, name) => {
    this.setState({
      grades: this.state.grades.map(grade => {
        if (grade.uuid === gradeUuid) {
          return { ...grade, name };
        } else return { ...grade };
      })
    });
  };

  onChangeNewGradeName = text => {
    this.setState(prevState => ({
      ...prevState,
      grade: {
        name: text
      }
    }));
  };

  routeTo = screen => {
    this.props.navigation.navigate({ routeName: screen });
  };

  onDelete = grade => {
    if (grade.uuid) {
      this.props.deleteGrade(grade.uuid);
    }
  };

  onUpdate = grade => {
    if (grade.uuid && grade.name && grade.name !== "")
      return this.props.updateGrade(grade);
    this.props.getGrades();
  };

  onInsert = () => {
    if (this.state.grade.name && this.state.grade.name !== "") {
      this.props.insertGrade({
        ...this.state.grade
      });
      this.setState({ grade: { name: "" } });
      this.refGradeName.focus();
    }
  };

  render() {
    const grades = !!this.props.searchValue
      ? this.state.grades.filter(g =>
          g.name.toLowerCase().includes(this.props.searchValue.toLowerCase())
        )
      : this.state.grades;
    return (
      <View style={[styles.container]}>
        {Platform.OS !== "web" && (
          <BackHeader
            title="Khối lớp"
            goBack={() => this.routeTo("SettingScreen")}
          />
        )}
        <KeyboardAvoidingView
          style={{ flex: 1, marginBottom: Layout.minerScale(12) }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: Layout.minerScale(50)
            }}
          >
            <AntDesign
              name="plus"
              style={styles.icon}
              onPress={() => this.refGradeName.focus()}
            />
            <TextInput
              ref={ref => (this.refGradeName = ref)}
              style={styles.textInput}
              onChangeText={text => this.onChangeNewGradeName(text)}
              value={this.state.grade.name}
            />
            <AntDesign
              name="check"
              style={[
                styles.icon,
                {
                  color: colors.buttonSave,
                  paddingRight: Layout.minerScale(12)
                }
              ]}
              onPress={() => this.onInsert()}
            />
            <AntDesign
              name="delete"
              style={[
                styles.icon,
                {
                  color: colors.buttonDelete,
                  paddingRight: Layout.minerScale(24),
                  opacity: 0
                }
              ]}
            />
          </View>
          <View style={styles.line}></View>

          <FlatList
            style={{ flex: 1 }}
            refreshing={this.state.refreshing}
            onRefresh={() => this.props.getGrades()}
            data={grades}
            keyExtractor={this._keyExtractor}
            extraData={this.state || this.props}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={true}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) => {
              return (
                <View key={index}>
                  <View style={styles.lineContainer}>
                    <MaterialIcons name="bookmark-border" style={styles.icon} />
                    <TextInput
                      style={styles.textInput}
                      onChangeText={text =>
                        this.onChangeOldGradetName(item.uuid, text)
                      }
                      value={item.name}
                    />

                    <AntDesign
                      name="check"
                      style={[
                        styles.icon,
                        {
                          color: colors.buttonSave,
                          paddingRight: Layout.minerScale(12)
                        }
                      ]}
                      onPress={() => this.onUpdate(item)}
                    />
                    <AntDesign
                      name="delete"
                      style={[
                        styles.icon,
                        {
                          color: colors.buttonDelete,
                          paddingRight: Layout.minerScale(24)
                        }
                      ]}
                      onPress={() => this.onDelete(item)}
                    />
                  </View>
                  <View style={styles.line}></View>
                </View>
              );
            }}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

GradeScreen.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  rightActions: {
    width: Layout.minerScale(100),
    flexDirection: "row",
    marginHorizontal: Layout.minerScale(12)
  },
  line: {
    borderBottomWidth: Layout.minerScale(1),
    borderBottomColor: "rgb(220,220,220)",
    marginHorizontal: Layout.minerScale(12)
  },
  lineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: Layout.minerScale(50)
  },
  icon: {
    color: colors.textBody,
    fontSize: fontSize.largest,
    paddingHorizontal: spaceSize.padding.small,
    paddingVertical: spaceSize.padding.small,
    paddingRight: 0
  },
  gradeName: {
    fontSize: Layout.minerScale(24),
    textAlign: "center",
    paddingLeft: Layout.minerScale(8)
  },
  textInput: {
    flex: 1,
    fontSize: fontSize.largest,
    paddingLeft: spaceSize.padding.small,
    color: colors.textBody,
    ...Platform.select({ web: { outlineColor: "transparent" } })
  }
});
