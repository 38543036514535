import React, { Component } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import Toast from "react-native-easy-toast";
import { TextField } from "react-native-material-textfield";
import { Types as BRANCH_TYPE } from "../../../Models/Reducers/BranchRecucer";
import { colors, fontSize, Layout, spaceSize } from "../../../Themes";
import { enumAccount } from "../../../Utilities/Enum";

export default class Company extends Component {
  constructor(props) {
    super(props);
    this.refTextInputName = {};
    this.state = {
      company: this.props.company,
      showPass: true
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.branchType !== "") {
      if (nextProps.branchType === BRANCH_TYPE.UPDATE_COMPANY_SUCCESS) {
        alert("Cập nhật thông tin thành công");
        this.props.getBranchs();
      }
      if (nextProps.branchType === BRANCH_TYPE.UPDATE_COMPANY_FAIL)
        alert("Có lỗi xảy ra, hãy thử lại sau!");
      if (nextProps.branchType === BRANCH_TYPE.GET_BRANCHS_SUCCESS)
        this.setState({ company: nextProps.company });
    }
  }

  vaidate = () => {
    const { company } = this.state;
    if (!!!company.email) {
      alert("Vui lòng nhập Email");
      return false;
    }
    if (!!!company.password) {
      alert("Vui lòng nhập mật khẩu");
      return false;
    }

    return true;
  };
  onSave = () => {
    if (this.vaidate()) this.props.updateCompany(this.state.company);
  };

  onChangeEmail = text => {
    this.setState({
      company: {
        ...this.state.company,
        email: text
      }
    });
  };
  onChangePassword = text => {
    this.setState({
      company: {
        ...this.state.company,
        password: text
      }
    });
  };
  showPass = () => {
    if (this.state.showPass) this.setState({ showPass: false });
    else this.setState({ showPass: true });
  };
  render() {
    return (
      <View style={styles.container}>
        <KeyboardAvoidingView
          enableOnAndroid={true}
          style={{
            flex: 1
          }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
            <TextField
              ref={ref => (this.refTextInputName = ref)}
              fontSize={fontSize.large}
              containerStyle={{
                flex: 1,
                paddingHorizontal: Layout.minerScale(16)
              }}
              labelFontSize={fontSize.medium}
              label={"Email"}
              style={{
                ...Platform.select({
                  web: { outlineColor: "transparent" }
                })
              }}
              textColor={colors.textBody}
              tintColor={colors.textPlaceHolder}
              baseColor={colors.textPlaceHolder}
              value={this.state.company.email || ""}
              onChangeText={text => this.onChangeEmail(text)}
            />
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <TextField
                ref={ref => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16)
                }}
                labelFontSize={fontSize.medium}
                label={"Mật Khẩu email"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" }
                  })
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.company.password || ""}
                onChangeText={text => this.onChangePassword(text)}
                secureTextEntry={this.state.showPass}
              />
              <TouchableOpacity
                style={{
                  width: Layout.minerScale(50),
                  aspectRatio: 1 / 1
                }}
                onPress={() => this.showPass()}
              >
                <MaterialCommunityIcons
                  name={this.state.showPass ? "eye" : "eye-off"}
                  size={Layout.minerScale(25)}
                  color={colors.textBody}
                />
              </TouchableOpacity>
            </View>
          </ScrollView>
          <View
            style={{
              alignItems: "center",
              marginTop: spaceSize.margin.largest
            }}
          >
            <TouchableOpacity style={styles.button} onPress={this.onSave}>
              <Text style={styles.buttonText}>Lưu</Text>
            </TouchableOpacity>
          </View>
          <Toast
            ref="toast"
            fadeInDuration={500}
            fadeOutDuration={750}
            opacity={0.8}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

Company.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed"
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  subjectSwitchContainer: {
    flexDirection: "row",
    height: Layout.minerScale(30),
    alignItems: "center"
  },
  nameSubjectSwitch: {
    fontSize: fontSize.medium,
    color: colors.textBody,
    paddingHorizontal: Layout.minerScale(10)
  },
  titleSubjectSwitchContainer: {
    fontSize: fontSize.largest,
    color: colors.textBody
  },
  titleTimeframeContainer: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody,
    paddingBottom: Layout.minerScale(8)
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12)
  },
  timeframeTitleText: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody
  },
  checkAll: {},
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest
  }
});
