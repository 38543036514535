import React, { Component } from "react";
import {
  Dimensions,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
  Animated,
} from "react-native";
import Toast from "react-native-easy-toast";
import { TextField } from "react-native-material-textfield";
import BackAndSaveHeader from "../../../Components/BackAndSaveHeader";
import { IconTextMateCom } from "../../../Components/CustomButton/CustomButton";
import { colors, fontSize, Layout, spaceSize } from "../../../Themes";

export default class TeacherDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.refTextInputName = {};
    this.state = {
      allTimefree: false,
      title: this.props.navigation
        ? this.props.navigation.getParam("title", "Tạo mới giảng viên")
        : this.props.title,
      teacher: this.props.navigation
        ? this.props.navigation.getParam("teacher", {
            uuid: null,
            name: "",
            subjects: [],
            teachingTimes: [],
          })
        : this.props.teacher,
      subjects: this.props.subjectState.subjects.map((subject) => {
        return { ...subject, switchValue: false };
      }),
      scrollX: new Animated.Value(0),
    };
  }
  componentWillReceiveProps(nextProps) {
    if (Platform.OS === "web")
      this.setState({
        teacher: nextProps.teacher,
        allTimefree: false,
      });
  }
  validate = () => {
    const { teacher } = this.state;
    if (!!!teacher.name) {
      alert("Vui lòng nhập tên giảng viên");
      return false;
    }
    if (!!!teacher.phone) {
      alert("Vui lòng nhập số điện thoại giảng viên");
      return false;
    }
    if (!!!teacher.email) {
      alert("Vui lòng nhập email giảng viên");
      return false;
    }
    return true;
  };
  onSave = () => {
    if (!this.validate()) {
      return;
    }

    if (this.state.teacher.uuid) {
      this.props.updateTeacher(this.state.teacher);
    } else {
      this.props.insertTeacher(this.state.teacher);
    }
    if (Platform.OS !== "web") this.routeTo("TeacherScreen");
  };

  routeTo = (screen) => {
    this.props.navigation.navigate({ routeName: screen });
  };

  onSwitchSubject = (subject) => {
    this.setState({
      subjects: this.state.subjects.map((each) => {
        if (subject.uuid === each.uuid) {
          return { ...each, switchValue: !each.switchValue };
        }
        return { ...each };
      }),
    });
    this.state.teacher.subjects.find((uuid) => uuid == subject.uuid)
      ? this.setState({
          teacher: {
            ...this.state.teacher,
            subjects: [
              ...this.state.teacher.subjects.filter(
                (uuid) => uuid !== subject.uuid
              ),
            ],
          },
        })
      : this.setState({
          teacher: {
            ...this.state.teacher,
            subjects: [...this.state.teacher.subjects, subject.uuid],
          },
        });
  };

  onTouchTimeframe = (weekday, timeframe) => {
    this.state.teacher.teachingTimes.find(
      (time) =>
        time.weekday === weekday.uuid && time.timeframe === timeframe.uuid
    )
      ? this.setState({
          teacher: {
            ...this.state.teacher,
            teachingTimes: [
              ...this.state.teacher.teachingTimes.filter(
                (time) =>
                  !(
                    time.weekday === weekday.uuid &&
                    time.timeframe === timeframe.uuid
                  )
              ),
            ],
          },
        })
      : this.setState({
          teacher: {
            ...this.state.teacher,
            teachingTimes: [
              ...this.state.teacher.teachingTimes,
              {
                weekday: weekday.uuid,
                timeframe: timeframe.uuid,
              },
            ],
          },
        });
  };

  onChangeName = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        name: text,
      },
    });
  };
  onChangePhone = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        phone: text,
      },
    });
  };
  onChangeEmail = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        email: text,
      },
    });
  };
  onChangeWorkAddress = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        workAddress: text,
      },
    });
  };
  onChangeDegree = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        degree: text,
      },
    });
  };
  onChangeGender = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        gender: text,
      },
    });
  };
  onChangeCustomSubject = (text) => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        customSubjects: text,
      },
    });
  };
  setAllTime = (all) => {
    this.setState({ allTimefree: all });
    let teachingTimes = [];
    if (all)
      this.props.weekdayState.weekdays.forEach((week) => {
        this.props.timeframeState.timeframes.forEach((time) => {
          teachingTimes.push({
            weekday: week.uuid,
            timeframe: time.uuid,
          });
        });
      });

    this.setState({
      teacher: {
        ...this.state.teacher,
        teachingTimes: teachingTimes,
      },
    });
  };
  getColumn = () => {
    if (Platform.OS === "web") {
      const { width, height } = Dimensions.get("window");
      let ratio = width / height;
      return ratio < 1.3 ? 2 : ratio < 1.8 ? 3 : 4;
    } else {
      return 2;
    }
  };
  getColumnTimeWidth = () => {
    if (Platform.OS === "web") {
      const { width, height } = Dimensions.get("window");
      let ratio = width / height;
      return ratio < 1.3
        ? Layout.minerScale(60)
        : ratio < 1.8
        ? Layout.minerScale(70)
        : Layout.minerScale(80);
    } else {
      return Layout.minerScale(80);
    }
  };
  render() {
    const { scrollX } = this.state;
    const column = this.getColumn();
    const width = this.getColumnTimeWidth();
    const ratio =
      Dimensions.get("window").width / Dimensions.get("window").height;
    return (
      <View style={styles.container}>
        {Platform.OS !== "web" && (
          <BackAndSaveHeader
            title={this.state.title}
            goBack={() => this.routeTo("TeacherScreen")}
            onSave={() => this.onSave()}
          />
        )}
        <KeyboardAvoidingView
          enableOnAndroid={true}
          style={{
            flex: 1,
          }}
          behavior="padding"
          enabled
          keyboardVerticalOffset={Layout.minerScale(24)}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TextField
                ref={(ref) => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16),
                }}
                labelFontSize={fontSize.medium}
                label={"Tên giảng viên"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" },
                  }),
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.teacher.name || ""}
                onChangeText={(text) => this.onChangeName(text)}
              />
              <View
                style={{
                  width: Layout.minerScale(100),
                  height: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <IconTextMateCom
                  nameIcon={
                    this.state.teacher.gender === "Male"
                      ? "check-circle-outline"
                      : "checkbox-blank-circle-outline"
                  }
                  text={"Nam"}
                  onPress={() => this.onChangeGender("Male")}
                  styleIcon={[styles.nameSubjectSwitch]}
                  textStyle={[styles.nameSubjectSwitch, { marginLeft: 5 }]}
                />
                <IconTextMateCom
                  nameIcon={
                    this.state.teacher.gender === "Female"
                      ? "check-circle-outline"
                      : "checkbox-blank-circle-outline"
                  }
                  text={"Nữ"}
                  onPress={() => this.onChangeGender("Female")}
                  styleIcon={[styles.nameSubjectSwitch]}
                  textStyle={[styles.nameSubjectSwitch, { marginLeft: 5 }]}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <TextField
                ref={(ref) => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16),
                }}
                labelFontSize={fontSize.medium}
                label={"Đơn vị"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" },
                  }),
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.teacher.workAddress || ""}
                onChangeText={(text) => this.onChangeWorkAddress(text)}
              />
              <TextField
                ref={(ref) => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16),
                }}
                labelFontSize={fontSize.medium}
                label={"Trình Độ"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" },
                  }),
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.teacher.degree || ""}
                onChangeText={(text) => this.onChangeDegree(text)}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <TextField
                ref={(ref) => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16),
                }}
                labelFontSize={fontSize.medium}
                label={"Email"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" },
                  }),
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.teacher.email || ""}
                onChangeText={(text) => this.onChangeEmail(text)}
              />
              <TextField
                ref={(ref) => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16),
                }}
                labelFontSize={fontSize.medium}
                label={"Số Điện Thoại"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" },
                  }),
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.teacher.phone || ""}
                onChangeText={(text) => this.onChangePhone(text)}
                maxLength={11}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <TextField
                ref={(ref) => (this.refTextInputName = ref)}
                fontSize={fontSize.large}
                containerStyle={{
                  flex: 1,
                  paddingHorizontal: Layout.minerScale(16),
                }}
                labelFontSize={fontSize.medium}
                label={"Môn Giảng Dạy"}
                style={{
                  ...Platform.select({
                    web: { outlineColor: "transparent" },
                  }),
                }}
                textColor={colors.textBody}
                tintColor={colors.textPlaceHolder}
                baseColor={colors.textPlaceHolder}
                value={this.state.teacher.customSubjects || ""}
                onChangeText={(text) => this.onChangeCustomSubject(text)}
              />
            </View>
            {/* <View style={{ flex: 1 }}>
              <View
                style={{
                  height: Layout.heightScale(100),
                  margin: spaceSize.margin.small
                }}
              >
                <Text style={styles.titleSubjectSwitchContainer}>
                  {"Các môn giảng dạy"}
                </Text>

                <FlatList
                  data={this.state.subjects}
                  keyExtractor={this._keyExtractor}
                  extraData={this.state || this.props}
                  scrollEventThrottle={16}
                  columnWrapperStyle={{
                    alignItems: "flex-start"
                  }}
                  numColumns={column}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <View
                      key={index}
                      style={[
                        styles.subjectSwitchContainer,
                        { width: `${100 / column}%` }
                      ]}
                    >
                      <Switch
                        style={{ height: Layout.minerScale(20) }}
                        onValueChange={() => this.onSwitchSubject(item)}
                        value={
                          this.state.teacher.subjects.find(
                            uuid => uuid === item.uuid
                          )
                            ? true
                            : false
                        }
                      />
                      <Text style={styles.nameSubjectSwitch}>{item.name}</Text>
                    </View>
                  )}
                />
              </View> */}
            {/* <View
                style={{
                  flex: 1,
                  marginHorizontal: spaceSize.margin.small,
                  alignItems: "center"
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <Text style={styles.titleTimeframeContainer}>
                    {"Thời gian đăng kí"}
                  </Text>
                  <View
                    style={[styles.subjectSwitchContainer, { marginLeft: 10 }]}
                  >
                    <Switch
                      style={{ height: Layout.minerScale(20) }}
                      onValueChange={value => this.setAllTime(value)}
                      value={this.state.allTimefree}
                    />
                    <Text style={styles.titleTimeframeContainer}>Tất Cả</Text>
                  </View>
                </View>
                <View
                  style={[
                    {
                      flexDirection: "row",
                      justifyContent: "flex-start"
                    },
                    ratio < 1.2 && { width: "100%" }
                  ]}
                >
                  <View style={styles.timeframeTitle}>
                    <Text style={[styles.timeframeTitleText, { opacity: 0 }]}>
                      {this.props.timeframeState.timeframes[0].beginTime +
                        " - " +
                        this.props.timeframeState.timeframes[0].endTime}
                    </Text>
                  </View>
                  <View style={{ overflow: "hidden" }}>
                    <Animated.View
                      style={{
                        flexDirection: "row",
                        flex: 1,
                        right: scrollX
                      }}
                    >
                      {this.props.weekdayState.weekdays
                        .sort((a, b) => a.sortIndex - b.sortIndex)
                        .map((weekday, idx1) => {
                          return (
                            <View key={idx1} style={{ width: width }}>
                              <View
                                style={{
                                  borderWidth: Layout.minerScale(1),
                                  borderColor: colors.line,
                                  height: Layout.minerScale(32),
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: fontSize.large,
                                    color: colors.textBody
                                  }}
                                >
                                  {width <= Layout.minerScale(70)
                                    ? weekday.code
                                    : weekday.name}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                    </Animated.View>
                  </View>
                </View>

                <View
                  style={[
                    {
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "flex-start"
                    },
                    ratio < 1.2 && { width: "100%" }
                  ]}
                >
                  <ScrollView
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={16}
                    contentContainerStyle={{
                      flexDirection: "row"
                    }}
                  >
                    <View>
                      {this.props.timeframeState.timeframes
                        .sort((a, b) => a.beginTime - b.beginTime)
                        .map((timeframe, index) => (
                          <View key={index} style={styles.timeframeTitle}>
                            <Text style={styles.timeframeTitleText}>
                              {timeframe.beginTime + " - " + timeframe.endTime}
                            </Text>
                          </View>
                        ))}
                    </View>
                    <ScrollView
                      style={{
                        flexDirection: "row",
                        flex: 1
                      }}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      horizontal={true}
                      scrollEventThrottle={16}
                      onScroll={Animated.event([
                        {
                          nativeEvent: {
                            contentOffset: { x: this.state.scrollX }
                          }
                        }
                      ])}
                      // onScroll={Animated.event([
                      //   {
                      //     nativeEvent: {
                      //       contentOffset: { x: this.state.scrollX }
                      //     }
                      //   }
                      // ])}
                    >
                      {this.props.weekdayState.weekdays
                        .sort((a, b) => a.sortIndex - b.sortIndex)
                        .map((weekday, idx1) => {
                          return (
                            <View
                              key={idx1}
                              style={{
                                width: width
                                // flex: 1,
                                // maxWidth: Layout.minerScale(80)
                              }}
                            >
                              {this.props.timeframeState.timeframes
                                .sort((a, b) => a.beginTime - b.beginTime)
                                .map((timeframe, idx2) => {
                                  return (
                                    <TouchableOpacity
                                      key={idx2}
                                      style={{
                                        borderWidth: Layout.minerScale(1),
                                        borderColor: colors.line,
                                        height: Layout.minerScale(32),
                                        backgroundColor: this.state.teacher.teachingTimes.find(
                                          time =>
                                            time.weekday === weekday.uuid &&
                                            time.timeframe === timeframe.uuid
                                        )
                                          ? "green"
                                          : "transparent"
                                      }}
                                      onPress={() => {
                                        this.onTouchTimeframe(
                                          weekday,
                                          timeframe
                                        );
                                      }}
                                    ></TouchableOpacity>
                                  );
                                })}
                            </View>
                          );
                        })}
                    </ScrollView>
                  </ScrollView>
                </View>
              </View> */}
            {/* <ScrollView
                showsVerticalScrollIndicator={false}
                style={[
                  {
                    margin: spaceSize.margin.small
                  }
                ]}
                contentContainerStyle={{ alignItems: "center" }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row"
                  }}
                >
                  <Text style={styles.titleTimeframeContainer}>
                    {"Thời gian đăng kí"}
                  </Text>
                  <View
                    style={[styles.subjectSwitchContainer, { marginLeft: 10 }]}
                  >
                    <Switch
                      style={{ height: Layout.minerScale(20) }}
                      onValueChange={value => this.setAllTime(value)}
                      value={this.state.allTimefree}
                    />
                    <Text style={styles.titleTimeframeContainer}>Tất Cả</Text>
                  </View>
                </View>

                <View
                  style={[
                    {
                      flexDirection: "row"
                    },
                    ratio < 1.2 && { width: "100%" }
                  ]}
                >
                  <View>
                    <Text
                      style={{
                        borderWidth: Layout.minerScale(1),
                        borderColor: colors.line,
                        height: Layout.minerScale(32)
                      }}
                    ></Text>
                    {this.props.timeframeState.timeframes
                      .sort((a, b) => a.beginTime - b.beginTime)
                      .map((timeframe, index) => (
                        <View key={index} style={styles.timeframeTitle}>
                          <Text style={styles.timeframeTitleText}>
                            {timeframe.beginTime + " - " + timeframe.endTime}
                          </Text>
                        </View>
                      ))}
                  </View>
                  <ScrollView
                    style={{
                      flexDirection: "row",
                      flex: 1
                    }}
                    contentContainerStyle={{
                      justifyContent: "center",
                      width: "100%"
                    }}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    horizontal={true}
                  >
                    <View style={{ flexDirection: "row", flex: 1 }}>
                      {this.props.weekdayState.weekdays
                        .sort((a, b) => a.sortIndex - b.sortIndex)
                        .map((weekday, idx1) => {
                          return (
                            <View
                              key={idx1}
                              style={{
                                width: width
                                // flex: 1,
                                // maxWidth: Layout.minerScale(80)
                              }}
                            >
                              <View
                                style={{
                                  borderWidth: Layout.minerScale(1),
                                  borderColor: colors.line,
                                  height: Layout.minerScale(32),
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: fontSize.large,
                                    color: colors.textBody
                                  }}
                                >
                                  {width <= Layout.minerScale(70)
                                    ? weekday.code
                                    : weekday.name}
                                </Text>
                              </View>
                              {this.props.timeframeState.timeframes
                                .sort((a, b) => a.beginTime - b.beginTime)
                                .map((timeframe, idx2) => {
                                  return (
                                    <TouchableOpacity
                                      key={idx2}
                                      style={{
                                        borderWidth: Layout.minerScale(1),
                                        borderColor: colors.line,
                                        height: Layout.minerScale(32),
                                        backgroundColor: this.state.teacher.teachingTimes.find(
                                          time =>
                                            time.weekday === weekday.uuid &&
                                            time.timeframe === timeframe.uuid
                                        )
                                          ? "green"
                                          : "transparent"
                                      }}
                                      onPress={() => {
                                        this.onTouchTimeframe(
                                          weekday,
                                          timeframe
                                        );
                                      }}
                                    ></TouchableOpacity>
                                  );
                                })}
                            </View>
                          );
                        })}
                    </View>
                  </ScrollView>
                </View>
              </ScrollView>
             */}
            {/* </View> */}
          </View>
          <View
            style={{
              alignItems: "center",
              marginTop: spaceSize.margin.medium,
            }}
          >
            <TouchableOpacity style={styles.button} onPress={this.onSave}>
              <Text style={styles.buttonText}>Lưu</Text>
            </TouchableOpacity>
          </View>
          <Toast
            ref="toast"
            fadeInDuration={500}
            fadeOutDuration={750}
            opacity={0.8}
          />
        </KeyboardAvoidingView>
      </View>
    );
  }
}

TeacherDetailScreen.navigationOptions = {
  header: null,
  drawerLockMode: "locked-closed",
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subjectSwitchContainer: {
    flexDirection: "row",
    height: Layout.minerScale(30),
    alignItems: "center",
  },
  nameSubjectSwitch: {
    fontSize: fontSize.medium,
    color: colors.textBody,
    paddingHorizontal: Layout.minerScale(10),
  },
  titleSubjectSwitchContainer: {
    fontSize: fontSize.largest,
    color: colors.textBody,
  },
  titleTimeframeContainer: {
    fontSize: Layout.minerScale(20),
    color: colors.textBody,
  },
  timeframeTitle: {
    borderWidth: Layout.minerScale(1),
    borderColor: colors.textBody,
    height: Layout.minerScale(32),
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Layout.minerScale(12),
  },
  timeframeTitleText: {
    fontSize: fontSize.large,
    color: colors.textBody,
  },
  checkAll: {},
  button: {
    height: Layout.minerScale(50),
    width: Layout.minerScale(100),
    borderRadius: spaceSize.borderRadius.small,
    borderWidth: Layout.minerScale(1),
    borderColor: "#fff",
    backgroundColor: colors.buttonEnable,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: spaceSize.margin.large,
  },
  buttonText: {
    color: colors.textButton,
    fontSize: fontSize.largest,
  },
});
