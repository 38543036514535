import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import {
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity
} from "react-native";

export const Button = ({ title, onPress, loading, style, styleText }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[ss.container, style]}
      disabled={loading}
    >
      {loading ? (
        <ActivityIndicator size={"small"} color={"#FFF"} />
      ) : (
        <Text style={[ss.title, styleText]}>{title}</Text>
      )}
    </TouchableOpacity>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.any,
  styleText: PropTypes.any,
  title: PropTypes.string
};
const ss = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#808080"
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF"
  }
});
