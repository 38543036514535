import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CreatorsTimeframe } from "../../Models/Reducers/TimeframeReducer";
import TimeframeScreen from "./TimeframeScreen";

const mapStateToProps = (state, props) => {
  return {
    timeframeState: state.timeframeState
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreatorsTimeframe
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeframeScreen);
