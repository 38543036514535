import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as BranchCreaters } from "../../../Models/Reducers/BranchRecucer";
import Company from "./CompanyScreen";

const mapStateToProps = (state, props) => {
  return {
    branchType: state.branchInfo.type,
    branchMess: state.branchInfo.message,
    company: state.branchInfo.company
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...BranchCreaters }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Company);
