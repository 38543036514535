import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  changeScreen: ["screen"],
  changeSetting: ["optionSetting"],
});

export const INITIAL_STATE = {
  type: "",
  screen: "",
  optionSetting: ""
};

const changeScreen = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    screen: action.screen
  });
};
const changeSetting = (state, action) => {
  return Object.assign({}, state, {
    type: action.type,
    optionSetting: action.optionSetting
  });
};

const HANDLERS = {
  [Types.CHANGE_SCREEN]: changeScreen,
  [Types.CHANGE_SETTING]: changeSetting,
};

export const appInfo = createReducer(INITIAL_STATE, HANDLERS);
