import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

export default class ButtonText extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleText: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };

  static defaultProps = {
    title: "",
    style: {},
    styleText: {},
    onPress: () => {}
  };
  render() {
    return (
      <TouchableOpacity style={this.props.style} onPress={this.props.onPress}>
        <Text style={[ss.title, this.props.styleText]}>{this.props.title}</Text>
      </TouchableOpacity>
    );
  }
}
const ss = StyleSheet.create({
  title: {
    textAlign: "center",
    fontSize: 16,
    color: "#FFF"
  }
});
