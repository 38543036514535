import React, { PureComponent } from "react";
import {
  Text,
  StyleSheet,
  View,
  Animated,
  TouchableOpacity
} from "react-native";
import { Layout, colors, spaceSize, fontSize } from "../../Themes";
import {
  IconTextButton,
  IconTextMateCom,
  IconTextSimpleLineIcons
} from "../CustomButton";
import { enumMenuOption, enumAccount } from "../../Utilities/Enum";
import { MaterialCommunityIcons as MateComIcon } from "@expo/vector-icons";

export default class MenuSetting extends PureComponent {
  state = {
    width: new Animated.Value(Layout.minerScale(40)),
    open: false
  };
  componentDidMount() {
    // Animated.timing(this.state.width, {
    //   toValue: 0,
    //   duration: 200
    // }).start();
  }

  change = () => {
    if (this.state.open) {
      this.setState({ open: false });
      Animated.timing(this.state.width, {
        toValue: Layout.minerScale(40),
        duration: 200
      }).start();
    } else {
      this.setState({ open: true });
      Animated.timing(this.state.width, {
        toValue: Layout.minerScale(160),
        duration: 200
      }).start();
    }
  };
  render() {
    const { currentOption, setCurrentOption } = this.props;
    const { open, width } = this.state;

    return (
      <Animated.View style={[ss.container, { width: width }]}>
        <View style={ss.header}>
          {/* <Text style={ss.title}>{"Cài đặt"}</Text> */}
          <View style={ss.vieAction}>
            {/* Account */}
            {this.props.userTypes.includes(enumAccount.ruleOption.admin) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.taikhoan && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextMateCom
                  nameIcon={"account-key"}
                  text={enumMenuOption.settingOption.taikhoan}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.taikhoan)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.taikhoan && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.taikhoan && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {this.props.userTypes.includes(enumAccount.ruleOption.admin) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.email && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextMateCom
                  nameIcon={"email"}
                  text={enumMenuOption.settingOption.email}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.email)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.email && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.email && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {/* Chi nhánh */}
            {this.props.userTypes.includes(enumAccount.ruleOption.manager) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.chinhanh && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextMateCom
                  nameIcon={"store"}
                  text={enumMenuOption.settingOption.chinhanh}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.chinhanh)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.chinhanh && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.chinhanh && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {/* Thời gian */}
            {this.props.userTypes.includes(enumAccount.ruleOption.manager) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.thoigian && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextMateCom
                  nameIcon={"clock-outline"}
                  text={enumMenuOption.settingOption.thoigian}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.thoigian)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.thoigian && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.thoigian && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {/* Khối */}
            {this.props.userTypes.includes(enumAccount.ruleOption.manager) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.khoi && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextMateCom
                  nameIcon={"menu"}
                  text={enumMenuOption.settingOption.khoi}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.khoi)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.khoi && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.khoi && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {/* Môn */}
            {this.props.userTypes.includes(enumAccount.ruleOption.manager) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.mon && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextButton
                  nameIcon={"book"}
                  text={enumMenuOption.settingOption.mon}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.mon)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.mon && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.mon && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {/* Lớp */}
            {this.props.userTypes.includes(enumAccount.ruleOption.manager) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.lop && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextMateCom
                  nameIcon={"google-classroom"}
                  text={enumMenuOption.settingOption.lop}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.lop)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption === enumMenuOption.settingOption.lop && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption === enumMenuOption.settingOption.lop && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
            {/* Giảng viên */}
            {this.props.userTypes.includes(enumAccount.ruleOption.manager) && (
              <View
                style={[
                  ss.viewItem,
                  currentOption === enumMenuOption.settingOption.giangvien && {
                    backgroundColor: colors.bgNavItem
                  }
                ]}
              >
                <IconTextSimpleLineIcons
                  nameIcon={"people"}
                  text={enumMenuOption.settingOption.giangvien}
                  onPress={() =>
                    setCurrentOption(enumMenuOption.settingOption.giangvien)
                  }
                  styleIcon={[
                    ss.styleIcon,
                    currentOption ===
                      enumMenuOption.settingOption.giangvien && {
                      color: colors.focusOption
                    }
                  ]}
                  textStyle={[
                    ss.textStyle,
                    currentOption ===
                      enumMenuOption.settingOption.giangvien && {
                      color: colors.focusOption
                    }
                  ]}
                />
              </View>
            )}
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              bottom: spaceSize.padding.small,
              margin: spaceSize.margin.small
            }}
            onPress={this.change}
          >
            <MateComIcon
              name={open ? "arrow-collapse" : "arrow-expand"}
              size={fontSize.large}
              color={colors.textNav}
            ></MateComIcon>
          </TouchableOpacity>
        </View>
      </Animated.View>
    );
  }
}

const ss = StyleSheet.create({
  container: {
    marginLeft: spaceSize.margin.largest,
    height: "100%"
  },
  header: {
    width: Layout.minerScale(160),
    height: "100%",
    backgroundColor: colors.bgNav075,
    borderTopLeftRadius: Layout.minerScale(20),
    borderBottomLeftRadius: Layout.minerScale(20),
    paddingRight: spaceSize.padding.large,
    justifyContent: "center"
  },

  title: {
    fontSize: fontSize.largest,
    color: colors.textHeader
  },
  vieAction: {
    // flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  ////** text icon  */
  viewItem: {
    height: Layout.minerScale(50),
    width: "100%",
    justifyContent: "center",
    paddingLeft: spaceSize.padding.medium,
    borderTopRightRadius: Layout.minerScale(25),
    borderBottomRightRadius: Layout.minerScale(25)
  },
  styleIcon: {
    fontSize: fontSize.large,
    color: colors.textNav
  },
  textStyle: {
    color: colors.textNav,
    fontSize: fontSize.large,
    fontWeight: "normal",
    marginLeft: spaceSize.margin.medium
  }
});
