import { Layout } from "./Layout";
const defPadding = 5;
const stepPadding = 3;

const defMargin = 5;
const stepMargin = 3;

const defBorder = 0;
const stepBorder = 1;

const defBorderRadius = 5;
const stepBorderRadius = 3;

export const spaceSize = {
  drawerWidth: Layout.minerScale(250),
  headerHeight: Layout.minerScale(50),
  footerHeight: Layout.minerScale(50),
  //
  padding: {
    small: Layout.minerScale(defPadding + stepPadding * 1),
    medium: Layout.minerScale(defPadding + stepPadding * 2),
    large: Layout.minerScale(defPadding + stepPadding * 3),
    largest: Layout.minerScale(defPadding + stepPadding * 4),
    huge: Layout.minerScale(defPadding + stepPadding * 5),
    hugest: Layout.minerScale(defPadding + stepPadding * 6)
  },
  margin: {
    small: Layout.minerScale(defMargin + stepMargin * 1),
    medium: Layout.minerScale(defMargin + stepMargin * 2),
    large: Layout.minerScale(defMargin + stepMargin * 3),
    largest: Layout.minerScale(defMargin + stepMargin * 4),
    huge: Layout.minerScale(defMargin + stepMargin * 5),
    hugest: Layout.minerScale(defMargin + stepMargin * 6)
  },
  //
  boderWidth: {
    small: defBorder + stepBorder * 1,
    medium: defBorder + stepBorder * 2,
    large: defBorder + stepBorder * 3
  },
  borderRadius: {
    small: Layout.minerScale(defBorderRadius + stepBorderRadius * 1),
    medium: Layout.minerScale(defBorderRadius + stepBorderRadius * 2),
    large: Layout.minerScale(defBorderRadius + stepBorderRadius * 3)
  },
  //
  shadow: {
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 2,
      width: 3
    },
    elevation: 1
  }
};
